import {
    FETCH_WEBSITE_USER
} from '../actions/types';

const initialState = {
    website_users: [],
};

const websiteReducer = (state = initialState, action) => {
    
    const { type, payload } = action;
    
    switch (type) {
        case FETCH_WEBSITE_USER:
            return {
                ...state,
                website_users: payload,
            };
        default:
            return state;
    }
};

export default websiteReducer;
