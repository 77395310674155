import React, { useEffect, useState } from "react";
import {connect} from "react-redux";
import { Button, Card, Container, Icon, Modal, ProgressBar, Table, Tab, Tabs } from "react-materialize";
import { axiosClient } from "../api/httpClient";
import { errorToast, getCardDisplayStatus, toISODate } from "../api/Util";
import { DateRangePicker } from 'react-date-range';
import * as actions from '../actions';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import StatsTable from "./UserStats";
import VideoBreakdown from "./VideoBreakdown";
import CustomerAverages from "./CustomerAverages";
import TopHeader from "./TopHeader";
import { ADMIN, CUSTOMER, SUB_ADMIN } from "../api/Constants";
import { Spin } from "antd";

function addDays(date, days) {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    if (new Date(result).getTime() < new Date('2022-12-20').getTime()) {
        result = (new Date('2022-12-20'));
    }
    return result;
}

export default connect(mapStateToProps, actions)((props) => {
    let loggedInUser = props.auth.loggedInUser;
    let primaryColor = props.subDomain.subDomainData.primary_color;
    const dateDiff = loggedInUser.client_type === CUSTOMER ? -29 : -7;
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const [startDate, setStartDate] = useState(addDays(new Date(), dateDiff));
    const [endDate, setEndDate] = useState(new Date());
    const [addDateRangeModalOpen, setAddDateRangeModalOpen] = useState(false);
    const websiteSlug = props?.subDomain?.subDomainData?.website_slug;
    const [triggerApi,setTriggerApi] = useState(true);
    const [oldDates, setOldDates] = useState({
        startDate:"",
        endDate:""
    })

    const loadData = () => {
        setLoading(true);
        let api = '/api/dashboard-data';
        let paramsObj = {
            start_date: toISODate(startDate),
            end_date: toISODate(endDate),
        };
        if (loggedInUser.client_type === CUSTOMER) {
            api = '/api/client-dashboard-data';
            paramsObj = { id: loggedInUser.client_id, ...paramsObj }
        }
        axiosClient.get(api, {
            params: paramsObj,
        })
            .then(response => { 
                setData(response.data); 
                setLoading(false);
                setOldDates({
                    startDate:startDate,
                    endDate:endDate
                });
                setTriggerApi(false);
            })
            .catch(err => {
                console.error(err);
                errorToast("Something went wrong while loading data");
            }).finally(() => {
               
            });
    };

    useEffect(() => {
        if (triggerApi){
           loadData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [triggerApi]);

    useEffect(() => {
       if ((startDate !== oldDates.startDate && endDate !== oldDates.endDate) && startDate !== endDate){
        setTriggerApi(true);
       }
    },[startDate, endDate]);
    

    function onDateChange(dates) {
        setStartDate(dates.selection.startDate);
        setEndDate(dates.selection.endDate);
        if ((dates.selection.startDate && dates.selection.endDate) && (dates.selection.startDate !== dates.selection.endDate)) {
            setAddDateRangeModalOpen(false); 
        }
    }
    function showDataInGraph(value, text) {
        return <CircularProgressbar value={value || '0'} text={text || '0'}
            styles={buildStyles({ pathColor: primaryColor, textSize: '0.7rem', textColor: '#000000' })} />;
    }

    return <Container>
        {loading && <ProgressBar />}
        <div className="aside-right-top-bar dashboard-top-bar">
            <div className="top-bar-left">
                <div className="top_bar">
                    <h3>Dashboard</h3>
                </div>
            </div>
            <div className="top-bar-right">
                <TopHeader />
            </div>
        </div>
        <div className="dashboard-main-page">
            <Card>
                <Button onClick={() => setAddDateRangeModalOpen(true)} node="button" className="btn-primary" icon={<Icon left>date_range</Icon>}>
                    {toISODate(startDate ? startDate : new Date())} ~ {toISODate(endDate)}
                </Button>
                <Modal

                    header="Add Date Range"
                    id="addDateRangeModal"
                    open={loading ? false : addDateRangeModalOpen}
                    style={{ height: '65%', width:'51%' }}
                    options={{
                        dismissible: true,
                        endingTop: '10%',
                        opacity: 0.5,
                        preventScrolling: true,
                        onCloseEnd: () => setAddDateRangeModalOpen(false)
                    }}
                >
                    <DateRangePicker
                        ranges={[{
                            startDate,
                            endDate,
                            key: 'selection',
                        }]}
                        minDate={new Date('2022-12-20')}
                        direction="horizontal"
                        fixedHeight={true}
                        months={2}
                        onChange={onDateChange} />
                </Modal>
                {loading && <Spin /> }
            </Card>
            {
                loggedInUser.client_type !== CUSTOMER && !(loggedInUser.client_type === ADMIN || loggedInUser.client_type === SUB_ADMIN) ?
                    <Tabs options={{ responsiveThreshold: Infinity }}>
                        <Tab title={websiteSlug === 'snappyads' ? "Quality Control" : "QA"}>
                            <StatsTable data={{ ...data?.editor_stats }} title="Team Performance" tab={'QaTab'}
                                start={startDate} end={endDate} />
                        </Tab>
                        <Tab title="Customer">
                            <CustomerAverages data={data} />
                            <StatsTable data={{ ...data?.customer_data?.customer_stats }} title="Customer Stats"
                                start={startDate} end={endDate} />
                        </Tab>
                        <Tab title="Own">
                            <StatsTable data={{ ...data?.team_lead_data }} title="Team Performance" tab={'TeamLeadTab'}
                                start={startDate} end={endDate} />
                        </Tab>
                    </Tabs> : null
            }
            {
                (loggedInUser.client_type === ADMIN || loggedInUser.client_type === SUB_ADMIN) ?
                    <Tabs options={{ responsiveThreshold: Infinity }}>
                        <Tab title={websiteSlug === 'snappyads' ? "Quality Control" : "QA"}>
                            <StatsTable data={{ ...data?.editor_stats }} title="Team Performance" tab={'QaTab'}
                                start={startDate} end={endDate} />
                        </Tab>
                        <Tab title="Customer">
                            <CustomerAverages data={data} />
                            <StatsTable data={{ ...data?.customer_data?.customer_stats }} title="Customer Stats"
                                start={startDate} end={endDate} />
                        </Tab>
                        <Tab title="Team Lead">
                            <StatsTable data={{ ...data?.team_lead_data }} title="Team Performance" tab={'TeamLeadTab'}
                                start={startDate} end={endDate} />
                        </Tab>
                    </Tabs> : null
            }
            {
                loggedInUser.client_type === CUSTOMER ?
                    <Table className="highlight client-dashboard-table">
                        <thead>
                            <tr>
                                <th>Serial Number</th>
                                <th>Card Title</th>
                                <th>Created Date</th>
                                <th>Completed Date</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data && data.length > 0 && data.map((card, i) => {
                                    return (
                                        <tr key={`table-card-${card.card_id}`}>
                                            <td>{i + 1}</td>
                                            <td>{card.card_title}</td>
                                            <td>{toISODate(card.creation_time)}</td>
                                            <td>{card.done_time ? toISODate(card.done_time) : '-'}</td>
                                            <td>{getCardDisplayStatus(card.card_status)}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                    :
                    <div>
                        <Card style={{ overflowX: 'scroll' }}>
                            <table style={{ minWidth: '840px' }}>
                                <thead>
                                    <tr>
                                        <th className="center-align" width="16%">Requested Videos</th>
                                        <th className="center-align" width="16%">Completed Videos</th>
                                        <th className="center-align" width="16%">Avg Completion Time</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="center-align">{
                                            showDataInGraph(data?.video_stats?.total_videos > 0 ? 100 : '0', data?.video_stats?.total_videos)
                                        }</td>
                                        <td className="center-align">{
                                            showDataInGraph(((data?.video_stats?.completed / Math.max(data?.video_stats?.total_videos, 1)) * 100), data?.video_stats?.completed)
                                        }</td>
                                        <td className="center-align">{
                                            showDataInGraph(data?.video_stats?.avg_time ? 100 : '0', data?.video_stats?.avg_time ? data.video_stats.avg_time.toFixed(2) + " hours" : '0')
                                        }</td>
                                    </tr>
                                    <tr>
                                        <th className="center-align" width="16%">Avg Rating</th>
                                        <th className="center-align" width="16%">In Progress</th>
                                        <th className="center-align" width="16%">Overdue Videos</th>
                                    </tr>
                                    <tr>
                                        <td className="center-align">{
                                            showDataInGraph(Math.ceil((data?.video_stats?.avg_rating * 100.0) / 5.0),
                                            data?.video_stats?.avg_rating ? `${data.video_stats.avg_rating.toFixed(1)} (${data.video_stats.rated_videos} ratings)` : '0')
                                        }</td>
                                        <td className="center-align">{
                                            showDataInGraph(Math.ceil((data?.video_stats?.in_progress * 100.0) / data?.video_stats?.total_videos), data?.video_stats?.in_progress)
                                        }</td>
                                        <td className="center-align">{
                                            showDataInGraph(Math.ceil((data?.video_stats?.overdue * 100.0) / data?.video_stats?.total_videos), data?.video_stats?.overdue)
                                        }</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Card>
                        <VideoBreakdown data={data?.all_videos} start={toISODate(startDate)} end={toISODate(endDate)} />
                    </div>
                }
        </div>
    </Container>;
});

function mapStateToProps({subDomain}) {
    return {subDomain};
}
