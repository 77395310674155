import {
    FETCH_ALL_WHITE_LABEL_CUSTOMERS,
    FETCHING_ALL_WHITE_LABEL_CUSTOMERS,
} from "../actions/types";

export default function (state = {}, {payload, type}) {
    switch (type) {
        case FETCHING_ALL_WHITE_LABEL_CUSTOMERS:
            return {...state, loadingWhiteLabelCustomers: true};
        case FETCH_ALL_WHITE_LABEL_CUSTOMERS:
            if (payload.status < 300 && payload.status >= 200) {
                return {...state, whiteLabelCustomers: payload.data, loadingWhiteLabelCustomers: false};
            }
            return {...state, loadingWhiteLabelCustomers: false};
        default:
            break;
    }
    return state;
}