import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import * as actions from "../actions";
import {Button, Checkbox, Col, ProgressBar, Row, TextInput} from "react-materialize";
import {axiosClient, setAuthToken, setWebsiteType, setParentId, setUserTimezoneInRequest} from "../api/httpClient";
import {errorToast} from "../api/Util";
import {Link, Redirect} from "react-router-dom";
import LoginPageCommon from "./LoginPageCommon";

const INITIAL_STATE = { useremail: "", password: "", loginProgress: false };

export default connect(mapStateToProps, actions)((props) => {
    const [{useremail, password, loginProgress}, setState] = useState(INITIAL_STATE);
    const emailField = useRef();
    const [rememberMe, setRememberMe] = useState(true);
    let subDomainData = props.subDomain.subDomainData;
    const websiteName = subDomainData?.name;

    useEffect(() => {
        let interval = setInterval(() => {
            if (emailField.current) {
                setState(prev => ({...prev, useremail: emailField.current.value}));
                clearInterval(interval);
            }
        }, 100);
    }, [emailField]);

    useEffect(() => {
        const credentials = JSON.parse(localStorage.getItem('access') || '{}');
        if(credentials) {
            setState(prev => ({...prev, useremail: credentials.useremail || '', password: credentials.password || ''}));
        }
    }, []);

    function validateForm() {
        return useremail?.length > 0 && password?.length > 0;
    }

    function setLoginProgress(value) {
        setState(prev => ({...prev, loginProgress: value}));
    }

    async function handleSubmit() {
        if(loginProgress) {
            return;
        }
        setLoginProgress(true);
        try {
            let response = await axiosClient.post("/api/login", {useremail, password});
            setAuthToken(`Bearer ${response.data.access_token}`);
            if (response) {
                localStorage.setItem('websiteTypeId', response.data.website_type_id)
                localStorage.setItem("timezone", response.data?.timezone ? response.data.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone)
                setUserTimezoneInRequest();
            }
            props.fetchUser();
            setWebsiteType(response.data.website_type_id);
            setParentId(subDomainData?.parent_id);
            props.fetchAllWebsiteUsers();
            if(rememberMe) {
                localStorage.setItem('access', JSON.stringify({ useremail, password }));
            } else {
                localStorage.removeItem('access');
            }
        } catch (e) {
            if(e.response.status === 401) {
                if (e?.response?.data?.error_message && e?.response?.data?.error_message !== "") {
                    errorToast(e.response.data.error_message || "Incorrect login credentials");
                }
                localStorage.removeItem('access');
            } else {
                errorToast("Unable to login: " + e.message);
                console.debug(e);
            }

            setLoginProgress(false);
        }
    }

    function onChange(e) {
        let name = e.target.name;
        let value = e.target.value;
        setState(prev => ({...prev, [name]: value}));
    }

    let auth = props.auth;
    if (auth.loggedIn) {
        if(subDomainData?.parent_id === 0) {
            props.fetchAllWhiteLabelCustomers();
            return <Redirect to="/whitelabelcustomers"/>
        } else {
            return <Redirect to="/"/>;
        }
    }

    return (
        <LoginPageCommon>
                <h5>{subDomainData?.homepage_logo_tagline}</h5>
            <div className={`outer-login-container ${websiteName}`}>
                <div className="left-align inner-login-container">
                    <h4>Sign In</h4>
                    <Row>
                        <TextInput s={12}
                                ref={emailField}
                                id="useremail"
                                inputClassName="border-text-box"
                                name="useremail"
                                placeholder="Email"
                                email validate autoFocus
                                value={useremail}
                                onChange={onChange}/>
                        <TextInput s={12}
                                id="password"
                                inputClassName="border-text-box"
                                name="password"
                                placeholder="Password"
                                password
                                value={password}
                                onChange={onChange}/>
                        <Col s={12}>
                            <Checkbox value="1" label="Remember me" checked={rememberMe}
                                    onChange={() => setRememberMe(!rememberMe)}/>
                        </Col>
                    </Row>
                    {
                        props.auth.loading || loginProgress ? <ProgressBar/> : ""
                    }
                    <Row>
                        <Col s={12}>
                            <Button className="btn-primary round-button login-button" disabled={!validateForm() || loginProgress}
                                    onClick={handleSubmit}>Login</Button>
                        </Col>
                        <Col s={12} style={{marginTop: "2vh", textAlign: "center"}}>
                            <Link className="password-reset" to="/password-reset">Forgot password?</Link>
                        </Col>
                    </Row>
                </div>
            </div>
        </LoginPageCommon>
    );
});

function mapStateToProps({auth, settings, subDomain}) {
    return {auth, settings, subDomain};
}
