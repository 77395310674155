import React, {useState} from "react";
import {connect} from "react-redux";
import {Link, Redirect} from "react-router-dom";
import {Button, Col, ProgressBar, Row, Select, TextInput} from "react-materialize";
import {axiosClient} from "../api/httpClient";
import {errorToast, successToast} from "../api/Util";
import ReCAPTCHA from "react-google-recaptcha";
import LoginPageCommon from "./LoginPageCommon";

const INITIAL_STATE = {
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    dropbox_email: "",
    secondary_email: "",
    referral: "NA",
    otherreferral: "",
    recaptcha: null,
    showEmailVerifyMessage: false,
}

export default connect(mapStateToProps)((props) => {

    const [progress, setProgress] = useState(false);
    const [state, setState] = useState(INITIAL_STATE);

    const websiteName = props?.subDomain?.subDomainData?.name;

    if (props.auth.loggedIn) {
        return (<Redirect to="/"/>);
    }

    function validateForm() {
        return state.firstname && state.lastname && state.email && state.password;
    }

    function handleSubmit() {
        setProgress(true);
        axiosClient.post('/api/signup', state)
            .then(response => {
                if (state.email.includes('@vipleadmachine.com') === false ) {
                    successToast('Confirmation link sent on your email address');
                    setState(prev => ({...prev, showEmailVerifyMessage: true}));
                }
                else {
                    successToast(response?.data?.message || "Account created successfully");
                    props.history.push("/login");
                }
            }).catch(err => {
                console.error(err);
                errorToast(err.response.data.message || "Something went wrong: " + err.message);
            }).finally(() => {
                setProgress(false);
            });
    }

    function onChange(e) {
        let name = e.target.name;
        let value = e.target.value;

        setState(prevState => ({...prevState, [name]: value}));
    }

    function onCaptcha(value) {
        setState(prevState => ({...prevState, recaptcha: value}));
    }

    return (
        <LoginPageCommon>
            <h5>Let us edit your videos!</h5>
            <div className={`outer-login-container ${websiteName}`}>
            <div className={`left-align inner-login-container ${websiteName}`}>
                    <h4 className="alignLabel">Sign Up</h4>

                    <Row>
                        <TextInput
                            s={12}
                            inputClassName="border-text-box"
                            name="firstname"
                            placeholder="First Name *"
                            autoFocus
                            value={state.firstname}
                            onChange={onChange}
                        />
                        <TextInput
                            s={12}
                            inputClassName="border-text-box"
                            name="lastname"
                            placeholder="Last Name *"
                            value={state.lastname}
                            onChange={onChange}
                        />
                        <TextInput
                            s={12}
                            inputClassName="border-text-box"
                            name="email"
                            placeholder="Email *"
                            email validate
                            value={state.email}
                            onChange={onChange}
                        />
                        <TextInput
                            s={12}
                            inputClassName="border-text-box"
                            name="password"
                            placeholder="Password *"
                            password
                            value={state.password}
                            onChange={onChange}
                        />
                        <div className="flipLabelTitle">
                            <Select
                                s={12}
                                className="border-text-box"
                                name="referral"
                                label="How did you find us?"
                                value={state.referral || "NA"}
                                onChange={onChange}>
                                <option value="NA">How did you find us?</option>
                                <option value="Google">Google</option>
                                <option value="Youtube">Youtube</option>
                                <option value="Facebook">Facebook</option>
                                <option value="Affiliate">Affiliate</option>
                                <option value="Other">Other</option>
                            </Select>
                        </div>
                        {
                            (state.referral === 'Other' || state.referral === 'Affiliate') &&
                            <TextInput
                                s={12}
                                inputClassName="border-text-box"
                                name="otherreferral"
                                placeholder="Please mention source"
                                value={state.otherreferral}
                                disabled={state.referral !== 'Other' && state.referral !== 'Affiliate'}
                                onChange={onChange}
                            />
                        }
                        <Col className="center-align recaptcha-box" s={12}>
                            <ReCAPTCHA
                                style={{display: "inline-block", width: "100%"}}
                                name="g-recaptcha-response"
                                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                onChange={onCaptcha}
                            />
                        </Col>
                        {
                            props.auth.loading || progress ? <ProgressBar/> : ""
                        }
                    </Row>
                    <Row className="center-align">
                        <Col s={12}>
                            <Button className={`btn-primary round-button login-button ${websiteName}`} disabled={!validateForm() || progress}
                                    onClick={handleSubmit}>Signup</Button>
                        </Col>
                        <Col s={12} style={{marginTop: "2vh", textAlign: "center"}}>
                            Click here to <Link to='/login'>Log In</Link>
                        </Col>
                        {
                            state.showEmailVerifyMessage ?
                        <Col s={12} style={{ marginTop:'15px' }}>
                            
                                    <span>
                                        We have sent an email with a confirmation link to your email address. In order to complete the sign-up process, please click the confirmation link.
                                    </span>
                        </Col>
                            : null
                        }
                    </Row>
                </div>
            </div>
        </LoginPageCommon>
    );
});

function mapStateToProps({auth, subDomain}) {
    return {auth, subDomain};
}
