import React, {useEffect, useState} from "react";
import {Button, Card, Col, Icon, ProgressBar, Row, Table} from "react-materialize";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {exportTableToCSV, getUserShortName, onSortTable, toISODate} from "../api/Util";
import {randomNumber} from "react-ratings-declarative/build/utils";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default ({data, start, end, title, tab}) => {
    const [filter, setFilter] = useState('');
    const [stats, setStats] = useState([]);
    const [sortBy, setSortBy] = useState(false);

    useEffect(() => {
        setStats(Object.keys(data || {})
        .map(userId => ({
            id: userId,
            name: data?.[userId]?.qa_name,
            total_requests: data?.[userId]?.total_request || 0,
            video_requests: data?.[userId]?.video_request || 0,
            inProgress: data?.[userId]?.in_progress || 0,
            onTime: data?.[userId]?.on_time || 0,
            overDue: data?.[userId]?.overdue || 0,
            revisionsOverDue: data?.[userId]?.revision_overdue || 0,
            completed: data?.[userId]?.completed || 0,
            avgHours: (data?.[userId]?.average_time / Math.max(1, data?.[userId]?.average_video_count)) || 0,
            revisions: data?.[userId]?.revision_count || 0,
            revisionsAvgHours: (data?.[userId]?.revision_average_time / Math.max(1, data?.[userId]?.revision_average_video_count)) || 0,
            avgRating: data?.[userId]?.total_rating / Math.max(data?.[userId]?.rated_videos, 1) || 0,
            avgResponseRate: (data?.[userId]?.response_rate / Math.max(data?.[userId]?.total_response_rated_cards, 1)) || 0,
            ratings: data?.[userId]?.rated_videos || 0,
        }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, title]);

    let tableId = title.replaceAll(/\s/g, '-') + '-' + randomNumber();

    return (
        <Card title={title}>
            <Row>
                <Col><Button large flat icon={<Icon left>save</Icon>} onClick={() => exportTableToCSV(tableId, `${title} ${toISODate(start)} - ${toISODate(end)}.csv`)}>Export as CSV</Button></Col>
                <Col s={6}><input type="text" onChange={e => setFilter(e.target.value)} placeholder="Filter" value={filter}/></Col>
            </Row>
            <Table responsive={true} hoverable={true} id={tableId}>
                <thead>
                <tr>
                    <th className="align-center" style={{cursor: "pointer"}}
                        onClick={() => onSortTable(stats, setStats, 'name', sortBy, setSortBy)}>Name
                    </th>
                    <th className="align-center" style={{cursor: "pointer"}}
                        onClick={() => onSortTable(stats, setStats, 'totalRequests', sortBy, setSortBy)}>Total Requests
                    </th>
                    <th className="align-center" style={{cursor: "pointer"}}
                        onClick={() => onSortTable(stats, setStats, 'videoRequests', sortBy, setSortBy)}>Pending Requests
                    </th>
                    <th className="align-center" style={{cursor: "pointer"}}
                        onClick={() => onSortTable(stats, setStats, 'inProgress', sortBy, setSortBy)}>In Progress
                    </th>
                    <th className="align-center" style={{cursor: "pointer"}}
                        onClick={() => onSortTable(stats, setStats, 'onTime', sortBy, setSortBy)}>On Time
                    </th>
                    <th className="align-center" style={{cursor: "pointer"}}
                        onClick={() => onSortTable(stats, setStats, 'overDue', sortBy, setSortBy)}>Overdue
                    </th>
                    <th className="align-center" style={{cursor: "pointer"}}
                        onClick={() => onSortTable(stats, setStats, 'revisionsOverDue', sortBy, setSortBy)}>Revisions Overdue
                    </th>
                    <th className="align-center" style={{cursor: "pointer"}}
                        onClick={() => onSortTable(stats, setStats, 'completed', sortBy, setSortBy)}>Completed
                    </th>
                    <th className="align-center" style={{cursor: "pointer"}}
                        onClick={() => onSortTable(stats, setStats, 'avgHours', sortBy, setSortBy)}>Average Time
                    </th>
                    <th className="align-center" style={{cursor: "pointer"}}
                        onClick={() => onSortTable(stats, setStats, 'revisions', sortBy, setSortBy)}>Revisions
                    </th>
                    <th className="align-center" style={{cursor: "pointer"}}
                        onClick={() => onSortTable(stats, setStats, 'revisionsAverageTime', sortBy, setSortBy)}>Revisions Average Time
                    </th>
                    <th className="align-center" style={{cursor: "pointer"}}
                        onClick={() => onSortTable(stats, setStats, 'avgRating', sortBy, setSortBy)}>
                        CSAT <FontAwesomeIcon icon="star" color="#FFD700" size="xs" title="☆"/>☆
                    </th>
                    {
                        (tab === 'QaTab'|| tab === 'TeamLeadTab') &&
                            <th className="align-center" style={{cursor: "pointer"}}
                                onClick={() => onSortTable(stats, setStats, 'avgResponseRate', sortBy, setSortBy)}>ART
                            </th>
                    }
                    <th className="align-center" style={{cursor: "pointer"}}
                        onClick={() => onSortTable(stats, setStats, 'ratings', sortBy, setSortBy)}>Ratings
                    </th>
                </tr>
                </thead>
                <tbody>
                {
                    stats?.map(user => <tr key={user.id + "-row"}>
                            {tab === 'QaTab' || tab === 'TeamLeadTab' ? (
                            <Link to={{ pathname: `/user/${user.id}/profile`, state: { fromLocationHistory: true, userType: tab, startDate: start, endDate: end } }} style={{ color: 'blue', cursor: 'pointer' }}>
                                <td className="align-center">{getUserShortName(user.name)}</td>
                            </Link>
                            ) : (
                            <td className="align-center">{user.name}</td>
                            )}
                            <td className="align-center">{user.total_requests}</td>
                            <td className="align-center">{user.video_requests}</td>
                            <td className="align-center">{user.inProgress}</td>
                            <td className="align-center">{user.onTime}</td>
                            <td className="align-center">{user.overDue}</td>
                            <td className="align-center">{user.revisionsOverDue}</td>
                            <td className="align-center">{user.completed}</td>
                            <td className="align-center"><ProgressBar progress={(100.0 - ((48.0 - user.avgHours) / 48.0) * 100)} />{user.avgHours.toFixed(2)} hrs</td>
                            <td className="align-center">{user.revisions}</td>
                            <td className="align-center"><ProgressBar progress={(100.0 - ((48.0 - user.revisionsAvgHours) / 48.0) * 100)} />{user.revisionsAvgHours.toFixed(2)} hrs</td>
                            <td className="align-center">{user.avgRating.toFixed(2)}</td>
                            <td className="align-center">{user.avgResponseRate.toFixed(2) > 100 ? 100 : user.avgResponseRate.toFixed(2)}</td>
                            <td className="align-center">{user.ratings}</td>
                        </tr>)
                }
                </tbody>
            </Table>
        </Card>
        );
}
