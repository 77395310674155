import React, { useEffect, useState } from 'react'
import { Container, ProgressBar, Row } from 'react-materialize';
import { useHistory } from 'react-router-dom';
import { axiosClient } from '../api/httpClient';
import { errorToast, successToast } from '../api/Util';


export default function VerifyEmail() {

    const [progress, setProgress] = useState(false);

    const history = useHistory();

    const verifyEmail = (emailToken) => {
        setProgress(true);
        axiosClient.post('/api/verify-email-address', { emailToken })
            .then(({ data }) => {
                if (data.status) {
                    successToast(data.message || "Email address verifed successfully");
                    history.push('/login');
                } else {
                    errorToast(data.message ?? "Something went wrong, please try again");
                }
            }).catch(err => {
                if (err?.response?.data?.message && err?.response?.data?.message !== "") {
                    errorToast(err.response.data.message);
                } else {
                    errorToast("Something went wrong, please try again");
                }
                console.error(err);
            }).finally(() => {
                setProgress(false);
            });
    }

    useEffect(() => {
        let query = history.location.search?.match(/token=([^&]*)/);
        let emailToken = query && query[1] && query[1] !== "" ? query[1] : null;
        if (emailToken && emailToken !== "") {
            verifyEmail(emailToken);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return <Container>
        {
            progress ? 
                <Row>
                    <ProgressBar />
                </Row> 
            : null
        }
        
    </Container>
}
