import React, {useState,useEffect} from 'react'
import { 
    Container,
    Row,
    Button,
    CardPanel,
    Checkbox,
    Col,
    Icon,
    ProgressBar,
    Collapsible } from 'react-materialize';
import { connect } from 'react-redux';
import * as actions from '../actions';
import { 
    YT_HELPER_ASSINED,
    YT_HELPER_IN_PROGRESS,
    YT_HELPER_IN_DONE,
    YT_HELPER_SECTION,
    YT_HELPER,
    YT_HELPER_LEAD,
    CUSTOMER,
    YT_HELPER_CANCELLED
} from '../api/Constants';
import CardList from './CardList';
import * as _ from 'lodash';
import PubSub from 'pubsub-js';
import {useLocation} from "react-router-dom";
import CardModal from './CardModal';
import {CARD_CLICKED} from "../api/Events";
import DragNDrop from './DragNDrop';
import { decodeBase64, getUserShortName, isAdmin, isSubAdmin, isTeamLead } from '../api/Util';
import Select from 'react-select';
import SearchBar from './SearchBar';
import TopHeader from './TopHeader';

export default connect(mapStateToProps,actions)((props) => {
    const [key, setKey] = useState(1);
    // for opning the modal 
    const [viewCardOpen, setViewCardOpen] = useState(false);
    // for storing the id of which to open  
    const [viewCardId, setViewCardId] = useState(0);
    //const location = useLocation();
    // get current login user
    const loggedInUser = props.auth?.loggedInUser || {};

    const [viewAs, setViewAsState] = useState(null);
    const [searchText, setSearchText] = useState();

    const [showCanceled, setShowCanceled] = useState(false);

    const location = useLocation();

    const admin = isAdmin(loggedInUser);
    const subAdmin = isSubAdmin(loggedInUser);
    const teamLead = isTeamLead(loggedInUser);
    const websiteName = props.subDomain.subDomainData.name;

    // function to open card
    const showCard = (cardId) => {
        setViewCardId(cardId);
        setViewCardOpen(true);
    }

    const searchCards = () =>{
        let obj = {
            offset : 0,
            limit:10,
            section : "",
            card_name: searchText ? searchText:"",
        }
        props.fetchYtHelperCards(obj,viewAs?.value);
    }

    function setViewAs(v) {
        setViewAsState(v);
        refresh(v.value);
    }

    const refresh = (user) => {
        setSearchText("");
        const obj = {
            offset:0,
            limit:10,
            section:"",
            card_name:"",
        }
        props.fetchYtHelperCards(obj,user || viewAs?.value);
    }

    useEffect(() => {
        if (props.location.search) {
            let encodeCardId = props.history.location.search.slice("?cardId=".length);
            let cardId = decodeBase64(encodeCardId);
            showCard(cardId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.location.search])

    useEffect(() => {
        let cardId = location.hash?.replace(/#card-/i, "");
        if (cardId && (props?.cards?.cardMap || {})[cardId]) {
            showCard(cardId);
        }
    }, [props.cards.cardMap, location.hash]);

    // to load all the cards if cards are not loaded till now
    useEffect(()=>{
        const subToken = PubSub.subscribe(CARD_CLICKED, (msg, data) => showCard(data));

        if (_.isEmpty(props.cards?.ytHelperCardList)) {
            refresh();
        }
        if((admin || teamLead ) && props.users.qas == null && !props.users.loadingUsers) {
            props.fetchAllUsers();
        }
        if ((admin || subAdmin || teamLead ) && props.users.customers === undefined && !props.users.loadingCustomers) {
            props.fetchAllCustomers();
        }
        return () => PubSub.unsubscribe(subToken);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[viewAs, admin, subAdmin])

    return <Container>
        <div className="aside-right-top-bar ythelper-dashboard-topbar">
            <div className="top-bar-left">
                <div className="top_bar">
                    <SearchBar cards = {props.cardList} setSearchText = {setSearchText} searchText = {searchText} searchCards ={searchCards} />
                </div>
            </div>
            <div className="top-bar-right">
                <TopHeader/>
            </div>
        </div>

        <DragNDrop loggedInUser={loggedInUser} refresh={refresh} setKey={setKey}/>
        <CardModal cardId={viewCardId} isOpen={viewCardOpen} onClose={setViewCardOpen.bind(this, false)} refresh={refresh} section = {YT_HELPER_SECTION}
                       qas={props.users.qas} users = {props.users.users} me={loggedInUser} />
        {props.cards.loading ? <Row><ProgressBar /></Row> : ""}
        <div className="ythelper-main">
            <Row style={{marginTop: "5px", marginBottom: "0",}} className="hide-on-med-and-down difplay_flext">
                <Col style={{margin: '5px', padding: 0}}>
                    <Button icon={<Icon>refresh</Icon>} tooltip="Reload Cards" onClick={()=> refresh()}
                            className="z-depth-1 center-align btn-chiclet btn-primary" style={{maxHeight: "70px"}}/>
                </Col>
                {
                    // View as functionality is only for team lead, for admin and for subadmin
                    (admin || subAdmin || isTeamLead(loggedInUser)) &&
                    <Col style={{margin: '5px', padding: 0, paddingRight: "15px"}} s={12} m={3}>
                        <Select className="z-depth-1 select-view-as"
                            value={viewAs}
                            onChange={setViewAs}
                            // for yt helper section view as list will be only for yt helper team lead , yt helper , and customer which has yt helper 
                            options={_.concat([{...loggedInUser, fullname: 'Me'}], props.users?.users?.filter(user => user.client_type === YT_HELPER) || [], 
                                    props.users?.customers || [])
                                .filter((user) =>  user.fullname === 'Me'||user.client_type === YT_HELPER || user.client_type === YT_HELPER_LEAD || (user.client_type === CUSTOMER && user.has_youtube_helper ))
                                .map(user => ({value: user.client_id, label: user.client_type === 'customer' ? `${user.fullname} (${user.client_type})` : `${getUserShortName(user.fullname)} (${user.client_type})`}))
                            }
                            placeholder="View As"
                        />
                    </Col>
                }
                {/* <Col className="search_bar_top" style={{margin: '5px', padding: 0}}>
                    <SearchBar cards = {props.cardList} setSearchText = {setSearchText} searchText = {searchText} searchCards ={searchCards} />
                </Col> */}
            {
                (admin || subAdmin || teamLead) &&
                <Col style={{margin: '5px', padding: 0}}>
                    <CardPanel className="toggle-panel">
                        <Checkbox value="1" label="Show Canceled Cards" checked={showCanceled} id="check-canceled"
                            onChange={setShowCanceled.bind(this, Boolean(!showCanceled))}/>
                    </CardPanel>
                </Col>
            }
        </Row>
        <Row style={{overflowX: "scroll", marginBottom: "0", }}>
                <table className="cards-panel">
                    <tbody>
                        <tr>
                            <td width="25%">
                                <Collapsible className={`assigned-card-list ${websiteName}`} accordion>
                                    <CardList name="Assigned" cards={props.cards?.ytHelperCardList?.assigne_card} section = {YT_HELPER_SECTION}
                                              viewCard={showCard} key={"vr-" + key} searchText = {searchText} viewAs = {viewAs?.value}
                                              ytType={YT_HELPER_ASSINED}/>
                                </Collapsible>
                            </td>
                            {
                                showCanceled &&
                                <td width="25%">
                                    <Collapsible className={`canceled-list ${websiteName}`} accordion>
                                        <CardList name="Cancelled" cards={props.cards?.ytHelperCardList?.cancelled} section = {YT_HELPER_SECTION}
                                                  viewCard={showCard} key={"vr-" + key} searchText = {searchText} viewAs = {viewAs?.value}
                                                  ytType={YT_HELPER_CANCELLED}/>
                                    </Collapsible>
                                </td>
                            }
                            <td width = "25%">
                                <Collapsible className={`in-progress-card-list ${websiteName}`} accordion>
                                    <CardList name="In Progress" cards={props.cards?.ytHelperCardList?.inProgress} section = {YT_HELPER_SECTION}
                                              viewCard={showCard} key={"vr-" + key} searchText = {searchText} viewAs = {viewAs?.value}
                                              ytType={YT_HELPER_IN_PROGRESS}/>
                                </Collapsible>
                            </td>
                            <td width = "25%">
                                <Collapsible className={`done-list ${websiteName}`} accordion>
                                    <CardList name="Done" cards={props.cards?.ytHelperCardList?.inDone} section = {YT_HELPER_SECTION}
                                              viewCard={showCard} key={"vr-" + key} searchText = {searchText} viewAs = {viewAs?.value}
                                              ytType={YT_HELPER_IN_DONE}/>
                                </Collapsible>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Row>
        </div>
    </Container>
});

function mapStateToProps({cards, auth, subDomain, users, settings}) {
    return {cards, auth, subDomain, users, settings};
}
