import {FETCH_USER, FETCHING_USER, TOKEN_EXPIRED, USER_LOGOUT,FETCH_NOTIFICATION_COUNT,FETCHING_NOTIFICATION_COUNT,FETCH_NOTIFICATION_LIST,FETCHING_NOTIFICATION_LIST} from "../actions/types";
const initialState = {
    checkingAuth: true, 
    loggedIn: localStorage.getItem('Authorization') ? true :  null, 
  };
export default function (state = initialState, { payload, type }) {
    switch (type) {
        case FETCHING_USER:
            return { ...state, loading: true };
        case FETCH_USER:
            return {
                ...state,
                loggedIn: payload?.status >= 200 && payload?.status < 300,
                loggedInUser: payload?.status >= 200 && payload?.status < 300 ? payload.data : null,
                loading: false,
                checkingAuth: false, // Auth check is complete
              };
        case USER_LOGOUT:
            return {
                ...state,
                loggedIn: payload.status >= 200 && payload.status < 300 ? false : state.loggedIn,
                checkingAuth: false,
              };
        case TOKEN_EXPIRED:
            return { ...state, loggedIn: false, checkingAuth: false };
        case FETCHING_NOTIFICATION_COUNT:
            return { ...state, loading: true };
        case FETCH_NOTIFICATION_COUNT:
            return { ...state, notificationCount : payload, loading: false };
        case FETCHING_NOTIFICATION_LIST:
            return { ...state, loading: true };
        case FETCH_NOTIFICATION_LIST:
            return { ...state, notificationList : payload, loading: false };
        default:
            return state;
    }
}