import React from 'react';
import {Col, Row} from "react-materialize";

export default ({ children }) => {
    let subDomainData = JSON.parse(localStorage.getItem('subDomainData'));
    return (
        <div className={`login-main-container  ${subDomainData?.name}`}>
             <Row style={{ marginBottom: 0 }}>
            <Col l={3} className={`loginPaneRight ${subDomainData?.name === "upkeepmedia" && "upkeepMediaLogoTagLine" }`}>
            <div className="logo-box">
                {subDomainData.homepage_logo ? <img style={{margin: "auto", width: "250px", borderRadius: "10px"}} src={subDomainData?.homepage_logo} alt="videoportal"/> : null}
                    {children[0] || <h5 className='text-center'>Welcome Back<br/>Pleasure To See You Again</h5>}
                    <a href="https://videoportal.com/yt-helper/" target="_blank" rel="noopener noreferrer">
                        {subDomainData.homepage_ythelper_logo ? <img style={{margin: "auto", marginTop: "48px", width: "250px", borderRadius: "10px"}} src={subDomainData.homepage_ythelper_logo} alt="YouTube Helper"/> : null}
                        {subDomainData.homepage_ythelper_tagline ? <h5 className='text-center'>{subDomainData?.homepage_ythelper_tagline}</h5> : null}
                    </a>
                </div>
                { children[1] }
            </Col>
        </Row>
        </div>
    );
}
