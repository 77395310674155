import React from 'react';
import Login from "./Login";
import Signup from "./Signup";
import PasswordReset from "./PasswordReset";
import VerifyEmail from './VerifyEmail';


const PublicRoutes = [
    { path: "/customer/signup", component: Signup },
    { path: "/customer/login", component: Login },
    { path: "/login", component: Login },
    { path: "/signup", component: Signup },
    { path: "/verify-email", component: VerifyEmail },
    { path: "/password-reset", component: PasswordReset },
];


export default PublicRoutes;
