import {axiosClient} from '../api/httpClient';

export const fetchCustomerDetails = async (filterData) => {
	try {
		const data = await axiosClient.get('/api/exportCustomers', {
            params: filterData,
        });
		return {
            status: true,
			data: data?.data,
		}
	} catch (error) {
		return {
            status: false,
			error: "Some Error Occured",
		}
	}
};
