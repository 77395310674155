import React, {useEffect, useState , useRef} from "react";
import {Button, Col, Divider, Icon, Modal, ProgressBar, Row, TextInput, Switch, Select as MaterialSelect} from "react-materialize";
import {axiosClient} from "../api/httpClient";
import {
    cardInProgress,
    dateFormatISO,
    errorToast,
    getCardDisplayStatus,
    infoToast,
    isAdmin,
    isSubAdmin,
    parseSqlDateTime,
    successToast,
    warningToast,
    repurposingVideosName,
    S3_BUCKET,
    myBucket,
    isCustomer,
    getUserShortName,
} from "../api/Util";
import ConfirmationDialog from "./ConfirmationDialog";
import DateTimePicker from "react-datetime-picker";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactTooltip from 'react-tooltip';
import ChatBox from "./ChatBox";
import {ADMIN, CANCELED, CUSTOMER,QA, DONE, ON_HOLD, YT_HELPER, YT_HELPER_IN_DONE, YT_HELPER_IN_PROGRESS, YT_HELPER_LEAD, YT_HELPER_SECTION, TEAM_LEAD, SUB_ADMIN} from "../api/Constants";
import CompleteRatingButton from "./CompleteRatingButton";
import ReviewButton from "./ReviewButton";
import ArchiveButton from "./ArchiveButton";
import PlayPauseButton from "./PlayPauseButton";
import { MdTimeline ,MdPerson,MdSchedule,MdAdd,MdRedo,MdComment,MdAspectRatio,MdHelp,MdShortText,MdKeyboardVoice } from 'react-icons/md';
import {BsPersonSquare,BsLink,BsFillInfoCircleFill, BsCheckAll} from 'react-icons/bs'
import Select from 'react-select'
import RepurposeButton from "./RepurposeButton";

import parse from 'html-react-parser';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import Annotation from 'react-image-annotation'
import {
    PointSelector,
    RectangleSelector,
    OvalSelector
} from 'react-image-annotation/lib/selectors'
import { RiFileUserLine } from  'react-icons/ri';
import VideoPlayTab from "./common/VideoPlayTab";

const INITIAL_STATE = {
    card: {
        card_title: "Card Details"
    },
    videoLink: '',
    videoVersions:{},
    videoVersionsCount:0,
};

function parseVideoLink(link) {
    const DBX_LINK = /http(s)?:\/\/(www.)?dropbox.com(.)+/i;

    if(DBX_LINK.test(link)) {
        return link.replace('dl=0', 'raw=1');
    }
    if((link).search("vidchops-videos.s3-accelerate.amazonaws.com") !== -1 || (link).search("videoportal-videos.s3-accelerate.amazonaws.com") !== -1)
    {
        return link;
    }
    if((link).search('digitaloceanspaces.com') !== -1)
    {
        return link;
    }

    return null;
}

const INITIAL_ANNOTATION_STATE = {
    type: RectangleSelector.TYPE,
    annotation : {},
    annotations : [],
    activeAnnotations : [],
    showAnnotations:true,
    callChatApi:false
}

export default (props) => {
    const [state, setState] = useState(INITIAL_STATE);
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);

    const [showThumbnail, setShowThumbnail] = useState(true);

    const [newCardName, setNewCardName] = useState(null);
    const [changeCardName, setChangeCardName] = useState(false);

    const videoRef = useRef();

    const [showVideoDetail, setShowVideoDetail] = useState(true);
    const [showMusicGuide, setShowMusicGuide] = useState(false);
    const [showVideoLink, setShowVideoLink] = useState();

    const [currentVideoUrl, setCurrentVideoUrl] = useState(null);
    const [isGDVideo, setIsGDVideo] = useState(false);
    const [showLargeChat, setShowLargeChat] = useState(false);
    const [changeDoneVideoUrlState, setChangeDoneVideoUrlState] = useState({isEditing: false});
    const [changeRepurposeVideoUrlState, setChangeRepurposeVideoUrlState] = useState({isEditing: false});
    const [changeUnlistedVideoVideoUrlState, setChangeUnlistedVideoVideoUrlState] = useState({isEditing: false});
    const [changeVideoThumbnailLink, setChangeVideoThumbnailLink] = useState({isEditing: false});
    const [changeSnippetVideoLink, setChangeSnippetVideoLink] = useState({isEditing: false});
    const [changeContentIdState, setChangeContentIdState] = useState({isContentIdEditing: false});
    const [selectedFile, setSelectedFile] = useState(null);
    const [editSrtFile, setEditSrtFile] = useState({isEditing: false});

    const [fetchChatForId, setFetchChatForId] = useState(null);
    const [videoVersionsState, setVideoVersionsState] = useState({currentVersion:null,options:[]});
    const [revisionButtonEnabled, setRevisionButtonEnabled] = useState(true);

    const GD_LINK = /http(s)?:\/\/drive\.google\.com\/file\/d\/([^\\]+)\/.*/i;

    const isYtSection = props?.section === YT_HELPER_SECTION ? true : false;

    // For Annotation :
    const [annotationStates, setAnnotationStates] = useState(INITIAL_ANNOTATION_STATE);
    const [selectedVideoType, setSelectedVideoType] = useState('Vi1-Square');
    const subDomain = JSON.parse(localStorage.getItem('subDomainData'));

    useEffect(() => {
        setAnnotationStates(INITIAL_ANNOTATION_STATE)
    }, [props.isOpen])

    const loadCard = async () => {
        if (loading) {
            return;
        }

        setLoading(true);
        try {
            const {data} = await axiosClient.get('/api/card/' + props.cardId);

            if(props.me.client_type === CUSTOMER && data.card_status === QA)
            {
                setShowLargeChat(true);
                setShowVideoDetail(false);
            }

            // when card is in yt helper section then chat should be visible  
            if(props?.section && props.section === YT_HELPER_SECTION && !data?.thumbnails_link)
            {
                setShowLargeChat(true);
            }
            else if(isYtSection && data?.thumbnails_link)
            {
                setShowThumbnail(true);
                if(GD_LINK.test(data.thumbnails_link))
                {
                    let matches = data.thumbnails_link
                    if(matches.length >= 3)
                    {
                        setShowLargeChat(false);
                    }
                    else{
                        setShowLargeChat(true);
                    }
                }
                else
                {
                    setShowLargeChat(true);
                }
            }
            let videoVersionsCount = 0;
            let videoVersions = [];

            if (data.repurpose_status !== "repurposeQA" && data.repurpose_video_versions && Object.keys(data.repurpose_video_versions).length > 0) {
                videoVersions = data.repurpose_video_versions;
                videoVersionsCount = Object.keys(data.repurpose_video_versions).length;
            } else if ((data.repurpose_status === "repurposeQA" || data.latest_revision_from?.video_type === "repurposeQA") && data.repurpose_qa_video_versions && Object.keys(data.repurpose_qa_video_versions).length > 0) {
                videoVersions = data.repurpose_qa_video_versions;
                videoVersionsCount = Object.keys(data.repurpose_qa_video_versions).length;
            } else if (data.repurpose_status && data.repurpose_done_link && data.repurpose_done_link !== "") {
                if(data.done_videos && data.done_video_count > 0) {
                    setFetchChatForId(data.done_videos[0].video_id);
                }
                setCurrentVideoLink(data.repurpose_done_link);

            } else if ( data.video_versions && Object.keys(data.video_versions).length > 0) {
                videoVersions = data.video_versions;
                videoVersionsCount = Object.keys(data.video_versions).length;
            }

            setState({
                card: data,
                dueDate: props?.section === YT_HELPER_SECTION ? ( data.yt_due_date ? parseSqlDateTime(data.yt_due_date) : null ) : ( data.due_date ? parseSqlDateTime(data.due_date) : null ),
                videoLink:data.repurpose_done_link ? data.repurpose_done_link : data.done_video_link,
                videoVersions,
                videoVersionsCount,
            });
            setAssignQa({ value: data.assigned_qa_id, label: getUserShortName(data.assigned_to) } || "0");
            setAssignYT({ value: data.yt_helper_id, label: getUserShortName(data.yt_assigned_to)} || "0");
            setNewCardName(data.card_title);
            
            if (!isYtSection && videoVersionsCount > 0) {
                setShowVideoLink(videoVersions[`version-${videoVersionsCount - 1}`][0].video_id);
                let temp = videoVersions[`version-0`][0];
                setFetchChatForId(temp.parent_link_id ? temp.parent_link_id : temp.video_id);
            } else if(!isYtSection && data.repurpose_done_videos.length > 0) {
                setShowVideoLink(data.repurpose_done_videos[0].video_id);
                setFetchChatForId(data.repurpose_done_videos[0].parent_link_id);
            } else if(!isYtSection) {
                setShowLargeChat(true);
            }
        } catch (err) {
            errorToast(err?.response?.data?.message || "Couldn't fetch card content");
            console.error(err);
        }
        setLoading(false);
    };

    useEffect(() => {
        setChangeCardName(false);
        setShowVideoDetail(true);
        setShowVideoLink(null);
        setFetchChatForId(null);
        setVideoVersionsState({currentVersion:null,options:[]});
        setState(INITIAL_STATE);
        setCurrentVideoUrl(null);
        setIsGDVideo(false);
        setShowLargeChat(false);
        setChangeDoneVideoUrlState({isEditing: false});
        setChangeRepurposeVideoUrlState({isEditing: false});
        setChangeUnlistedVideoVideoUrlState({isEditing: false});
        setChangeVideoThumbnailLink({isEditing: false});
        setChangeSnippetVideoLink({isEditing: false});
        setEditSrtFile({isEditing: false});
        setSelectedFile(null);
        setChangeContentIdState({isContentIdEditing: false});
    }, [props.isOpen])

    useEffect(() => {
        if(props.isOpen) {
            loadCard().then(() => {});
            const video = document.getElementById("preview-vid");
            if(video) {
                video.load();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isOpen, props.cardId]);

    useEffect(() => {
        var option;
        if(props?.section !== YT_HELPER_SECTION){
            option = (props.users || []).filter(user => user.client_type === "qa" || user.client_type === "team-lead").map(user => {
                return {
                    value:`${user.client_id}` , 
                    label: `${getUserShortName(user?.fullname)}`
                }
            })
            option = [{value:"0" ,label:"None"},...option];
            setOptions(option);
        }
        else{
            option = (props.users || []).filter(user => user.client_type === YT_HELPER || user.client_type === YT_HELPER_LEAD).map(user => {
                return {
                    value:`${user.client_id}` , 
                    label: `${getUserShortName(user.fullname)} (${user.client_type})`
                }
            })
            option = [{value:"0" ,label:"None"},...option];
            setOptions(option);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.users,props?.section])

    useEffect(() => {
        if (props.isOpen && !isYtSection && showVideoDetail) {
            setIsGDVideo(false);
            setShowLargeChat(false);
            setCurrentVideoUrl(null);

            let currentId = null;
            let videoArray = null;

            if (state.videoVersions && state.videoVersionsCount > 0 ) {
                currentId = showVideoLink;
                if (state.card.repurpose_status === 'repurposeQA') {
                    videoArray = mapAllRevisionLink(state.card.repurpose_qa_video_versions, Object.keys(state.card.repurpose_qa_video_versions).length, videoVersionsState.currentVersion);
                } else if (state.card.repurpose_status === 'done' || state.card.repurpose_status === 'editing') {
                    if (state.card.repurpose_video_versions && Object.keys(state.card.repurpose_video_versions).length > 0) {
                        videoArray = mapAllRevisionLink(state.card.repurpose_video_versions, Object.keys(state.card.repurpose_video_versions).length, videoVersionsState.currentVersion);
                    } else {
                        videoArray = state.videoVersions[videoVersionsState.currentVersion];
                    }
                } else {
                    videoArray = state.videoVersions[videoVersionsState.currentVersion];
                }
            } else if ( state.card.repurpose_status && state.card.repurpose_done_link && state.card.repurpose_done_link !== "") {
                let videoUrl = state.card.repurpose_done_link;
                
                if(state.card.done_videos && state.card.done_video_count > 0) {
                    setFetchChatForId(state.card.done_videos[0].video_id);
                }

                setCurrentVideoLink(videoUrl);
                return;
            }

            if (videoArray && videoArray.length > 0) {
                let videoData = videoArray.filter(d => d.video_id === currentId);
                let baseVersionVideo = state.videoVersions['version-0'];
                if (videoData[0] && videoData[0].video_url) {
                    let videoIndex = videoArray.findIndex(data => data.video_id === currentId);
                    if (baseVersionVideo[videoIndex] && baseVersionVideo[videoIndex].parent_link_id) {
                        setFetchChatForId(baseVersionVideo[videoIndex].parent_link_id);
                    } else if (baseVersionVideo[videoIndex] && baseVersionVideo[videoIndex].video_id) {
                        setFetchChatForId(baseVersionVideo[videoIndex].video_id);
                    } else {
                        let baseVersionVideoId = getBaseVersionVideoId(videoIndex);
                        setFetchChatForId(baseVersionVideoId ? baseVersionVideoId : currentId);
                    }

                    let videoUrl = videoData[0].video_url;

                    setCurrentVideoLink(videoUrl);
                    return;
                }
            }
            setCurrentVideoUrl(null);
            setShowLargeChat(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showVideoLink]);

    useEffect(() => {
        if (currentVideoUrl && currentVideoUrl !== "") {
            let doneVideo = document.getElementById("preview-vid");
            if(doneVideo) {
                doneVideo.load();
            }
        }
    }, [currentVideoUrl]);

    useEffect(() => {
        if (state.videoVersions && state.videoVersionsCount > 0) {
            let options = [];
            let versions = Object.keys(state.videoVersions);

            options = (versions || []).map((v, i) => {
                return <option key={'option-' + v + '-key'} value={v}>{`versions ${i + 1}`}</option>
            });
            setVideoVersionsState(prev => ({ ...prev, currentVersion: versions[state.videoVersionsCount - 1], options }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state?.videoVersions])

    useEffect(() => {
        if (videoVersionsState.currentVersion) {
            setShowVideoLink(state.videoVersions[videoVersionsState.currentVersion][0].video_id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [videoVersionsState.currentVersion])

    if(videoRef.current)
    {
        videoRef.current.disablePictureInPicture = true;
    }

    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [ytConfirmationOpen, setYTConfirmationOpen] = useState(false);
    const [assignQa, setAssignQa] = useState({ value: `${state.card?.assigned_qa_id || ''}`, label: `${getUserShortName(state.card?.assigned_to) || ''}` } || "0");
    const [assignYT, setAssignYT] = useState({value : `${state.card?.yt_helper_id || ''}`,label:`${getUserShortName(state.card?.yt_assigned_to) || ''}`});

    function onConfirmChangeQa() {
        infoToast("Changing assigned QA");
        axiosClient.post('/api/card/assign', { card_id: props.cardId, assign_to: Number(assignQa.value)})
            .then(({data}) => {
                if(data.success) {
                    successToast(data.message);
                }
            }).catch(err => {
                if (err?.response?.data?.message && err?.response?.data?.message !== "") {
                    errorToast(err.response.data.message);
                } else {
                    errorToast("Something went wrong");
                }
            });
    }
    function onConfirmChangeYT() {
        infoToast("Changing assigned YT Helper");
        axiosClient.post('/api/ythelper/card/assign', { card_id: props.cardId, assign_to: Number(assignYT.value)})
            .then(({data}) => {
                if(data.success) {
                    successToast(data.message);
                } else {
                    errorToast(data.message);
                }
            });
    }

    function updateDueDate() {
        if(!state.dueDate) {
            warningToast("Please select a valid due date");
            return;
        }

        let newDueDate = dateFormatISO(state.dueDate);

        const commenCardApi = '/api/card/updateDueDate';
        // for yt helper card due date
        const ytCardApi = '/api/ythelper/card/updateDueDate';

        axiosClient.post(props?.section === YT_HELPER_SECTION ? ytCardApi : commenCardApi , {card_id: props.cardId, due_date: newDueDate})
            .then(() => {
                successToast(`Card ${state.card.card_title} due date changed to ${newDueDate}`);
            }).catch(err => {
                errorToast('Something went wrong, please try again');
                console.error(err);
            })
    }
    const customStyles = {
        control: base => ({
          ...base,
          height:"45px",
        })
    };  

    function makeUserSelection(qas) {
        return <div className = 'dropdown'>
            <ConfirmationDialog
                key="confirm-assign-qa"
                onNegative={() => setConfirmationOpen(false)}
                onPositive={onConfirmChangeQa}
                confirmationHeader="Confirmation"
                confirmationDialogId="confirm-assign-qa-dialog"
                confirmation={confirmationOpen}
                confirmationText="Are you sure you want to change assigned QA?"
            />
            <Col className="left-padding" s={12} m={4} key="col-h">
                <RiFileUserLine style = {{ float : 'left' ,marginTop : '0',marginRight:'8px' }} size = '20px'/><strong className="blue-text">Assigned to: </strong>
            </Col>
            <Col className="select_dropdown" s={12} m={8} key="col-s">
                <Select
                    styles = {customStyles}
                    className="basic-single"
                    classNamePrefix="select"
                        value={assignQa}
                        options = {options}
                        onChange={e => { setAssignQa(e); setConfirmationOpen(true); }}>
                </Select>
            </Col>
        </div>;
    }

    function makeYTSelection() {
        return <div className = 'dropdown'>
            <ConfirmationDialog
                key="confirm-assign-yt"
                onNegative={() => setYTConfirmationOpen(false)}
                onPositive={onConfirmChangeYT}
                confirmationHeader="Confirmation"
                confirmationDialogId="confirm-assign-yt-dialog"
                confirmation={ytConfirmationOpen}
                confirmationText="Are you sure you want to change assigned YT Helper?"
            />
            <Col className="left-padding" s={12} m={4} key="col-h">
                <RiFileUserLine style = {{ float : 'left' ,marginTop : '0px',marginRight:'8px' }} size = '20px'/><strong className="blue-text">Assigned to: </strong>
            </Col>
            <Col className="select_dropdown" s={12} m={8} key="col-s">
                <Select
                    styles = {customStyles}
                    className="basic-single"
                    classNamePrefix="select"
                        value={assignYT}
                        options = {options}
                        onChange={e => { setAssignYT(e); setYTConfirmationOpen(true); }}>
                </Select>
            </Col>
        </div>;
    }

    function makeCardHeader() {
        let ratingStar = "";
        let completeIcon = "";
        let repurposeConpleteIcon = "";
        let repurposeRatingStar = "";
        if (props?.section !== YT_HELPER_SECTION && state.card.card_status === DONE) {
            let rating = state.card.doneCardRating ?
                (<strong className="fa-layers-text">{state.card.doneCardRating}</strong>) :
                (<FontAwesomeIcon icon="question" color="black" size="xs"/>);

            ratingStar = (
                <span className="fa-layers fa-fw fa-pull-right">
                <FontAwesomeIcon icon="star" color="#FFD700"/>
                    {rating}
            </span>
            )

            if (state.card.is_complete) {
                completeIcon =
                  state.card.card_status === DONE &&
                  state.card.yt_helper_status &&
                  state.card.role === YT_HELPER &&
                  state.card.is_complete ? (
                    <>
                      <FontAwesomeIcon
                        data-tip="This video is marked done for upload"
                        data-place="bottom"
                        icon="check"
                        color="green"
                        pull="right"
                      />
                      <ReactTooltip />
                    </>
                  ) : (
                    <FontAwesomeIcon
                      icon="check"
                      color="green"
                      pull="right"
                    />
                  );
            }

            if (state.card.is_repurpose_complete) {
                let repurposeRating = state.card.repurposeCardRating ?
                    (<strong className="fa-layers-text">{state.card.repurposeCardRating}</strong>) :
                    (<FontAwesomeIcon icon="question" color="black" size="xs"/>);

                repurposeRatingStar = (
                    <span className="fa-layers fa-fw fa-pull-right">
                        <FontAwesomeIcon icon="star" color="#FFD700"/>
                        {repurposeRating}
                    </span>
                );
                repurposeConpleteIcon = (
                    <BsCheckAll style={{ float:'right',color:'green', strokeWidth: '1px', width: '27px', height: '27px' }} />
                );
            }
        }
        else if(props?.section === YT_HELPER_SECTION && state.card.ytHelper_card_status === YT_HELPER_IN_DONE)
        {
            let rating = state.card.doneCardRating ?
                (<strong className="fa-layers-text">{state.card.doneCardRating}</strong>) :
                (<FontAwesomeIcon icon="question" color="black" size="xs"/>);
            ratingStar = (
                <span className="fa-layers fa-fw fa-pull-right">
                <FontAwesomeIcon icon="star" color="#FFD700"/>
                    {rating}
            </span>
            )
            if (state.card.yt_is_complete) {
                completeIcon = (
                    <FontAwesomeIcon icon="check" color="green" pull="right"/>
                );
            }
        }

        return (
            <div>
                {/* {state.card.card_title} */}
                
                <div className = "listing_button1">
                    {
                        (props.me.client_type === ADMIN || props.me.client_type === SUB_ADMIN) && changeCardName && <TextInput className = "input-card-title" label="New Card Title" value={newCardName} onChange={(e)=>setNewCardName(e.target.value)}/>
                    }
                <div className ="btn-side1">
                    {
                        (props.me.client_type === ADMIN || props.me.client_type === SUB_ADMIN) && !changeCardName && <Button icon={<Icon>edit</Icon>} className = "edit-btn-card-title" tooltip = "Change Card Title" onClick={()=>setChangeCardName(true)} ></Button>
                    }
                    {
                        (props.me.client_type === ADMIN || props.me.client_type === SUB_ADMIN) && changeCardName && [
                            <Button icon={<Icon>close</Icon>} className = "cancel-btn-change-card-title" key="cancel-change-card-title" tooltip="Cancel" onClick={()=>{setChangeCardName(false);setNewCardName(state.card.card_title)}}/>,
                            <Button icon={<Icon>save</Icon>} className = "save-btn-change-card-title" key="save-change-card-title" tooltip = "Save Card Title" onClick={handleCardNameChange} />
                        ]
                    }
                </div>
                {
                    !changeCardName ? newCardName:null
                }
            </div>
                {ratingStar}{completeIcon}{repurposeRatingStar}{repurposeConpleteIcon}</div>
        );
    }

    function makeQaAndDueDateRow() {
        if(isAdmin(props.me) || props.me.client_type === YT_HELPER_LEAD) {
            return React.Children.toArray([
                <Row className ="bottom_row">
                    <div className="inside_linkg">
                    {props?.section === YT_HELPER_SECTION ? makeYTSelection() : makeUserSelection(props.qas)}
                    {
                        state.dueDate  && (( props?.section !== YT_HELPER_SECTION && cardInProgress(state.card) )
                        || ( props?.section === YT_HELPER_SECTION && state.card.ytHelper_card_status === YT_HELPER_IN_PROGRESS )) && [
                            <Col className="left-padding" s={12} m={4} key="due-on">
                                <MdSchedule style = {{ float : 'left' ,marginTop : '-2px' ,marginRight:'5px'}} size = '25px' /><strong className="blue-text">Due on:</strong>
                            </Col>,
                            <Col className="date_feature date-datetime-picker-box" s={12} m={8} key="save-due-date">
                                <Button icon={<Icon left>save</Icon>} className="center-align btn-primary" large
                                        style={{margin: 0, padding: 0, marginLeft: "0", marginRight: "10px", paddingLeft: "16px"}}
                                        onClick={updateDueDate}>
                                </Button>
                                <DateTimePicker className="due-date-datetime-picker" value={state.dueDate} amPmAriaLabel="Select AM/PM" clearIcon={null}
                                                onChange={(d) => setState(prev => ({...prev, dueDate: d}))}/>
                                <span className="due-time-message"> EST</span>
                            </Col>,
                        ]
                    }
                    </div>
                </Row>,
            ]);
        } else {
            return React.Children.toArray([
                <Row className ="bottom_row">
                    <div className="inside_linkg">
                        <Col s={12} m={4}>
                        <BsPersonSquare style = {{ float : 'left' ,marginTop : '-2px' ,marginRight:'5px'}} size = '25px' /><strong className="blue-text">Assigned to :</strong>
                        </Col>
                        <Col s={12} m={8}>
                            <div style={{paddingLeft: '0'}}>{ props?.section === YT_HELPER_SECTION ? state.card.yt_assigned_to :state.card.assigned_to}</div>
                        </Col>
                    </div>
                </Row>,
                (props?.section === YT_HELPER_SECTION ? state.card.yt_due_date && state.card.ytHelper_card_status !== YT_HELPER_IN_DONE :  
                    state.dueDate && cardInProgress(state.card) )?
                <Row className ="bottom_row">
                    <div className="inside_linkg">
                    <Col s={12} m={4}>
                    <MdSchedule style = {{ float : 'left' ,marginTop : '-2px' ,marginRight:'5px'}} size = '25px' /><strong className="blue-text">Due on:</strong>
                    </Col>
                    <Col s={12} m={8}>
                        <div style={{paddingLeft: '0'}}>{props?.section === YT_HELPER_SECTION ? state.card.yt_due_date : state.card.due_date} EST</div>
                    </Col>
                    </div>
                </Row> : null,
            ]);
        }
    }

    function onVideoPreviewLoadedMetadata() {
        let videoPreview = document.getElementById("preview-vid");
        let duration = videoPreview.duration || 0.0;
        if(duration <= 0.01 || isNaN(duration) || !isFinite(duration)) {
            setShowLargeChat(true);

        }
    }
    function onVideoPreviewLoadedMetadataError() {
        let videoPreview = document.getElementById("preview-vid");
        let duration = videoPreview.duration || 0.0;
        if(duration <= 0.01 || isNaN(duration) || !isFinite(duration)) {
            setShowLargeChat(true);
        }
    }

    function embedGDLink() {
        let matches = currentVideoUrl?.match(GD_LINK);
        // eslint-disable-next-line no-unused-vars
        let key = "";
        if (props.me.client_type === ADMIN) {
            key = "AIzaSyBAvcPRGkcuWpt7d7bG0PVyj_AN67VUvBc";
        }
        else if (props.me.client_type === CUSTOMER) {
            key = "AIzaSyBdJXJ3nr3zC6wWJscp767e6ABzv5d0vlc";
        }
        else {
            key = "AIzaSyD5-jXQ6ReM6YK7mmOKUnXmiPaNgXs4m14";
        }
        if (matches?.length >= 3) {
            return (
                <Row className="video_sec video-Section-row-box">
                    <Col className="video-prev-col">
                        <h5 className="video_heading">Preview</h5>
                        {
                            state.videoVersions && state.videoVersionsCount > 1 ?
                                <MaterialSelect
                                    value={videoVersionsState.currentVersion}
                                    onChange={e => { let temp = e.target.value; setVideoVersionsState(prev => ({ ...prev, currentVersion: temp })); }}
                                >
                                    {videoVersionsState.options}
                                </MaterialSelect> : null
                        }
                    </Col>
                    <div className="outer_video_row">
                        <Col className="video_col" s={12} m={12}>
                            <iframe style={{ width: "100%", maxHeight: "65vh", border: "1px solid black", borderRadius: "5px" }} title="Preview" ref={videoRef} id="myframe"
                                src={`https://drive.google.com/file/d/${matches[2]}/preview`} />
                            {
                                props.isOpen &&
                                <Row className="chatbox">
                                    <ChatBox cardId={props.cardId} me={props.me} poll={props.isOpen} card={state.card}
                                        videoCount={state.card.repurpose_video_count > 0 ? state.card.repurpose_video_count : state.card.done_video_count} showVideoChat={fetchChatForId} />
                                </Row>
                            }
                        </Col>
                    </div>
                </Row>
            );
        }
    }

    
    const onChangeAnnotation = (annotation) => {
        setAnnotationStates(prev=>({
            ...prev,
            annotation
        }));
    }
    const onSubmitAnnotation = (annotation) => {
        const { geometry, data } = annotation

        let annotationObj = JSON.stringify({
            geometry,
            data
        });

        const obj = {
            card_id: props.cardId, 
            note: annotationObj,
            note_type:"annotation"
        }

        axiosClient.post(`/api/ythelper/card/${props.cardId}/comment`,obj)
            .then(({data})=>{
                setAnnotationStates(prev=>({
                    ...prev,
                    callChatApi:true
                }));
                successToast("Annotation is added successfully");
            })
            .catch((err)=>{
                console.error(err);
                errorToast("Unable to post annotation, try again later");
            })
    }

    const onAnnotationMouseOver = (id) => e => {
        setAnnotationStates(prev => ({
            ...prev,
            activeAnnotations: [
                ...annotationStates.activeAnnotations,
                id
            ]
        }))
    }

    const onAnotationMouseOut = (id) => e => {
        const index = annotationStates.activeAnnotations.indexOf(id)

        setAnnotationStates(prev => ({
            ...prev,
            activeAnnotations: [
                ...annotationStates.activeAnnotations.slice(0, index),
                ...annotationStates.activeAnnotations.slice(index + 1)
            ]
        }))
    }

    const activeAnnotationComparator = (a, b) => {
        return a.id === b
    }

    const onChangeType = (e) => {
        setAnnotationStates(prev=>({
            ...prev,
            type:e.currentTarget.innerHTML
        }));
    }

    function showThumbnailImg()
    {
        const GD_LINK = /http(s)?:\/\/drive\.google\.com\/file\/d\/([^\\]+)\/.*/i;
        let thumbnail_link = state.card.thumbnails_link;
        if(GD_LINK.test(thumbnail_link)) {
            let matches = thumbnail_link?.match(GD_LINK);
            let key = "";
            if(props.me.client_type === ADMIN)
            {
                key = "AIzaSyBAvcPRGkcuWpt7d7bG0PVyj_AN67VUvBc";
            }
            else if (props.me.client_type === CUSTOMER)
            {
                key = "AIzaSyBdJXJ3nr3zC6wWJscp767e6ABzv5d0vlc";
            }
            else{
                key = "AIzaSyD5-jXQ6ReM6YK7mmOKUnXmiPaNgXs4m14";
            }
            if(matches.length >= 3) {
                return (
                    <Row className="video_sec video-Section-row-box">
                        <Divider/>
                        <br/>
                        <h5 className="video_heading">Thumbnail Preview</h5>
                        <div className="outer_video_row">
                        <Col className="video_col" s={12} m={12}>
                            <div>
                            <Button onClick={onChangeType}>
                                {RectangleSelector.TYPE}
                            </Button>
                            <Button onClick={onChangeType}>
                                {PointSelector.TYPE}
                            </Button>
                            <Button onClick={onChangeType}>
                                {OvalSelector.TYPE}
                            </Button>
                            <div className="switch-annotation" style={{ float: 'right' }} >
                                <Switch 
                                    offLabel ="Annotations"
                                    checked={annotationStates.showAnnotations}
                                    onChange = {()=>
                                        setAnnotationStates(prev=>({
                                            ...prev,
                                            showAnnotations:!annotationStates.showAnnotations
                                        }))
                                    }
                                />
                            </div>
                            <Annotation
                                style={{ width: "100%", maxHeight: "65vh", border: "1px solid black", borderRadius: "5px" }}
                                src = {`https://www.googleapis.com/drive/v3/files/${matches[2]}?alt=media&key=${key}`}
                                // src={"https://videoportal-videos.s3-accelerate.amazonaws.com/773/Thumbnail_Meme-Stocks.jpg"}
                                alt = "Thumbnail"

                                activeAnnotationComparator={activeAnnotationComparator}
                                activeAnnotations={annotationStates.showAnnotations ? annotationStates.activeAnnotations :[]}

                                annotations={annotationStates.showAnnotations ? annotationStates.annotations:[]}
                                type={annotationStates.type}
                                value={annotationStates.showAnnotations ? annotationStates.annotation :[]}
                                onChange={onChangeAnnotation}
                                onSubmit={onSubmitAnnotation}
                                disableAnnotation ={!annotationStates.showAnnotations}
                            />
                            </div>
                            {/* <a href = {state.card.thumbnails_link} target="_blank" >
                                {/* <img style={{ width: "100%", maxHeight: "65vh", border: "1px solid black", borderRadius: "5px" }} onError = {()=> {setShowThumbnail(false); setShowChat(true);}}
                                    src = {`https://drive.google.com/uc?export=view&id=${matches[2]}`} alt = "Thumbnail"/> 
                                <img style={{ width: "100%", maxHeight: "65vh", border: "1px solid black", borderRadius: "5px" }} onError = {()=> {setShowThumbnail(false); setShowChat(true);}}
                                    src = {`https://www.googleapis.com/drive/v3/files/${matches[2]}?alt=media&key=${key}`} alt = "Thumbnail"/>
                            </a> */}
                        {
                            props.isOpen &&
                            <Row className="chatbox">
                                <ChatBox cardId={props.cardId} me={props.me} poll={props.isOpen} section = {props?.section} card = {state.card} annotations={annotationStates.annotations} 
                                setAnnotationStates={setAnnotationStates} callChatApi={annotationStates.callChatApi}
                                onAnnotationMouseOver={onAnnotationMouseOver} onAnotationMouseOut={onAnotationMouseOut} />
                            </Row>
                        }
                        </Col>
                       </div>
                    </Row>
                );
            }
        }
    }


    const handleDoneVideoChange = ()=>{
        if (changeDoneVideoUrlState.isEditing && !changeDoneVideoUrlState.newVideoLink) {
            return;
        }
        if (changeRepurposeVideoUrlState.isEditing && !changeRepurposeVideoUrlState.newVideoLink) {
            return;
        }
        if (changeUnlistedVideoVideoUrlState.isEditing && !changeUnlistedVideoVideoUrlState.newVideoLink) {
            return;
        }
        if (changeVideoThumbnailLink.isEditing && !changeVideoThumbnailLink.newVideoLink) {
            return;
        }
        if (changeSnippetVideoLink.isEditing && !changeSnippetVideoLink.newVideoLink) {
            return;
        }
        let obj;
        if (changeRepurposeVideoUrlState.isEditing) {
            obj = {
                card_id:props.cardId, 
                done_video_link: changeRepurposeVideoUrlState.newVideoLink,
                video_id: changeRepurposeVideoUrlState.oldVideoId,
                type: 'repurpose',
            };
        } else if (changeDoneVideoUrlState.isEditing) {
            obj = {
                card_id:props.cardId, 
                done_video_link: changeDoneVideoUrlState.newVideoLink,
                video_id: changeDoneVideoUrlState.oldVideoId,
                type: 'done',
            };
        }  else if (changeUnlistedVideoVideoUrlState.isEditing) {
            obj = {
                card_id:props.cardId,
                unlisted_video_link: changeUnlistedVideoVideoUrlState.newVideoLink,
                type: YT_HELPER_IN_DONE,
            };
        }  else if (changeVideoThumbnailLink.isEditing) {
            obj = {
                card_id:props.cardId,
                thumbnails_link: changeVideoThumbnailLink.newVideoLink,
                type: YT_HELPER_IN_DONE,
            };
        } else if (changeSnippetVideoLink.isEditing) {
            obj = {
                card_id:props.cardId,
                yt_snippet_link: changeSnippetVideoLink.newVideoLink,
                type: YT_HELPER_IN_DONE,
            };
        }
        if (changeDoneVideoUrlState.oldUrl === state.card.done_video_link || changeRepurposeVideoUrlState.oldUrl === state.card.repurpose_done_link) {
            obj = {...obj,updateBothTable:true};
        } else {
            obj = {...obj,updateBothTable:false};
        }

        axiosClient.post("/api/card/changeDoneVideo",obj)
            .then(({data})=>{
                successToast(data?.message);
                setChangeDoneVideoUrlState({ isEditing: false });
                props.onClose();
            })
            .catch((err)=>{
                errorToast(err?.response?.data?.message || "Something went wrong, please try again");
                //console.log("err : ",err);
            })
    }

    const handleDoneContentIdChange = ()=>{
        let obj = {
            card_id: props.cardId,
            contentId: changeContentIdState.oldContentId
        };
        axiosClient.post("/api/card/changeContentId",obj)
            .then(({data})=>{
                successToast(data?.message);
                setChangeContentIdState({ isContentIdEditing: false });
                props.onClose();
            })
            .catch((err)=>{
                errorToast(err?.response?.data?.message || "Something went wrong, please try again");
            })
    }

    const handleCardNameChange = ()=>{
        axiosClient.put("/api/card/" + props.cardId,{card_id:props.cardId, card_title : newCardName,flag:1})
            .then(({data})=>{
                successToast(data?.message);
                setChangeCardName(false);
                // props.onClose();
                props.refresh();
            })
            .catch((err)=>{
                errorToast(err?.response?.data?.message || "Something went wrong, please try again");
                //console.log("err : ",err);
            })
    }
    const handleChangeDisplayVideo = (data, videoType)=>{
        setShowVideoLink(data);
        setSelectedVideoType(videoType);
    }
    const handleMapVideoOption = () => {
        let tempFields = [];
        let videos = state.videoVersions[videoVersionsState.currentVersion];
        videos && videos.length > 1 && videos.forEach((data,i) => {
            tempFields.push(
                <div onClick={() => handleChangeDisplayVideo(data.video_id)} className={`tab-vid-inn ${showVideoLink === data.video_id ? 'active' : ''}`} key={"video-radio-"+data.video_id}>
                    Video {i+1}
                </div>
            );
        });
        return tempFields;
    }

    const handleMapDoneVideos = () => {
        let tempFields = [];
        let videos = state.card.video_versions[`version-${ Object.keys(state.card.video_versions).length - 1 }`];
        videos && videos.forEach((data, i) => {
            tempFields.push(
                <Row className="middle_row" key={'done-video-link-row-key-'+ i}>
                    <Col s={12} m={4}>
                        <BsLink style={{ float: 'left',  marginRight: '5px' }} size='30px' />
                        <strong className="blue-text">
                            Done Video Link { videos.length > 1 ? i + 1 :null} :
                            </strong>
                    </Col>
                    <Col s={12} m={7} className="listing_button">
                        <div style={{ display: "flex", flexDirection: 'column',width: '100%' }}>
                            <div style={{ display: 'flex' }} key={'done-video-link-' + i}>
                                <div className="show-done-video-link" style={{ paddingLeft: '0' }}>
                                    <VideoPlayTab
                                        dataTip={"Click to view video"}
                                        videoUrl={data.video_url}
                                        cardName={state.card.card_title} />
                                </div>
                                {
                                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                    <a className="download-btn-vertical-align" href={data?.video_url} download>
                                        <Button icon={<Icon>download</Icon>} className="edit-done-video" tooltip="Download Video" />
                                    </a>
                                }
                            </div>
                        </div>
                    </Col>
                    <Col s={12} m={1} className="edit-btn-col">
                        {
                            props.me.client_type !== CUSTOMER ?
                                <div className="btn-side edit-video-btn-div">
                                    {
                                        <Button icon={<Icon>edit</Icon>} className="edit-done-video" 
                                            style={{ backgroundColor: subDomain?.primary_color }}
                                            tooltip="Change Done Video Link" onClick={() => {
                                                setChangeDoneVideoUrlState({
                                                    isEditing: true,
                                                    oldUrl: data.video_url,
                                                    oldVideoIndex: i,
                                                    oldVideoId: data.video_id,
                                                    oldUrlType: data.audio_video_type,
                                                    newUrlType: data.audio_video_type,
                                                });
                                                setChangeRepurposeVideoUrlState({
                                                    isEditing: false
                                                });
                                                setChangeUnlistedVideoVideoUrlState({
                                                    isEditing: false
                                                });
                                                setChangeVideoThumbnailLink({
                                                    isEditing: false
                                                });
                                                setChangeSnippetVideoLink({
                                                    isEditing: false
                                                });
                                                }} >
                                        </Button>
                                    }
                                </div> : null
                        }
                    </Col>
                </Row>
            );
        });
        return tempFields;
    }

    const mapMultipleFields = (dataArray,fieldName) => {
        let tempFields = [];
        let dataLength = dataArray.length;
        dataLength > 0 && dataArray.forEach((data, i) => {
            tempFields.push(
                <Row className="bottom_row" key={fieldName.trim().replace(" ",'-') + '-row-key-'+ i}>
                    <div className="inside_linkg">
                        <Col s={12} m={4}>
                        <BsLink style = {{ float : 'left' ,marginTop : '-5px',marginRight:'5px' }} size = '30px' /><strong className="blue-text">{fieldName} {data?.video_type ? data?.video_type : null}:</strong>
                        </Col>
                        <Col s={12} m={7}>
                        <div style={{ display: "flex", width: '100%' }}>
                            <div style={{paddingLeft: '0'}}>
                                <VideoPlayTab
                                    dataTip={"Click to view video"}
                                    videoUrl={data.video_url}
                                    cardName={state.card.card_title} />
                            </div>
                                {
                                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                    <a className="download-btn-vertical-align" href={data?.video_url} download>
                                        <Button icon={<Icon>download</Icon>} className="edit-done-video" tooltip="Download Video" />
                                    </a>
                                }
                        </div>
                        </Col>
                        <Col s={12} m={1} className="edit-btn-col">
                            {
                                props.me.client_type === ADMIN || props.me.client_type === SUB_ADMIN || props.me.client_type === TEAM_LEAD ?
                                    <div className="btn-side edit-video-btn-div">
                                        {
                                            <Button icon={<Icon>edit</Icon>} className="edit-done-video"
                                                style={{ backgroundColor: subDomain?.primary_color }}
                                                tooltip="Change Repurpose Video Link" onClick={() => {
                                                    setChangeRepurposeVideoUrlState({
                                                        isEditing: true,
                                                        oldUrl: data.video_url,
                                                        oldVideoIndex: i,
                                                        oldVideoId: data.video_id,
                                                        oldUrlType: data.audio_video_type,
                                                        newUrlType: data.audio_video_type,
                                                    });
                                                    setChangeDoneVideoUrlState({
                                                        isEditing: false
                                                    });
                                                    setChangeUnlistedVideoVideoUrlState({
                                                        isEditing: false
                                                    });
                                                    setChangeVideoThumbnailLink({
                                                        isEditing: false
                                                    });
                                                    setChangeSnippetVideoLink({
                                                        isEditing: false
                                                    });
                                                    }} >
                                            </Button>
                                        }
                                    </div> : null
                            }
                        </Col>
                    </div>
                </Row>
            );
        });
        return tempFields;
    }

    const mapAllRevisionLink = (data, dataLength, currentVersion) => {
        let currentSelectedVersion = parseInt(currentVersion?.match(/\d+/), 10);
        let temp = [];
        let count = 0;
        for (const [key, value] of Object.entries(data)) {
          if (currentSelectedVersion >= count) {
            if (currentSelectedVersion > 0) {
              // eslint-disable-next-line array-callback-return
              value.map((revision, index) => {
                if (key === "version-0") {
                  temp.push(revision);
                } else {
                  // eslint-disable-next-line array-callback-return
                  temp.map((item, i) => {
                    if (item.video_type === revision.video_type) {
                      if (key === `version-${dataLength - 1}`) {
                        temp.splice(i, 1);
                      } else {
                        temp.splice(i, 1);
                        temp.push(revision);
                      }
                    }
                  });
                }
                if (key === `version-${dataLength - 1}`) {
                  temp.push(revision);
                }
              });
            } else {
              temp.push(...value);
            }
          }
          count = count + 1;
        }
        return sortRevisionLinks(temp, repurposingVideosName)
    };

    const sortRevisionLinks = (unsortedArray, videoTypeNames) => {
        let sortedArray = [];
        // eslint-disable-next-line array-callback-return
        videoTypeNames.map((videoType) => {
        // eslint-disable-next-line array-callback-return
          unsortedArray.find((item) => {
            if (item.video_type === videoType) {
              sortedArray.push(item);
            }
          });
        });
        return sortedArray;
    };

    const getBaseVersionVideoId = (index) => {
        let videoVersions = state.videoVersions;
        for (const version in videoVersions) {
            if (videoVersions.hasOwnProperty(version)) {
                const element = videoVersions[version];
                if (element[index] && element[index].video_id) {
                    return element[index].video_id;
                }
            }
        }
        return 0;
    }

    const handleRevisionButtonToggle = (isEnabled) => {
        setRevisionButtonEnabled(isEnabled);
      };

    const setCurrentVideoLink = (videoUrl) => {
        let parseVideoUrl = parseVideoLink(videoUrl);
        if (parseVideoUrl) {
            setCurrentVideoUrl(parseVideoUrl);
        } else if (GD_LINK.test(videoUrl)) {
            let matches = videoUrl?.match(GD_LINK);
            if (matches?.length >= 3) {
                setIsGDVideo(true);
                setCurrentVideoUrl(videoUrl);
            }
        } else {
            setShowLargeChat(true);
        }
        return true;
    }

    const onChangeFile = (e) => {
        let value = e.target.files[0];

        const params = {
            ACL: 'public-read',
            Body: value,
            Bucket: S3_BUCKET,
            Key: 'prod/media/srt/'+value.name
        };
        myBucket.upload(params,
            (err, data) => {
            if (err) {
                return err;
            } else {
                let temp = data?.Key ? "https://videoportal-files.s3.amazonaws.com/"+ data.Key : null;
                setSelectedFile(temp);
            }
        })
    }

    return (
        <Modal
            actions={[
                <Button flat modal="close" node="button" waves="red"><Icon right>close</Icon>Close</Button>
            ]}
            header={makeCardHeader()}
            id="viewCardModal"
            open={props.isOpen}
            options={{
                dismissible: true,
                endingTop: '3%',
                opacity: 0.5,
                preventScrolling: true,
                startingTop: '4%',
                onCloseEnd: () => { props.onClose(); setState(INITIAL_STATE); setRevisionButtonEnabled(true)}
            }}
            style={{
                wordWrap: "break-word",
            }}
        >
            { loading && <ProgressBar/> }
            <Row className="right-align multibutton">
                {!state.card.is_complete && revisionButtonEnabled && <ReviewButton card={state.card} me={props.me} section={props?.section} />}
                {state.card.card_status === DONE && state.card.repurpose_status === null && <CompleteRatingButton card={state.card} me={props.me} section = {props?.section} buttonText = {state.card.card_status === DONE && state.card.repurpose_status === null && "Mark As Done"} onToggleRevisionButton={handleRevisionButtonToggle} />}
                {state.card.repurpose_status === DONE && <CompleteRatingButton card={state.card} me={props.me} section = {props?.section} buttonText = {state.card.repurpose_status === DONE && "Mark As Done"} />}
                <ArchiveButton card={state.card} me={props.me} section = {props?.section} />
                <PlayPauseButton card={state.card} me={props.me} refresh={loadCard} section = {props?.section} />
                <RepurposeButton card = {state.card} me= {props.me} refresh={props.refresh} closeModal = {props.onClose} section = {props?.section} />
                {
                    showVideoDetail && (props.me.client_type === CUSTOMER || props.me.client_type === ADMIN || props.me.client_type === SUB_ADMIN) 
                    && ( currentVideoUrl && (currentVideoUrl.search("vidchops-videos.s3-accelerate.amazonaws.com") !== -1 || currentVideoUrl.search("videoportal-videos.s3-accelerate.amazonaws.com") !== -1 || currentVideoUrl.search("digitaloceanspaces.com/") !== -1 ) ) ? 
                        <Button className = 'btn-primary' icon = {<Icon left>download</Icon>} onClick={()=>window.open(currentVideoUrl)}>Download</Button>:null
                }
            </Row>
            {
                props.me.client_type === CUSTOMER && props.me.has_youtube_helper 
                    && !state.card.is_complete && state.card.card_status === DONE ?
                    <Col className = "card-note-col">
                        <strong className = "card-note">Note : </strong>
                        <span className = "card-note-message">Once you are Satisfied kindly click on the "READY FOR UPLOAD" button and rate your Editor. If you have some changes kindly click on the "REVISION" button for the card to go back to EDITING.</span>
                    </Col>:null
            }
            <div className = "ModalDiv1">
            <Row>
                <Modal
                    header={"CLEAR YOUR YOUTUBE CONTENT WITH SYNC ID"}
                    id="music-guide"
                    open={showMusicGuide}
                    options={{
                        dismissible: true,
                        startingTop: '4%',
                        endingTop: '10%',
                        opacity: 0.5,
                        preventScrolling: true,
                        onCloseEnd: ()=>setShowMusicGuide(false),
                    }}
                >
                    <p>Add this SyncID to your YouTube video description to validate your license and avoid copyright claims.</p>
                    <p><span style={{fontWeight:'bold'}}>Important:</span> Make sure to set the video Visibility to "Unlisted" or "Public" and make sure to put the Sync ID in the Description before uploading.</p>
                    <p>What is SyncID™?</p>
                    <p>SyncID is a Content ID clearance system created by our third-party license provider, Musicbed for YouTube and offered as an exclusive benefit to subscribers. SyncID provides a smooth, streamlined process to clear videos of claims and allows you to monetize your videos.</p>
                    <p>What happens if I receive a claim on my video and I have a Musicbed subscription?</p>
                    <p>Through our third party's SyncID system, any claims placed on your video for music in subscription should clear automatically after the video is uploaded as unlisted or public. If your video still gets claimed for copyright, reach out to us so we can manually clear it via Music Bed.</p>
                    <div style={{display:"flex", justifyContent:"center"}}>
                    <iframe
                        width="420"
                        height="345"
                        src="https://www.youtube.com/embed/VYF6ZNcaG4U"
                        title="Musicbed Personal Subscription: 101- Clearing Copyright Claims with SyncID™"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen>
                    </iframe>
                    </div>
                </Modal>
                <div className= "inside-row">
                    <Col s={12} m={3}>
                        <MdPerson size = '25px' style = {{ float : 'left' ,marginTop : '-2px',marginRight:'5px' }} /><strong className="blue-text">Client:</strong><span> {state.card.client_name}</span>
                    </Col>
                    <Col s={12} m={3}>
                        <MdAdd size = '25px' style = {{ float : 'left' ,marginTop : '-2px',marginRight:'5px' }} /><strong className="blue-text">Created:</strong><span> {state.card.creation_time}</span>
                    </Col>
                    {
                        state.card?.yt_helper_id && props?.section !== YT_HELPER_SECTION && !state.card.is_complete 
                            && state.card.card_status !== CANCELED && state.card.card_status !== ON_HOLD &&
                            <Col s={12} m={3}>
                                <MdShortText size = '25px' style = {{ float : 'left' ,marginTop : '-2px' ,marginRight:'5px'}}/>
                                <strong className="blue-text">YouTube Helper Status: </strong><span> 
                                    {state.card.ytHelper_card_status ? (state.card.ytHelper_card_status ===YT_HELPER_IN_PROGRESS ? "Progress" : "Done") :"ASSIGNED CARD"}</span>
                            </Col>
                    }
                    {
                        props?.section === YT_HELPER_SECTION && 
                        <Col s={12} m={3}>
                            <MdShortText size = '25px' style = {{ float : 'left' ,marginTop : '-2px' ,marginRight:'5px'}}/>
                            <strong className="blue-text">Editor Status: </strong><span> 
                            {state.card.card_status === DONE && state.card.yt_helper_status && state.card.role === YT_HELPER && state.card.is_complete ? "Mark As Done" :
                                state.card.is_complete ? "Mark as done completed" : getCardDisplayStatus(state.card.card_status) }
                            </span>
                        </Col>
                    }
                </div>
                {
                    (props?.section === YT_HELPER_SECTION ? state.card.yt_card_revision || state.card?.yt_timeline  : state.card.revision_nr || state.card.timeline ) ?
                        <Row>
                            <div className= "inside-row">
                            {
                                (props?.section === YT_HELPER_SECTION ? state.card?.yt_timeline  : state.card.timeline ) ?
                                    <Col s={12} m={3}>
                                        <MdTimeline size = '20px' style = {{ float : 'left' ,marginTop : '-2px' ,marginRight:'5px'}}/><strong className="blue-text">Timeline:</strong><span> 
                                            {props?.section === YT_HELPER_SECTION ? state.card?.yt_timeline  : state.card.timeline} hrs</span>
                                    </Col> : ""
                            }
                            {
                                (props?.section === YT_HELPER_SECTION ? state.card.yt_card_revision  : state.card.revision_nr ) ?
                                    <Col className="last-rev" s={12} m={3}>
                                        <MdRedo size = '25px' style = {{ float : 'left' ,marginTop : '-2px' ,marginRight:'5px'}} /><strong className="blue-text">Revisions:</strong><span> 
                                            {props?.section === YT_HELPER_SECTION ? state.card.yt_card_revision:state.card.revision_nr}</span>
                                    </Col> : ""
                            }
                            </div>
                        </Row>
                        : ""
                }
            
            </Row>
            </div>
            <Divider/>
            {
                makeQaAndDueDateRow()
            }
            {
                state.card.comment && !cardInProgress(state.card) &&
                    <Row>
                        <Col className = "padding-left" s={12} m={4}>
                        <MdComment style = {{ float : 'left' ,marginTop : '0px' ,marginRight:'8px'}} size = '20px' /><strong className="blue-text">Feedback: </strong>
                        </Col>
                        <Col s={12} m={8}>
                            <div style={{paddingLeft: '0'}}>{state.card.comment}</div>
                        </Col>
                    </Row>
            }
            <Divider/>
            <Row className="linking_area" style={{marginTop: "10px"}}>
                <div className="inside_linkg">
                    <Col className = "padding-left" s={12} m={4}>
                        <BsLink style = {{ float : 'left' ,marginTop : '0px',marginRight:'5px' }} size = "30px" /><strong className="blue-text">Raw Video Link:</strong>
                    </Col>
                    <Col s={12} m={8}>
                        <div style={{paddingLeft: '0'}}><a target="_blank" rel="noopener noreferrer" href={state.card.dropbox_link}>{state.card.dropbox_link}</a></div>
                    </Col>
                </div>

            {
                state.card.done_video_link  && showVideoDetail ? 
                    handleMapDoneVideos() : null
            }
            {
                props.section === "ytHelperSection" && state.card.yt_card_status === "inDone" ?
                <Row className="bottom_row">
                <div className="inside_linkg">
                    <Col s={12} m={4}>
                    <BsLink style = {{ float : 'left' , marginRight:'5px' }} size = '30px' /><strong className="blue-text">{'Unlisted video'}:</strong>
                    </Col>
                    <Col s={12} m={8}>
                        <div style={{ display: "flex", justifyContent: 'space-between', width: '100%' }}>
                            <div style={{paddingLeft: '0'}}><a target="_blank" rel="noopener noreferrer" href={state.card.unlisted_video_link}>{state.card.unlisted_video_link ? state.card.unlisted_video_link : 'N/A'}</a></div>
                            {
                                props.me.client_type === ADMIN || props.me.client_type === YT_HELPER || props.me.client_type === YT_HELPER_LEAD || props.me.client_type === TEAM_LEAD ?
                                    <div className="btn-side edit-video-btn-div">
                                        {
                                            <Button icon={<Icon>edit</Icon>} className="edit-done-video" tooltip="Change Unlisted Video Link" onClick={() => {
                                                setChangeUnlistedVideoVideoUrlState({
                                                    isEditing: true,
                                                    oldUrl: state.card.unlisted_video_link,
                                                });
                                                setChangeDoneVideoUrlState({
                                                    isEditing: false
                                                });
                                                setChangeRepurposeVideoUrlState({
                                                    isEditing: false
                                                });
                                                }} >
                                            </Button>
                                        }
                                    </div> : null
                            }
                        </div>
                    </Col>
                </div>
            </Row>
            : null
            }
            {
                showVideoDetail && changeUnlistedVideoVideoUrlState.isEditing ?
                    <Row className="middle_row">
                        <div style={{ marginTop: '25px', display:'flex' }}>
                            <TextInput className="input-new-done-video" label="New Video Link" s={12} m={7}
                                value={changeUnlistedVideoVideoUrlState.newVideoLink === '' ? '' : changeUnlistedVideoVideoUrlState.newVideoLink || changeUnlistedVideoVideoUrlState.oldUrl} validate
                                onChange={(e) => {
                                    let value = e.target.value; setChangeUnlistedVideoVideoUrlState((prev) => ({ ...prev, newVideoLink: value }))
                                }} />

                            <Button icon={<Icon>close</Icon>} style={{ margin:'20px 0 0 0' }} className="cancel-btn-change-unlisted-video" key="cancel-change-unlisted-video" tooltip="Cancel" onClick={() =>
                                setChangeUnlistedVideoVideoUrlState({ isEditing: false })} />
                            <Button icon={<Icon>save</Icon>} style={{ margin:'20px 0 0 0' }} className="save-btn-change-unlisted-video" key="save-change-unlisted-video" tooltip="Save Unlisted Video Link" onClick={e =>
                                handleDoneVideoChange()} />
                        </div>
                    </Row>
                : null
            }
            {
                showVideoDetail && changeDoneVideoUrlState.isEditing ?
                    <Row className="middle_row">
                        <div style={{ marginTop: '25px', display:'flex' }}>
                            <TextInput className="input-new-done-video" label="New Video Link" s={12} m={7}
                                value={changeDoneVideoUrlState.newVideoLink || changeDoneVideoUrlState.oldUrl} validate
                                onChange={(e) => {
                                    let value = e.target.value; setChangeDoneVideoUrlState((prev) => ({ ...prev, newVideoLink: value }))
                                }} />

                            <Button icon={<Icon>close</Icon>} style={{ margin:'20px 0 0 0' }} className="cancel-btn-change-done-video" key="cancel-change-done-video" tooltip="Cancel" onClick={() =>
                                setChangeDoneVideoUrlState({ isEditing: false })} />
                            <Button icon={<Icon>save</Icon>} style={{ margin:'20px 0 0 0' }} className="save-btn-change-done-video" key="save-change-done-video" tooltip="Save Done Video Link" onClick={e =>
                                handleDoneVideoChange()} />
                        </div>
                    </Row>
                : null
            }
            {
                state.card.content_id && state.card.content_id !== '' ?
                <Row className="middle_row">
                    <Col s={12} m={4}>
                    <MdShortText style = {{ float : 'left' ,marginTop : '-2px',marginRight:'5px' }} size = '25px' /><strong className="blue-text">Sync ID:</strong>
                    <Button small className="music-licensing-guide-btn" onClick={()=>setShowMusicGuide(true)}
                    icon={<BsFillInfoCircleFill style = {{ float : 'left' ,marginTop : '2px' ,marginRight:'5px'}} size = '25px' />} tooltip="Learn more" />
                    </Col>
                    <Col s={12} m={8} className="content-id listing_button">
                        <div style={{paddingLeft: '0'}} >
                            {state.card.content_id.split(",").map((i,key) => <div key={key}>{i}</div>)}
                        </div>
                        {/* {state.card.content_id} */}
                        {
                            props.me.client_type !== CUSTOMER ?
                                <div className="btn-side">
                                    {
                                        <Button icon={<Icon>edit</Icon>} className="edit-done-video" tooltip="Edit content" onClick={() =>
                                            setChangeContentIdState({ isContentIdEditing: true, oldContentId: state.card.content_id})} >
                                        </Button>
                                    }
                                </div> : null
                        }
                    </Col>
                </Row> : null
            }
            {
                state.card.card_status === "done" && changeContentIdState.isContentIdEditing ?
                    <Row className="middle_row">
                        <div style={{ marginTop: '25px', display:'flex' }}>
                            <TextInput className="input-new-done-video" label="Sync Id for music licensing" s={12} m={7}
                                value={changeContentIdState.oldContentId} validate
                                onChange={(e) => {
                                    let value = e.target.value; setChangeContentIdState((prev) => ({ ...prev, oldContentId: value }))
                                }} />

                            <Button icon={<Icon>close</Icon>} style={{ margin:'20px 0 0 0' }} className="cancel-btn-change-done-video" key="cancel-change-done-video" tooltip="Cancel" onClick={() =>
                                setChangeContentIdState({ isContentIdEditing: false })} />
                            <Button icon={<Icon>save</Icon>} style={{ margin:'20px 0 0 0' }} className="save-btn-change-done-video" tooltip="Save Sync Id" onClick={e => handleDoneContentIdChange()} />
                        </div>
                    </Row>
                : null
            }
            {
                state.card.repurpose_video_versions && Object.keys(state.card.repurpose_video_versions).length > 0 && state.card.repurpose_status !== "repurposeQA" ?
                    mapMultipleFields((mapAllRevisionLink(state.card.repurpose_video_versions, Object.keys(state.card.repurpose_video_versions).length, videoVersionsState.currentVersion)?.map(d => {
                       return {
                        video_url: d.video_url,
                        video_type: d.video_type,
                        audio_video_type: d.audio_video_type,
                        video_id: d.video_id,
                       }
                    })) ?? [], "Repurposed Video Link")
                    : null
            }
            {
                state.card.repurpose_qa_video_versions && Object.keys(state.card.repurpose_qa_video_versions).length > 0 && (state.card.repurpose_status === "repurposeQA" || state.card.latest_revision_from?.video_type === "repurposeQA") ?
                    mapMultipleFields((mapAllRevisionLink(state.card.repurpose_qa_video_versions, Object.keys(state.card.repurpose_qa_video_versions).length, videoVersionsState.currentVersion)?.map(d => {
                       return {
                        video_url: d.video_url,
                        video_type: d.video_type,
                        audio_video_type: d.audio_video_type,
                        video_id: d.video_id,
                       }
                    })) ?? [], "Repurposed Video Link")
                    : null
            }
            {
                showVideoDetail && changeRepurposeVideoUrlState.isEditing ?
                    <Row className="middle_row">
                        <div style={{ marginTop: '25px', display:'flex' }}>
                            <TextInput className="input-new-done-video" label="New Video Link" s={12} m={7}
                                value={changeRepurposeVideoUrlState.newVideoLink || changeRepurposeVideoUrlState.oldUrl} validate
                                onChange={(e) => {
                                    let value = e.target.value; setChangeRepurposeVideoUrlState((prev) => ({ ...prev, newVideoLink: value }))
                                }} />

                            <Button icon={<Icon>close</Icon>} style={{ margin:'20px 0 0 0' }} className="cancel-btn-change-repurpose-video" key="cancel-change-repurpose-video" tooltip="Cancel" onClick={() =>
                                setChangeRepurposeVideoUrlState({ isEditing: false })} />
                            <Button icon={<Icon>save</Icon>} style={{ margin:'20px 0 0 0' }} className="save-btn-change-repurpose-video" key="save-change-repurpose-video" tooltip="Save Repurpose Video Link" onClick={e =>
                                handleDoneVideoChange()} />
                        </div>
                    </Row>
                : null
            }
            {
                state.card && state.card.repurpose_done_videos && state.card.repurpose_done_videos.length === 0 && state.card.repurpose_done_link && state.card.repurpose_qa_video_versions.length === 0 ?
                    <Row className="bottom_row">
                        <Col s={12} m={4}>
                        <BsLink style = {{ float : 'left' ,marginTop : '-5px',marginRight:'5px' }} size = '30px' /><strong className="blue-text">Repurposed Video Link:</strong>
                        </Col>
                        <Col s={12} m={8}>
                            <div style={{paddingLeft: '0'}}>
                                <VideoPlayTab
                                    dataTip={"Click to view video"}
                                    videoUrl={state.card.repurpose_done_link}
                                    cardName={state.card.card_title} />
                            </div>
                        </Col>
                    </Row>:null
            }
            <Row className="bottom_row">

                <div className="inside_linkg">
                    <Col s={12} m={4}>
                    <MdAspectRatio style = {{ float : 'left' ,marginTop : '0px',marginRight:'8px' }} size = '20px' /><strong className="blue-text">Aspect Ratio:</strong>
                    </Col>
                    <Col s={12} m={8}>
                        <div style={{paddingLeft: '0'}}>{state.card.youtube_aspect_ratio}</div>
                    </Col>
                </div>
            </Row>
            <Row className="bottom_row">
                <div className="inside_linkg">
                    <Col s={12} m={4}>
                    <MdHelp style = {{ float : 'left' ,marginTop : '0px',marginRight:'8px' }} size = '25px' /><strong className="blue-text">What type of content is this for:</strong>
                    </Col>
                    <Col s={12} m={8}>
                        <div style={{paddingLeft: '0'}}>{state.card.video_purpose}</div>
                    </Col>
                </div>
            </Row>
            {
                state.card.additional_info && state.card.additional_info !== '' &&
                    <Row className="bottom_row">
                        <div className="inside_linkg">
                            <Col s={12} m={4}>
                                <MdShortText style = {{ float : 'left' ,marginTop : '-2px',marginRight:'5px' }} size = '25px' /><strong className="blue-text">Anything else about this video:</strong>
                                </Col>
                                <Col s={12} m={8}>
                                    <div className = "editor_text" >
                                    {/* <div style={{paddingLeft: '0'}}>{state.card.additional_info.split("\n")
                                        .map((i,key) => <div key={key}>{i}</div>)}</div> */}
                                        {parse(state.card.additional_info)}
                                    </div>
                                </Col>
                        </div>
                    </Row>
            }
            {
                state.card.special_instructions && state.card.special_instructions !== '' &&
                    <Row className="bottom_row">
                        <div className="inside_linkg">
                            <Col s={12} m={4}>
                                <MdShortText style = {{ float : 'left' ,marginTop : '-2px' ,marginRight:'5px'}} size = '25px' /><strong className="blue-text">Special Instructions:</strong>
                                </Col>
                                <Col s={12} m={8}>
                                    <div className = "editor_text" >
                                    {/* <div style={{paddingLeft: '0'}}>{state.card.special_instructions}</div> */}
                                    {parse(state.card.special_instructions)}
                                    </div>
                                </Col>
                        </div>
                    </Row>
            }
            {
                state.card.video_thumb_desc && 
                <Row className="bottom_row">
                    <div className="inside_linkg">
                        <Col s={12} m={4}>
                            <MdShortText style = {{ float : 'left' ,marginTop : '-2px' ,marginRight:'5px'}} size = '25px' /><strong className="blue-text">Instructions about thumbnail:</strong>
                            </Col>
                            <Col s={12} m={8}>
                                <div className = "editor_text" >
                                {/* <div style={{paddingLeft: '0'}}>{state.card.video_thumb_desc}</div> */}
                                {parse(state.card.video_thumb_desc)}
                                </div>
                            </Col>
                    </div>
                </Row>
            }
            {
                state.card.video_thumb_ref &&
                <Row className="bottom_row">
                    <div className="inside_linkg">
                        <Col s={12} m={4}>
                        <BsLink style = {{ float : 'left' ,marginTop : '-5px',marginRight:'5px' }} size = '30px' /><strong className="blue-text">Video Thumbnail Reference Links:</strong>
                        </Col>
                        <Col s={12} m={8}>
                            <div style={{paddingLeft: '0'}}><a target="_blank" rel="noopener noreferrer" href={state.card.video_thumb_ref}>{state.card.video_thumb_ref}</a></div>
                        </Col>
                    </div>
                   
                </Row>
            }
            {
                state.card.video_optimization_desc && 
                <Row className="bottom_row">

                    <div className="inside_linkg">
                        <Col s={12} m={4}>
                        <MdShortText style = {{ float : 'left' ,marginTop : '-2px' ,marginRight:'5px'}} size = '25px' /><strong className="blue-text">Instruction about video title and description:</strong>
                        </Col>
                        <Col s={12} m={8}>
                            <div className = "editor_text" >
                            {/* <div style={{paddingLeft: '0'}}>{state.card.video_optimization_desc}</div> */}
                            {parse(state.card.video_optimization_desc)}
                            </div>
                        </Col>
                    </div>
                </Row>
            }
            {
                state.card.affiliate_links &&
                <Row className="bottom_row">
                    <div className="inside_linkg">
                    <Col s={12} m={4}>
                    <BsLink style = {{ float : 'left' ,marginTop : '-5px',marginRight:'5px' }} size = '30px' /><strong className="blue-text">Affiliate or Product Links:</strong>
                    </Col>
                    <Col s={12} m={8}>
                        <div style={{paddingLeft: '0'}}><a target="_blank" rel="noopener noreferrer" href={state.card.affiliate_links}>{state.card.affiliate_links}</a></div>
                    </Col>
                    </div>
                </Row>
            }
            {
                state.card.subtitles ?
                <Row className="bottom_row">
                    <div className="inside_linkg">
                        <Col s={12} m={4}>
                        <MdShortText style = {{ float : 'left' ,marginTop : '-2px' ,marginRight:'5px'}} size = '25px' /><strong className="blue-text">Subtitles:</strong>
                        </Col>
                        <Col s={12} m={8}>
                            <div style={{paddingLeft: '0'}}>Yes</div>
                        </Col>
                        </div>
                </Row>:null
            }
            {
                state.card.voice_note &&
                    <Row className="bottom_row">
                        <div className="inside_linkg">
                        <Col s={12} m={4}>
                            <MdKeyboardVoice style = {{ float : 'left' ,marginTop : '-2px',marginRight:'5px' }} size = '25px' /><strong className="blue-text">Voice notes:</strong>
                        </Col>
                        <Col s={12} m={8}>
                            <audio src={state.card.voice_note} controls="controls" style={{paddingLeft: '0'}}/>
                        </Col>
                        </div>
                    </Row>
            }
            {
                state.card.repurpose_aspect ? React.Children.toArray([
                    <Row className="bottom_row">
                        <div className="inside_linkg">
                        <Col s={12} m={4}>
                        <MdAspectRatio style = {{ float : 'left' ,marginTop : '-2px' ,marginRight:'5px'}} size = '25px' /><strong className="blue-text">Repurpose Video Formats:</strong>
                        </Col>
                        <Col s={12} m={8}>
                            <div style={{paddingLeft: '0'}}>{state.card.repurpose_aspect}</div>
                        </Col>
                        </div>
                    </Row>,
                    <Row className="bottom_row">
                        <div className="inside_linkg">
                        <Col s={12} m={4}>
                        <MdShortText style = {{ float : 'left' ,marginTop : '-2px',marginRight:'5px' }} size = '25px' /><strong className="blue-text">Repurpose Video Headline:</strong>
                        </Col>
                        <Col s={12} m={8}>
                            <div style={{paddingLeft: '0'}}>{state.card.repurpose_headline || "N/A"}</div>
                        </Col>
                        </div>
                    </Row>,
                    <Row className="bottom_row">
                        <div className="inside_linkg">
                        <Col s={12} m={4}>
                        <MdShortText style = {{ float : 'left' ,marginTop : '-2px' ,marginRight:'5px'}} size = '25px' /><strong className="blue-text">Repurpose Video Additional Info:</strong>
                        </Col>
                        <Col s={12} m={8}>
                            <div style={{paddingLeft: '0'}}>{state.card.repurpose_additional_info || "N/A"}</div>
                        </Col>
                        </div>
                    </Row>,
                    ]) : null
            }
            <Row className="bottom_row">
                <div className="inside_linkg">
                    <Col s={12} m={4}>
                        <MdShortText style = {{ float : 'left' ,marginTop : '-2px' ,marginRight:'5px'}} size = '25px' /><strong className="blue-text">Does this video require an End Card:</strong>
                    </Col>
                    <Col s={12} m={8}>
                        <div style={{paddingLeft: '0'}}>{state.card.has_end_card ? "Yes" : "No"}</div>
                    </Col>
                </div>
            </Row>
            {
                state.card.repurpose_status !== null &&
                <Row className="bottom_row">
                    <div className="inside_linkg">
                    <Col s={12} m={4}>
                    <MdShortText style = {{ float : 'left' ,marginTop : '-2px' ,marginRight:'5px'}} size = '25px' /><strong className="blue-text">SRT Text File:</strong>
                    </Col>
                    <Col s={12} m={8}>
                        {state.card.srt_file && <div style={{paddingLeft: '0'}}><a className="downloadTextFile" href={state.card.srt_file}>Download</a></div>}
                        {!state.card.srt_file && <div style={{paddingLeft: '0'}}>N/A</div>}
                    </Col>
                    {
                        isAdmin(props.me) ||
                        isSubAdmin(props.me) ||
                        props.me.client_type === YT_HELPER ||
                        (isCustomer(props.me) && state.card.role === YT_HELPER) ?
                        <div className="btn-side edit-video-btn-div">
                        {
                            <Col s={12} m={1}>
                                <Button icon={<Icon>edit</Icon>} onClick={() => {
                                    setEditSrtFile({ isEditing: true });
                                    }} >
                                </Button>
                            </Col>
                        }
                        </div>
                    : null}
                    </div>
                </Row>
            }
            {
                editSrtFile.isEditing ? <>
                <Col s={12} m={4}>
                    <div style={{ display: "flex" }}>
                    <input
                        style={{ width: "100%" }}
                        className="custom-file-input"
                        s={12}
                        id="srt-input"
                        type="file"
                        name="srtFile"
                        onChange={(e) => onChangeFile(e)}
                        label="Select SRT File"
                    />
                    </div>
                 </Col>
                 <Col s={12} m={8}>
                    <Button className="srtButton" disabled={selectedFile ? false : true} onClick={() => {
                        setEditSrtFile({ isEditing: true });
                        axiosClient.post(`/api/card/${state.card.card_id}/srtFile`,
                        {
                            card_id: state.card.card_id,
                            srt_file: selectedFile
                        })
                        .then(({data}) => {
                            successToast(data.message || 'File Uploaded');
                            props.onClose();
                        }).catch(err => {
                            errorToast("Something went wrong, please try again");
                        });
                        }} >
                            Submit SRT
                    </Button>
                    </Col>
                    </>
                 : null
            }
            {
                props?.section === YT_HELPER_SECTION && state.card.thumbnails_link &&
                    <Row className="bottom_row">
                        <div className="inside_linkg">
                        <Col s={12} m={4}>
                            <BsLink style = {{ float : 'left', marginRight:'5px' }} size = '30px' /><strong className="blue-text">Video Thumbnail Link:</strong>
                        </Col>
                        <Col s={12} m={8}>
                        <div style={{ display: "flex", justifyContent: 'space-between', width: '100%' }}>
                        <div style={{paddingLeft: '0'}}><a target="_blank" rel="noopener noreferrer" href={state.card.thumbnails_link}>{state.card.thumbnails_link ? state.card.thumbnails_link : 'N/A'}</a></div>
                            {
                                props.me.client_type === ADMIN || props.me.client_type === YT_HELPER || props.me.client_type === YT_HELPER_LEAD || props.me.client_type === TEAM_LEAD ?
                                    <div className="btn-side edit-video-btn-div">
                                        {
                                            <Button icon={<Icon>edit</Icon>} className="edit-video-thumbnail-link" tooltip="Change Video Thumbnail Link" onClick={() => {
                                                setChangeVideoThumbnailLink({
                                                    isEditing: true,
                                                    oldUrl: state.card.thumbnails_link,
                                                });
                                                setChangeDoneVideoUrlState({
                                                    isEditing: false
                                                });
                                                setChangeRepurposeVideoUrlState({
                                                    isEditing: false
                                                });
                                                setChangeUnlistedVideoVideoUrlState({
                                                    isEditing: false
                                                });
                                                setChangeSnippetVideoLink({
                                                    isEditing: false
                                                });
                                                }} >
                                            </Button>
                                        }
                                    </div> : null
                            }
                        </div>
                    </Col>
                        </div>
                    </Row>
            }
            {
                changeVideoThumbnailLink.isEditing ?
                    <Row className="middle_row">
                        <div style={{ marginTop: '25px', display:'flex' }}>
                            <TextInput className="input-new-done-video" label="New Video Link" s={12} m={7}
                                value={changeVideoThumbnailLink.newVideoLink === '' ? '' : changeVideoThumbnailLink.newVideoLink || changeVideoThumbnailLink.oldUrl} validate
                                onChange={(e) => {
                                    let value = e.target.value; setChangeVideoThumbnailLink((prev) => ({ ...prev, newVideoLink: value }))
                                }} />

                            <Button icon={<Icon>close</Icon>} style={{ margin:'20px 0 0 0' }} className="cancel-btn-change-video-thumbnail-link" key="cancel-change-video-thumbnail-link" tooltip="Cancel" onClick={() =>
                                setChangeVideoThumbnailLink({ isEditing: false })} />
                            <Button icon={<Icon>save</Icon>} style={{ margin:'20px 0 0 0' }} className="save-btn-change-video-thumbnail-link" key="save-change-video-thumbnail-link" tooltip="Save Video Thumbnail Link" onClick={e =>
                                handleDoneVideoChange()} />
                        </div>
                    </Row>
                : null
            }
            {
                props?.section === YT_HELPER_SECTION && state.card.yt_card_description && 
                    <Row className="bottom_row">
                        <div className="inside_linkg">
                        <Col s={12} m={4}>
                        <MdShortText style = {{ float : 'left' ,marginTop : '-2px' ,marginRight:'5px'}} size = '25px' /><strong className="blue-text">Video Description:</strong>
                        </Col>
                        <Col s={12} m={8}>
                            <div className = "editor_text" >
                                {parse(state.card.yt_card_description)}
                            </div>
                        </Col>
                        </div>
                    </Row>
            }
            {
                props?.section === YT_HELPER_SECTION && state.card.yt_snippet_link &&
                    <Row className="bottom_row">
                        <div className="inside_linkg">
                            <Col s={12} m={4}>
                                <BsLink style = {{ float : 'left', marginRight:'5px' }} size = '30px' /><strong className="blue-text">Snippet Link:</strong>
                            </Col>
                            <Col s={12} m={8}>
                                <div style={{ display: "flex", justifyContent: 'space-between', width: '100%' }}>
                                <div style={{paddingLeft: '0'}}><a target="_blank" rel="noopener noreferrer" href={state.card.yt_snippet_link}>{state.card.yt_snippet_link ? state.card.yt_snippet_link : 'N/A'}</a></div>
                                    {
                                        props.me.client_type === ADMIN || props.me.client_type === YT_HELPER || props.me.client_type === YT_HELPER_LEAD || props.me.client_type === TEAM_LEAD ?
                                            <div className="btn-side edit-video-btn-div">
                                                {
                                                    <Button icon={<Icon>edit</Icon>} className="edit-snippet-link" tooltip="Change Snippet Link" onClick={() => {
                                                        setChangeSnippetVideoLink({
                                                            isEditing: true,
                                                            oldUrl: state.card.yt_snippet_link,
                                                        });
                                                        setChangeDoneVideoUrlState({
                                                            isEditing: false
                                                        });
                                                        setChangeRepurposeVideoUrlState({
                                                            isEditing: false
                                                        });
                                                        setChangeUnlistedVideoVideoUrlState({
                                                            isEditing: false
                                                        });
                                                        setChangeVideoThumbnailLink({
                                                            isEditing: false
                                                        });
                                                        }} >
                                                    </Button>
                                                }
                                            </div> : null
                                    }
                                </div>
                            </Col>
                        </div>
                    </Row>
            }
            {
                changeSnippetVideoLink.isEditing ?
                    <Row className="middle_row">
                        <div style={{ marginTop: '25px', display:'flex' }}>
                            <TextInput className="input-new-done-video" label="New Video Link" s={12} m={7}
                                value={changeSnippetVideoLink.newVideoLink === '' ? '' : changeSnippetVideoLink.newVideoLink || changeSnippetVideoLink.oldUrl} validate
                                onChange={(e) => {
                                    let value = e.target.value; setChangeSnippetVideoLink((prev) => ({ ...prev, newVideoLink: value }))
                                }} />

                            <Button icon={<Icon>close</Icon>} style={{ margin:'20px 0 0 0' }} className="cancel-btn-change-snippet-link" key="cancel-change-snippet-link" tooltip="Cancel" onClick={() =>
                                setChangeSnippetVideoLink({ isEditing: false })} />
                            <Button icon={<Icon>save</Icon>} style={{ margin:'20px 0 0 0' }} className="save-btn-change-snippet-link" key="save-change-snippet-link" tooltip="Save Snippet Link" onClick={e =>
                                handleDoneVideoChange()} />
                        </div>
                    </Row>
                : null
            }
            {
                props?.section === YT_HELPER_SECTION && state.card.thumbnails_link && showThumbnail && showThumbnailImg()
            }
            </Row>
            {
                props.isOpen && props?.section !== YT_HELPER_SECTION && showVideoDetail && state.videoVersions[videoVersionsState.currentVersion] && (state.videoVersions[videoVersionsState.currentVersion]).length > 1 ? 
                    <Row className="tab-vid">
                        <Col s={12} m={12} className="radio-cover">
                            {
                                handleMapVideoOption()
                            }
                        </Col>
                    </Row> : <Divider />
            }
            {
                props.isOpen && props?.section !== YT_HELPER_SECTION && showVideoDetail && !isGDVideo && !showLargeChat && currentVideoUrl ? 
                        <Row className="video_Sec video-Section-row-box">
                            <Col className="video-prev-col">
                                <h5 className="video_heading">Preview</h5>
                                {
                                    state.videoVersions && state.videoVersionsCount > 1 ?
                                        <MaterialSelect
                                            value={videoVersionsState.currentVersion}
                                            onChange={e => { let temp = e.target.value; setVideoVersionsState(prev => ({ ...prev, currentVersion: temp })); }}
                                        >
                                            {videoVersionsState.options}
                                        </MaterialSelect> : null
                                }
                            </Col>
                            <div className="outer_video_row">
                            {
                                <Col className="left_video" s={12} m={12}>
                                    <video id="preview-vid" preload="metadata" controls 
                                        style={{width: "100%", maxHeight: "65vh", border: "1px solid black", borderRadius: "5px"}}
                                        onLoadedMetadata={e => onVideoPreviewLoadedMetadata()}
                                        onError={(e) =>onVideoPreviewLoadedMetadataError()}>
                                        <source src={currentVideoUrl}/>Video Preview
                                    </video>
                                    {
                                        props.isOpen &&
                                        <Row className="chatbox">
                                            <ChatBox cardId={props.cardId} selectedVideoType={selectedVideoType} me={props.me} poll={props.isOpen} card = {state.card}
                                                videoCount={state.card.repurpose_video_count > 0 ? state.card.repurpose_video_count : state.card.done_video_count} showVideoChat={fetchChatForId}
                                            />
                                        </Row>
                                    }
                                </Col>
                            }
                            </div>
                        </Row>
                :null
            }

            {
                props.isOpen && isGDVideo && !showLargeChat ? embedGDLink() : null
            }
            {
                props.isOpen && state.card.card_id && showLargeChat ? 
                <Row className="chatbox">
                    {
                        props?.section === YT_HELPER_SECTION ? 
                            <ChatBox cardId={props.cardId} me={props.me} poll={props.isOpen}  section = {YT_HELPER_SECTION} card = {state.card} /> 
                            :
                                <div>
                                    <Col className="video-prev-col">
                                    {
                                        state.videoVersions && state.videoVersionsCount > 1 ?
                                            <MaterialSelect
                                                value={videoVersionsState.currentVersion}
                                                onChange={e => { let temp = e.target.value; setVideoVersionsState(prev => ({ ...prev, currentVersion: temp })); }}
                                            >
                                                {videoVersionsState.options}
                                            </MaterialSelect> : null
                                    }
                                    </Col>
                                    <ChatBox cardId={props.cardId} me={props.me} poll={props.isOpen} card={state.card} videoCount={state.card.repurpose_video_count > 0 ? state.card.repurpose_video_count : state.card.done_video_count} shouldFetchCmnt={true}
                                        showVideoChat={fetchChatForId} />
                                </div>
                            
                    }
                </Row>
                :''
            }
        </Modal>
    );
};
