import React from "react";
import { Select } from "antd";
import styles from './Dropdownstyle.module.scss';
const CustomDropdown = ({ options, placeholder, className, dropdownValue, handleOnchange, disabled }) => {
    return (
        <Select
            className={`${styles.customDropdownSize} ${className}`}
            showSearch
            allowClear
            optionFilterProp="children"
            options={options}
            placeholder={placeholder}
            onChange={handleOnchange}
            value={dropdownValue ? dropdownValue : undefined}
            filterOption={(input, option) =>
                (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase())}
            disabled={disabled}    
        />
    );
};

export default CustomDropdown;
