import React, { useState } from 'react';
import { Icon, Col, Checkbox, TextInput, Textarea } from "react-materialize";
import { Modal as AntModal } from "antd";
import { axiosClient } from '../../api/httpClient';
import { errorToast, successToast } from '../../api/Util';
const initialState = {
    ratios: 0,
    sq: false,
    sq916: false,
    sq45: false,
    other: false,
    aspect: "",
    headlineRepurpose: "",
    headlineRepurposeCheck: false,
    ctaRepurposeCheck: false,
    ctaTitle: "",
    repurposeAdditionalInfo: "",
};
const EnableRepurpose = ({ cardRepurposeModal, setCardRepurposeModal, currentCardId, setCurrentCardRepurpose, }) => {
	const [repurposeState, setRepurposeState] = useState(initialState);
	function onChangeRepurposeAspect(val, fn, aspect) {
		if (val) {
			fn(false);
			setRepurposeState(prevState => ({ ...prevState, ratios: prevState.ratios - 1 }));
		} else if (repurposeState.ratios < 2) {
			fn(true);
			setRepurposeState(prevState => ({ ...prevState, ratios: prevState.ratios + 1 }));
		}
	}

	function makeRepurposeRatioText() {
		let ratio = "";
		if (repurposeState.sq) {
			ratio += "1:1 Square Video,";
		}
		if (repurposeState.sq916) {
			ratio += "9:16 IGTV Vertical Video,";
		}
		if (repurposeState.sq45) {
			ratio += "4:5 In Feed Vertical Video,";
		}
		if (repurposeState.other) {
			ratio += repurposeState.aspect;
		}
		return ratio;
	}

	const updateCardRepurpose = () => {
		setCardRepurposeModal(false);
		let repurposeRatio = makeRepurposeRatioText();
		let obj = {
			repurpose_aspect: repurposeRatio,
			repurpose_headline: repurposeState.headlineRepurposeCheck ? repurposeState.headlineRepurpose : null,
			call_to_action: repurposeState.ctaRepurposeCheck ? repurposeState.ctaTitle : null,
			repurpose_additional_info: repurposeState.repurposeAdditionalInfo,
		};

		axiosClient.post(`/api/card/${currentCardId}/enableCardRepurpose`, obj)
			.then(({ data }) => {
				setRepurposeState(initialState); 
				successToast("Card Repurpose Enabled successfully!!")
				setCurrentCardRepurpose(false);
			})
			.catch((err) => {
				setRepurposeState(initialState); 
				errorToast("Something went wrong, please try again");
			})
	}

	return (
		<AntModal
			title="Enable Repurpose"
			id="cardRepurposeModal"
			open={cardRepurposeModal}
			onOk={updateCardRepurpose}
			onCancel={() => { setCardRepurposeModal(false); setRepurposeState(initialState); }}
			okText="Save"
		>
			<Col s={12} className="label-column">
				<label>Which size(s) do you want your video in? (Max 2) *</label>
			</Col>
			<Col s={12} className="label-column">
				<Checkbox label="Square (1:1)" name="1-1" onChange={() => onChangeRepurposeAspect(repurposeState.sq, val => setRepurposeState(prevState => ({ ...prevState, sq: val })), 'Square (1:1)')}
					value="Square (1:1)" checked={repurposeState.sq} id="1-1" disabled={!repurposeState.sq && repurposeState.ratios >= 2} />
			</Col>
			<Col s={12} className="label-column">
				<Checkbox label="IGTV Vertical (9:16)" name="9-16" onChange={() => onChangeRepurposeAspect(repurposeState.sq916, val => setRepurposeState(prevState => ({ ...prevState, sq916: val })), 'IGTV Vertical (9:16)')}
					value="IGTV Vertical (9:16)" checked={repurposeState.sq916} id="9-16" disabled={!repurposeState.sq916 && repurposeState.ratios >= 2} />
			</Col>
			<Col s={12} className="label-column">
				<Checkbox label="In Feed Vertical (4:5)" name="4-5" onChange={() => onChangeRepurposeAspect(repurposeState.sq45, val => setRepurposeState(prevState => ({ ...prevState, sq45: val })), 'In Feed Vertical (4:5)')}
					value="In Feed Vertical (4:5)" checked={repurposeState.sq45} id="4-5" disabled={!repurposeState.sq45 && repurposeState.ratios >= 2} />
			</Col>
			<Col s={12} className="label-column">
				<Checkbox label="Other" name="other" onChange={() => onChangeRepurposeAspect(repurposeState.other, val => setRepurposeState(prevState => ({ ...prevState, other: val })), 'Enter Custom size')}
					value="Enter Custom size" checked={repurposeState.other} id="other" disabled={!repurposeState.other && repurposeState.ratios >= 2} />
			</Col>
			{repurposeState.other ?
				<TextInput s={12} placeholder="Custom video size" value={repurposeState.aspect} icon="aspect_ratio"
					onChange={e => {e.persist();setRepurposeState(prevState => ({ ...prevState, aspect: e?.target?.value }))}} /> : null}
			<br />
			<Col s={12} className="label-column">
				<Checkbox label="Do you want a headline on your video?"
					onChange={() => setRepurposeState(prevState => ({ ...prevState, headlineRepurposeCheck: !prevState.headlineRepurposeCheck }))}
					value="1" checked={repurposeState.headlineRepurposeCheck} id="headline" />
			</Col>
			{repurposeState.headlineRepurposeCheck ?
				<TextInput s={12} placeholder="Custom headline" value={repurposeState.headlineRepurpose} icon="short_text"
					onChange={e => {e.persist();setRepurposeState(prevState => ({ ...prevState, headlineRepurpose: e?.target?.value }))}} /> : null}
			<Col s={12} className="label-column">
				<Checkbox label="Do you want a Call To Action at the end of the repurposed video?"
					onChange={() => setRepurposeState(prevState => ({ ...prevState, ctaRepurposeCheck: !prevState.ctaRepurposeCheck }))}
					value="1" checked={repurposeState.ctaRepurposeCheck} id="callToAction" name="callToAction" />
			</Col>
			{repurposeState.ctaRepurposeCheck ?
				<>
					<Col s={12} className="label-column cta_label">
						<label>What should the CTA ask the viewer to do?</label>
					</Col>
					<Col s={12} className="label-column txt_cta">
						<TextInput icon={<Icon>short_text</Icon>} name="call_to_action" placeholder="Subscribe to my channel" value={repurposeState.ctaTitle} onChange={(e) => setRepurposeState(prevState => ({ ...prevState, ctaTitle: e.target.value }))} />
					</Col>
				</> : null}
			<br />
			<Col s={12} className="label-column">
				<label>Do you have any other information or instructions for us?</label>
			</Col>
			<Textarea value={repurposeState.repurposeAdditionalInfo} s={12} icon={<Icon>short_text</Icon>} name="repurposeAdditionalInfo"
                onChange={(e) => {e.persist();setRepurposeState(prevState => ({ ...prevState, repurposeAdditionalInfo: e?.target?.value }))}} />
		</AntModal>
	);
}

export default EnableRepurpose;
