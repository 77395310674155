import React from 'react';
import { Button, Icon, Modal } from "react-materialize";
import { DateRangePicker } from 'react-date-range';
import { toISODate } from "../../api/Util";

export default ({ startDate, endDate, addDateRangeModalOpen, setAddDateRangeModalOpen, onDateChange }) => {
    return (
        <Modal
            trigger={(
                <Button node="button" className="btn-primary" icon={<Icon left>date_range</Icon>}>
                    {toISODate(startDate ? startDate : new Date())} ~ {toISODate(endDate)}
                </Button>
            )}
            header="Add Date Range"
            id="addDateRangeModal"
            open={addDateRangeModalOpen}
            style={{ height: '65%', width:'51%' }}
            options={{
                dismissible: true,
                endingTop: '10%',
                opacity: 0.5,
                preventScrolling: true,
                onCloseEnd: () => setAddDateRangeModalOpen(false)
            }}
        >
            <DateRangePicker
                ranges={[{
                    startDate,
                    endDate,
                    key: 'selection',
                }]}
                direction="horizontal"
                fixedHeight={true}
                months={2}
                onChange={onDateChange} />
        </Modal>
    );
}
