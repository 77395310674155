import React from 'react';
import { Container, Table } from "react-materialize";

export default ({ data }) => {
    return (
        <Container id="customer-tabs-container">
            <Table className = "highlight">
                <thead>
                    <tr>
                        <th>Billing Number</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.length > 0 ? data.map((item, i)=>{
                            return (
                                <tr key={'billing-table-row'+i}>
                                    <td>{i+1}</td>
                                    <td>{item.start_date}</td>
                                    <td>{item.end_date}</td>
                                </tr>
                            )
                        }) : <tr>
                                <td colspan="6" style={{ textAlign: 'center' }}>No Data Found</td>
                            </tr>
                    }
                </tbody>
            </Table>
        </Container>
    );
}
