import React, { Component, useCallback, useState } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core'
import { connect } from "react-redux";
import {
    faArchive,
    faAt,
    faBell,
    faChartLine,
    faCheck,
    faCircle,
    faClock,
    faCogs,
    faHourglassEnd,
    faIdBadge,
    faPauseCircle,
    faPlus,
    faPlusSquare,
    faQuestion,
    faRedo,
    faStar,
    faStopwatch,
    faSync,
    faTag,
    faTicketAlt,
    faUser,
    faUsers,
    faUserTag,
    faUserTie
} from "@fortawesome/free-solid-svg-icons";
import { faComments } from "@fortawesome/free-regular-svg-icons";

import Header from "./Header";
import routes from "./Routes"; // Import routes from a separate file
import PublicRoutes from "./PublicRoutes";
import { Slide, ToastContainer } from "react-toastify";
import { Container } from "react-materialize";

library.add(faStar, faQuestion, faCheck, faComments, faCircle, faPlus, faHourglassEnd, faClock, faUser, faUsers, faBell,
    faChartLine, faUserTie, faArchive, faIdBadge, faCogs, faTicketAlt, faSync, faAt, faUserTag, faPauseCircle, faRedo,
    faPlusSquare, faStopwatch, faTag);

let parentId = localStorage.getItem('parentId');
const App = () => {
    const [sidebarToggled, setSidebarToggled] = useState(false);
    const [isMobileView, setIsMobileView] = useState(false);
  
    const handleSidebarToggled = useCallback((flag) => {
      setSidebarToggled(flag);
    }, []);
  
    const handleIsMobileView = useCallback((flag) => {
      setIsMobileView(flag);
    }, []);
    


        return (
            <Container>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={true}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    pauseOnHover
                    draggable
                    transition={Slide}
                />
                <BrowserRouter>
                    <div className="main-sec">
                            <div className="left-sec">
                                <Header
                                sidebarToggled={sidebarToggled}
                                handleSidebarToggled={handleSidebarToggled}
                                handleIsMobileView={handleIsMobileView}
                                />
                            </div>
                           {PublicRoutes.map((publicRoute) => (
                            <Route
                            key={publicRoute.path}
                            exact
                            path={publicRoute.path}
                            component={publicRoute.component}
                            />
                        ))}
                        <div
                            className={
                            sidebarToggled
                                ? "right-sec active"
                                : isMobileView
                                ? "right-sec hover"
                                : "right-sec"
                            }
                         >
                            {routes.map((route) => (
                            <Route
                                key={route.path}
                                exact
                                path={route.path}
                                component={route.component}
                            />
            ))}
          </div>
        </div>
      </BrowserRouter>
    </Container>
  );
};

export default App;
