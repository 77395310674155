import React, { useEffect, useState} from "react";
import {axiosClient} from '../api/httpClient';
import {
    Button,
    Card,
    CardPanel,
    Col,
    Container,
    Icon,
    Modal,
    Row,
    TextInput
} from "react-materialize";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {connect} from "react-redux";
import * as actions from '../actions';
import * as _ from "lodash";
import ConfirmationDialog from "./ConfirmationDialog";
import {errorToast, infoToast, successToast} from "../api/Util";
import {ADMIN} from "../api/Constants";

import TopHeader from "./TopHeader";
import { SketchPicker } from 'react-color';

const INITIAL_STATE = {
    subDomain: "",
    loginPageLogo: null,
    loginPageTagLine: "",
    loginPageYTLogo: null,
    loginPageYTTagLine: "",
    sideBarLogo: null,
    favIcon: null,
    sideBarProfileLogo: null,
    themeColor: "#ab322d",
    repurposingVideosCount: "",
};

let EDIT_DETAILS_STATE;

function populateCustomers(props, onClickEditCustomer, confirmationOpen, setConfirmationOpen, onConfirmDelete, setOnConfirmDelete) {
    
    if(!props?.whiteLabelCustomers?.whiteLabelCustomers && !props.whiteLabelCustomers.loadingWhiteLabelCustomers ) {
        props.fetchAllWhiteLabelCustomers();
    }
    
    if (_.isEmpty(props?.whiteLabelCustomers?.whiteLabelCustomers)) {
        return (
            <CardPanel style={{padding: "12px", textAlign: "center"}}>
                <span style={{fontSize: "1.1em"}}>No White Label Customers found</span>
            </CardPanel>
        );
    }

    let whiteLabelCustomers = props?.whiteLabelCustomers?.whiteLabelCustomers;

    function onClickActivateDeactivate(user) {
        axiosClient.get(`/api/activate-deactivate-customer/${user.id}/${user.is_active ? '0' : '1'}`).then(({data}) => {
            if (user.is_active === 1) {
                successToast(data.message || "Status updated");   
            } else {
                if(data.message) {
                    successToast(data.message || "Status updated");
                } else {
                    errorToast("Couldn't renew subscription, please try again");
                }
            }
            props.fetchAllWhiteLabelCustomers();
        }).catch(err => {
           errorToast("Something went wrong");
           console.error(err);
        });
    }

    let showFields = props.auth.loggedInUser.client_type === ADMIN ? true : false;

    return (
        <Container>
            <ConfirmationDialog
                onNegative={() => setConfirmationOpen(false)}
                onPositive={onConfirmDelete}
                confirmationHeader="Caution"
                confirmationDialogId="customerDeleteConfirmationDialog"
                confirmation={confirmationOpen}
                confirmationText="Are you sure you want to continue?"
            />
            {
                _.map(whiteLabelCustomers, (user) => {
                    return (
                        <Col className="customer-col" s={12} m={4} l={4} key={`customer-${user.id}`}>
                            <Card
                                actions={[
                                    <Button key={`inactive-${user.client_id}`} onClick={() => {
                                        setOnConfirmDelete(() => () => onClickActivateDeactivate(user));
                                        setConfirmationOpen(true);
                                    }} disabled = {!showFields}
                                        className={user.is_active ? "btn-danger" : "btn-primary"}>
                                        {user.is_active ? "Deactivate" : "Activate"}<Icon right>check</Icon>
                                    </Button>,
                                    <Button key={`inactive-${user.id}`} className="btn-danger" onClick={() => onClickEditCustomer(user.id, user)}>
                                        Edit<Icon right>edit</Icon>
                                    </Button>
                                ]}>
                                <Container className="card-container">
                                    <Row>
                                        <Col s={12}>
                                            <span className="card-headings">Sub Domain: </span>&nbsp;{(user.url)}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col s={12} style={{ textAlign: 'justify' }}>
                                        <span className="card-headings">Login Page Tagline: </span>&nbsp;{user.homepage_logo_tagline}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col s={12} style={{ textAlign: 'justify' }}>
                                        <span className="card-headings">Login Page YT Tagline: </span>&nbsp;{user?.homepage_ythelper_tagline || ''}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col s={12}>
                                        <span className="card-headings">Theme Color: </span>&nbsp;{user.primary_color}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col s={12}>
                                                <span className="card-headings">Login Page Logo: </span>&nbsp;
                                                <img src={user.homepage_logo} alt="Avatar" className="login-logo-in-card"/>
                                        </Col>
                                    </Row>
                                </Container>
                            </Card>
                        </Col>
                    );
                })
            }
        </Container>
    );
}

export default connect(mapStateToProps, actions)((props) => {
    const [formValues, setFormValues] = useState(INITIAL_STATE);
    let [modalOpen, setModalOpen] = useState(false);
    let [addDisabled, setAddDisabled] = useState(true);
    const [type, setType] = useState('Add');
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [onConfirmDelete, setOnConfirmDelete] = useState(() => {});
    const websiteTypeId = localStorage.getItem('websiteTypeId');

    async function addCustomer(e) {
        setAddDisabled(true);
        try {
            e.preventDefault();
            const data = new FormData();
            data.append('subDomain', formValues.subDomain);
            if (formValues.loginPageLogo) {
                let loginPageLogoUrl = await fetch(formValues.loginPageLogo).then(r => r.blob());
                data.append('loginPageLogo', loginPageLogoUrl, `media-login-page-logo-label-${new Date().toString()}`);
            }
            data.append('loginPageTagLine', formValues.loginPageTagLine);
            if (formValues.loginPageYTLogo) {
                let loginPageYTLogoUrl = await fetch(formValues.loginPageYTLogo).then(r => r.blob());
                data.append('loginPageYTLogo', loginPageYTLogoUrl, `media-login-page-yt-logo-label-${new Date().toString()}`);
            }
            data.append('loginPageYTTagLine', formValues.loginPageYTTagLine);
            if (formValues.sideBarLogo) {
                let sidebarLogoUrl = await fetch(formValues.sideBarLogo).then(r => r.blob());
                data.append('sideBarLogo', sidebarLogoUrl, `media-side-bar-logo-label-${new Date().toString()}`);
            }
            if (formValues.sideBarProfileLogo) {
                let sideBarProfileLogoUrl = await fetch(formValues.sideBarProfileLogo).then(r => r.blob());
                data.append('sideBarProfileLogo', sideBarProfileLogoUrl, `media-side-bar-profile-logo-label-${new Date().toString()}`);
            }
            if (formValues.favIcon) {
                let favIconUrl = await fetch(formValues.favIcon).then(r => r.blob());
                data.append('favIcon', favIconUrl, `media-fav-icon-label-${new Date().toString()}`);
            }
            data.append('themeColor', formValues.themeColor);
            data.append('websiteTypeId', websiteTypeId);
            data.append('repurposingVideosCount', formValues.repurposingVideosCount);

            await axiosClient.post('/api/white-label-customer', data).then(({data}) => {
                if (data.message) {
                    successToast(data.message || "Status updated");
                }
                props.fetchAllWhiteLabelCustomers();
            });
            setModalOpen(false);
        } catch (err) {
            errorToast('Something went wrong: ' + err.message);
            setAddDisabled(false);
        }
    }

    const onClickEditCustomer = (id, user) => {
        setType('Edit');
        setModalOpen(true);
        EDIT_DETAILS_STATE = {
            subDomain: user.url,
            loginPageLogo: user.homepage_logo,
            loginPageTagLine: user.homepage_logo_tagline,
            loginPageYTLogo: user.homepage_ythelper_logo,
            loginPageYTTagLine: user.homepage_ythelper_tagline,
            sideBarLogo: user.sidebar_logo,
            sideBarProfileLogo: user?.profile_logo,
            favIcon: user.fav_icon,
            themeColor: user.primary_color,
            isActive: user.is_active,
            id: user.id,
            repurposingVideosCount: user.repurposing_videos_count,
        }
        setFormValues(EDIT_DETAILS_STATE);
    }

    const compareLogo = (newLogo, previousLogo) => {
        if (newLogo !== previousLogo) {
            return true
        }
        return false
    }
    async function onClickUpdate(e) {
        setAddDisabled(true);
        try {
            e.preventDefault();
            let loginPageLogoUrl = await fetch(formValues.loginPageLogo).then(r => r.blob());
            let loginPageYTLogoUrl = await fetch(formValues.loginPageYTLogo).then(r => r.blob());
            let sidebarLogoUrl = await fetch(formValues.sideBarLogo).then(r => r.blob());
            let sideBarProfileLogoUrl = await fetch(formValues.sideBarProfileLogo).then(r => r.blob());
            let favIconUrl = await fetch(formValues.favIcon).then(r => r.blob());
            const data = new FormData();
            data.append('subDomain', formValues.subDomain);
            compareLogo(
                formValues.loginPageLogo, EDIT_DETAILS_STATE.loginPageLogo) &&
                 data.append('previousLoginPageLogo', EDIT_DETAILS_STATE.loginPageLogo);
            data.append('loginPageLogo', loginPageLogoUrl, `media-login-page-logo-label-${new Date().toString()}`);
            data.append('loginPageTagLine', formValues.loginPageTagLine);
            compareLogo(
                formValues.loginPageYTLogo, EDIT_DETAILS_STATE.loginPageYTLogo) &&
              data.append("previousLoginPageYTLogo", EDIT_DETAILS_STATE.loginPageYTLogo);
            data.append('loginPageYTLogo', loginPageYTLogoUrl, `media-login-page-yt-logo-label-${new Date().toString()}`);
            data.append('loginPageYTTagLine', formValues.loginPageYTTagLine);
            compareLogo(
                formValues.sideBarLogo, EDIT_DETAILS_STATE.sideBarLogo) &&
                 data.append('previousSideBarLogo', EDIT_DETAILS_STATE.sideBarLogo);
            data.append('sideBarLogo', sidebarLogoUrl, `media-side-bar-logo-label-${new Date().toString()}`);
            compareLogo(
                formValues.sideBarProfileLogo, EDIT_DETAILS_STATE.sideBarProfileLogo) &&
                 data.append('previousSideBarProfileLogo', EDIT_DETAILS_STATE.sideBarProfileLogo);
            data.append('sideBarProfileLogo', sideBarProfileLogoUrl, `media-side-bar-profile-logo-label-${new Date().toString()}`);
            compareLogo(
                formValues.favIcon, EDIT_DETAILS_STATE.favIcon) &&
                 data.append('previousFavIcon', EDIT_DETAILS_STATE.favIcon);
            data.append('favIcon', favIconUrl, `media-fav-icon-label-${new Date().toString()}`);
            data.append('themeColor', formValues.themeColor);
            data.append('websiteTypeId', websiteTypeId);
            data.append('isActive', formValues.isActive);
            data.append('repurposingVideosCount', formValues.repurposingVideosCount);

            await axiosClient.post('/api/white-label-customer/'+ formValues.id, data).then(({data}) => {
                if (data.message) {
                    successToast(data.message || "Status updated");   
                }
                props.fetchAllWhiteLabelCustomers();
            })
            setModalOpen(false);
        } catch (err) {
            errorToast('Something went wrong: ' + err.message);
            setAddDisabled(false);
        }
    }

    function resetFields(value) {
        if (value === 'Add') {
            setFormValues(INITIAL_STATE);
        }
    }

    function triggerFile(fileid, filename){
        var clickEvent = document.createEvent("MouseEvents");
    	clickEvent.initMouseEvent("click", true, true, window, 1, 0, 0, 0, 0,
        false, false, false, false, 0, null);
        document.getElementById(fileid).dispatchEvent(clickEvent);
        document.getElementById(fileid).onchange = function (e) {
            if (e.target.files[0]?.name !== undefined) {
                var inptfileName = e.target.files[0].name;
                document.getElementById(filename).innerHTML = inptfileName;
            }
        };
    }

    function onChange(e, type) {
        let name = e.target.name;
        let value = e.target.value;
        if (type === 'logo') {
            if (name && e.target.files[0]) {
                readImageFile(name, e.target.files[0]);
            }
        } else if (type === 'number' && (value > 6 || value < 1)) {
            if (e.target.value > 6) {
                setFormValues(prev => ({...prev, [name]: 6}));
            } else if (e.target.value < 1) {
                setFormValues(prev => ({...prev, [name]: 1}));
            }
        } else {
            setFormValues(prev => ({...prev, [name]: value}));
        }
    }

    function readImageFile(name, file) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
    
        reader.onload = function (e) {
            setFormValues(prev => ({...prev, [name]: e.target.result}));
        };
    }
    

    function ImageWithRemoveIcon({ src, alt, onRemove }) {
        if (!src) return null; // Don't render if there's no image source
        return (
          <span className="rightImageBlock" style={{ position: 'relative', display: 'inline-block' }}>
            <img src={src} alt={alt} className="logo-styling" />
            <span onClick={onRemove} className="remove-icon" style={{ position: 'absolute', top: '0', right: '0', cursor: 'pointer', fontSize: '20px' }}>✖</span>
          </span>
        );
      }
      
      const onRemoveImage = async (fieldName, imageUrl) => {
        try {
            if (imageUrl.startsWith('data:image/png;base64')) {
                // Do something specific for base64 image URLs
            } else if (imageUrl.startsWith('https://videoportal-files')) {
                await removeImageFromBucket(imageUrl);
            }
            setFormValues(prevValues => ({
                ...prevValues,
                [fieldName]: null // Clear the field value
            }));
        } catch (error) {
            console.error('Error removing image:', error);
        }
    }
    

    const removeImageFromBucket = async (imageUrl) => {
        try {
            await axiosClient.post('/api/delete-image', { imageUrl });
            successToast("Image deleted successfully");
        } catch (error) {
            console.error('Error removing image:', error);
        }
    }
    
    
    // To Enable the add Button if fields are not empty
    useEffect(() => {
        if (
          formValues.subDomain !== "" &&
          formValues.loginPageLogo !== null &&
          formValues.loginPageTagLine !== "" &&
          formValues.repurposingVideosCount !== ""
        ) {
          setAddDisabled(false);
        } else {
            setAddDisabled(true);
        }
      }, [formValues]);

    return (
        <Container>
            <div className="aside-right-top-bar all-customer-topbar">
                <div className="top-bar-left">
                    <div className="top_bar">
                        <h3>White Label Customers</h3>
                    </div>
                </div>
                <div className="top-bar-right">
                    <TopHeader/>
                </div>
            </div>
            <div className="aside-top-filters filter-customer-top">
            <Row className="users-top-btn">
                <Col s={6} m={3}>
                    <CardPanel style={{padding: "12px", textAlign: "center", cursor: "pointer"}}
                               className="red darken-1 customer-reload-btn"
                               onClick={() => {
                                   props.fetchAllWhiteLabelCustomers();
                                   infoToast("Reloading customers")
                               }}>
                        <FontAwesomeIcon icon="sync" inverse/>&nbsp;&nbsp;<span className="text-primary"
                                                                                style={{fontSize: "1.1em"}}>White Label Customers</span>
                    </CardPanel>
                </Col>
                <Col s={6} m={3}>
                    <CardPanel style={{padding: "12px", textAlign: "center"}}>
                        <FontAwesomeIcon icon="users"/>&nbsp;&nbsp;
                        <span className="text-accent-1" style={{fontSize: "1.1em"}}>Active: {props?.whiteLabelCustomers?.whiteLabelCustomers?.reduce(
                            (count, customer) => count + (customer.is_active), 0)}</span>
                    </CardPanel>
                </Col>
                <Col s={6} m={3}>
                    <CardPanel style={{padding: "12px", textAlign: "center"}}>
                        <FontAwesomeIcon icon="users"/>&nbsp;&nbsp;
                        <span className="text-darken-1" style={{fontSize: "1.1em"}}>Inactive: {props?.whiteLabelCustomers?.whiteLabelCustomers?.reduce(
                            (count, customer) => count + (!customer.is_active), 0)}</span>
                    </CardPanel>
                </Col>
                <Col s={6} m={3}>
                    <Modal
                        actions={[
                            <Button flat modal="close" node="button" className="btn-danger" large>Close</Button>,
                            <Button modal="close" onClick={e => {type === 'Edit' ? onClickUpdate(e) : addCustomer(e)}} disabled={addDisabled} node="button"
                                    waves="green" className="btn-primary" large>{type === 'Edit' ? 'Update' : type}</Button>
                        ]}
                        trigger={(
                            <div style={{ display: 'contents'}}>
                                <Button className="open-modal-button" onClick={() => resetFields('Add')}>
                                    <FontAwesomeIcon icon="plus" inverse/>&nbsp;&nbsp;Add White Label Customer
                                </Button>
                            </div>
                        )}
                        header={`${type} White Label Customer`}
                        id="addCustomerModal"
                        open={modalOpen}
                        style={{height: '60%'}}
                        options={{
                            dismissible: true,
                            endingTop: '10%',
                            opacity: 0.5,
                            preventScrolling: true,
                            onCloseEnd: () => {setModalOpen(false); setType('Add')}
                        }}
                    >
                        <Container>
                            <Row>
                                <TextInput s={12} m={6} id="txtSubDomain" name="subDomain" label="Sub Domain *" icon="person"
                                           value={formValues.subDomain} onChange={onChange} required />
                                <div className="col s12 m6">
                                    <input s={12} m={6} id="imgLoginPage" name="loginPageLogo" type="file" className="fileopacitybtn" accept="image/*" onChange={e => onChange(e, 'logo')}/>
                                    <span onClick={() => triggerFile('imgLoginPage','imgLoginPageName')} className="getfilebutton">Select Login Page Logo *</span>
                                    <span className="imagefilename" id="imgLoginPageName"></span>
                                    <ImageWithRemoveIcon src={formValues.loginPageLogo} alt="" onRemove={() => onRemoveImage('loginPageLogo', formValues.loginPageLogo)}/>                            
                                </div>
                            </Row>
                            <Row>
                                <TextInput s={12} m={6} id="txtLoginPageTagLine" name="loginPageTagLine" label="Login Page Tagline *" icon="tagline" value={formValues.loginPageTagLine} onChange={onChange}/>
                                <div className="col s12 m6">
                                    <input s={12} m={6} id="imgLoginPageYT" name="loginPageYTLogo" className="fileopacitybtn" type="file" accept="image/*"  onChange={e => onChange(e, 'logo')}/>
                                    <span onClick={() => triggerFile('imgLoginPageYT','imgLoginPageName')} className="getfilebutton">Select Login Page YT Logo</span>
                                    <span className="imagefilename" id="imgLoginPageYTName"></span>
                                    <ImageWithRemoveIcon src={formValues.loginPageYTLogo} alt="" onRemove={() => onRemoveImage('loginPageYTLogo', formValues.loginPageYTLogo)}/>                              
                                </div>
                            </Row>
                            <Row>
                                <TextInput s={12} m={6} id="txtLoginPageYTTagLine"  name="loginPageYTTagLine" label="Login Page YT TagLine" icon="tagline" value={formValues.loginPageYTTagLine} onChange={onChange}/>
                                <div className="col s12 m6">
                                    <input s={12} m={6} id="imgSideBar"  name="sideBarLogo" className="fileopacitybtn" type="file" accept="image/*" onChange={e => onChange(e, 'logo')}/>
                                    <span onClick={() => triggerFile('imgSideBar','imgLoginPageName')} className="getfilebutton">Select Side Bar Logo</span>
                                    <span className="imagefilename" id="imgSideBarName"></span>
                                    <ImageWithRemoveIcon src={formValues.sideBarLogo} alt="" onRemove={() => onRemoveImage('sideBarLogo', formValues.sideBarLogo)}/>                              
                                </div>
                            </Row>
                            <Row>
                            <TextInput s={12} m={6} id="txtrepurposingVideosCount" name="repurposingVideosCount" label="Repurposing Videos Count *" icon="link"
                                           value={formValues.repurposingVideosCount.toString()} type="number" onChange={(e) => onChange(e, 'number')} />
                                <div className="col s12 m6">
                                    <input s={12} m={6} id="imgSideBarProfile"  name="sideBarProfileLogo" className="fileopacitybtn" type="file" accept="image/*" onChange={e => onChange(e, 'logo')}/>
                                    <span onClick={() => triggerFile('imgSideBarProfile','imgLoginPageName')} className="getfilebutton">Select Profile Logo</span>
                                    <span className="imagefilename" id="imgSideBarProfileName"></span>
                                    <ImageWithRemoveIcon src={formValues.sideBarProfileLogo} alt="" onRemove={() => onRemoveImage('sideBarProfileLogo', formValues.sideBarProfileLogo)}/>                              
                                </div>
                            </Row>
                            <Row>
                                <TextInput s={12} m={6} id="txtThemeColor" label="Select Primary Color" icon="palette" disabled={true} value={formValues.themeColor} />
                                <div className="col s12 m6">
                                    <input s={12} m={6} id="imgFavIcon"  name="favIcon" className="fileopacitybtn" type="file" accept="image/*" onChange={e => onChange(e, 'logo')}/>
                                    <span onClick={() => triggerFile('imgFavIcon','imgLoginPageName')} className="getfilebutton">Select FavIcon</span>
                                    <span className="imagefilename" id="imgFavIconName"></span>
                                    <ImageWithRemoveIcon src={formValues.favIcon} alt="" onRemove={() => onRemoveImage('favIcon', formValues.favIcon)}/>                              
                                </div>
                            </Row>
                            <Row>
                                <Col md="auto">
                                <SketchPicker
                                    color={ formValues.themeColor }
                                    onChange={ color => setFormValues(prev => ({...prev, 'themeColor': color.hex})) }
                                    colors={['#ab322d', '#FCB900', '#7BDCB5', '#00D084', '#8ED1FC', '#0693E3', '#ABB8C3', '#EB144C', '#F78DA7', '#9900EF']}
                                />
                                </Col>
                            </Row>
                        </Container>
                    </Modal>
                </Col>
            </Row>
            </div>
            <Row className="customers-row-cards">
                {populateCustomers(props, onClickEditCustomer, confirmationOpen, setConfirmationOpen, onConfirmDelete, setOnConfirmDelete)}
            </Row>
        </Container>
    );
});

function mapStateToProps({whiteLabelCustomers}) {
    return {whiteLabelCustomers};
}
