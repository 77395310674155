import React, {useState} from "react";
import {errorToast, isAdmin, isCustomer, isTeamLead, isSubAdmin, successToast} from "../api/Util";
import {Button, Icon} from "react-materialize";
import ConfirmationDialog from "./ConfirmationDialog";
import {axiosClient} from "../api/httpClient";
import {connect} from "react-redux";
import {DONE, QA, YT_HELPER_IN_DONE, YT_HELPER_SECTION} from "../api/Constants";

const replaceString = require('react-string-replace');
const actions = require('../actions');

export default connect(null, actions)(({me, card, fetchCards,fetchYtHelperCards,section = ''}) => {
    if (isCustomer(me) && me.is_active_subscription === 0) {
        return null;
    }

    const [confirmationOpen, setConfirmationOpen] = useState(false);

    let allow = isCustomer(me) || isAdmin(me) || isSubAdmin(me);

    if (card.card_status === QA) {
        allow = isAdmin(me) || isSubAdmin(me) || isTeamLead(me);
    }

    if(!allow || (section !== YT_HELPER_SECTION && card.card_status !== DONE && card.card_status !== QA)
        || (section !== YT_HELPER_SECTION && card.is_complete ) || (section === YT_HELPER_SECTION && card.yt_card_status !== YT_HELPER_IN_DONE) 
        || (section === YT_HELPER_SECTION && card.yt_is_complete)) {
        return "";
    }

    function onConfirmReview() {

        let ytHelperUrl = `/api/ythelper/card/${card.card_id}/revision`;
        let commonCardUrl = `/api/card/${card.card_id}/revision`;

        axiosClient.post( section === YT_HELPER_SECTION ? ytHelperUrl : commonCardUrl ,
            {card_id: card.card_id, card_status: card.card_status})
            .then(({data}) => {
                successToast(data.success || "Card moved to revision successfully");
                section === YT_HELPER_SECTION ? fetchYtHelperCards() : fetchCards();
            }).catch(err => {
                let message = "Something went wrong, please try again";
                if(err?.response?.data && err.response.data.message && err.response.data.message !== "") {
                    message = err.response.data.message;
                }
                errorToast(message);
                console.error(err);
            });
    }

    return (
        <Button className="btn-danger" icon={<Icon left>navigate_before</Icon>}
                onClick={() => setConfirmationOpen(true)} >
            Revision
            <ConfirmationDialog
                onNegative={() => setConfirmationOpen(false)}
                onPositive={onConfirmReview}
                confirmationHeader="Revision"
                confirmationDialogId={"confirm-revision-" + card.card_id}
                confirmation={confirmationOpen}
                confirmationText={replaceString("Requesting a revision for: PLCHLDR.\nAre you sure you want to proceed?", /(PLCHLDR)/,
                    (match, i) => (<strong key={match + i}>{card.card_title}</strong>))}
            />
        </Button>
    );
});
