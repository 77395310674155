import React, {useCallback, useEffect, useState} from "react";
import {Button, Card, Col, Container, Icon, Pagination, Row} from "react-materialize";
import axios from "axios";
import {axiosClient} from "../api/httpClient";
import {connect} from "react-redux";
import {errorToast, infoToast, isAdmin, isCustomer} from "../api/Util";
import {moveCard} from "../api/CardActions";
import {ARCHIVED, DONE} from "../api/Constants";
import CardModal from "./CardModal";
import TopHeader from "./TopHeader";
import Select from 'react-select';
import _ from "lodash";
import { Input } from "antd";

const cancelTokenSource = axios.CancelToken.source();

export default connect(mapStateToProps)((props) => {

    const loggedInUser = props?.auth?.loggedInUser || {};
    const customer = isCustomer(loggedInUser);
    const admin = isAdmin(loggedInUser);
    const [cards, setCards] = useState([]);
    const [index, setIndex] = useState(1);
    const [viewFor, setViewFor] = useState({ value: "0", label: 'All' });
    const [loading, setLoading] = useState(false);
    const [viewCardId, setViewCardId] = useState(0);
    const [viewCardOpen, setViewCardOpen] = useState(false);
    const [searchTitle, setsearchTitle] = useState("");
    const limit = 10;

    const refresh = () => {
        if(loading) {
            return;
        }
        setLoading(true);
        infoToast("Loading");
        axiosClient.get('/api/cards/archive', {
            cancelToken: cancelTokenSource.token,
            params: {
                limit: limit,
                page: index - 1,
                client_id: Number(viewFor?.value),
                searchTitle: searchTitle
            }
        })
            .then(({data}) => setCards(data))
            .catch(err => {
                if (!axios.isCancel(err)) {
                    errorToast("Something went wrong");
                    console.error(err);
                }
            }).finally(() => setLoading(false));
    };

    useEffect(() => {
        if (props.users.customers === undefined && !props.users.loadingCustomers) {
            props.fetchAllCustomers();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    

    useEffect(() => {
        refresh();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [index, viewFor, searchTitle]);

    function unArchive(cardId) {
        infoToast("Please wait");
        moveCard(cardId, DONE, ARCHIVED);
    }
    const showCard = (cardId) => {
        setViewCardId(cardId);
        setViewCardOpen(true);
    }
    const debouncedSearch = useCallback(_.debounce((newsearchTitle) => {
        setsearchTitle(newsearchTitle);
    }, 1000), []);

    const handleSearchChange = (event) => {
        debouncedSearch(event.target.value);
    };
    

    return (
        <Container className="archived-main">
            <div className="aside-right-top-bar">
                <div className="top-bar-left">
                    <div className="top_bar">
                        <h3>Archived</h3>
                    </div>
                </div>
                <div className="top-bar-right">
                    <TopHeader/>
                </div>
            </div>
            <CardModal cardId={viewCardId} isOpen={viewCardOpen} onClose={setViewCardOpen.bind(this, false)} me={loggedInUser} />
            <Card 
                title={<Row>
                    {
                        !customer ?
                            <Col s={4} m={3} className="archived-cus-select archived-cus-select-input">
                                <Select
                                    style={{ margin: 0, padding: 0 }}
                                    key="select-customer-archive"
                                    value={viewFor}
                                    onChange={value => setViewFor(value)}
                                    options={_.concat([{ value: "0", label: 'All' }], (props.users?.customers || []).map(user => ({ value: user.client_id, label: user.fullname })))}
                                />
                            </Col> : null
                    }
                    <Col s={4} m={3} className="archived-cus-select">
                        <Input
                            placeholder="Search by name"
                            allowClear 
                            className="searchedUser"
                            style={{height:"49px" }}
                            onChange={handleSearchChange}
                        />
                    </Col> 
                    <Col s={4} m={3} className="archived-cus-select" style={{'float':'right'}}>
                        <button onClick={refresh} node="button" waves="light" large flat className="archived-cus-button">
                            <Icon right>refresh</Icon>Reload 
                        </button>
                    </Col>
                </Row>} >
            </Card>
            <Card>
                <div className="archived-table-row">
                <table>
                    <thead>
                    <tr>
                        <th width="15%">Customer</th>
                        <th width="35%">Card Title</th>
                        <th width="5%">Rating</th>
                        <th width="7%">Subscription</th>
                        <th width="10%">Created On</th>
                        <th width="10%">Assigned On</th>
                        <th width="10%">Done On</th>
                        <th width="10%">Archived On</th>
                        <th width="15%">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        React.Children.toArray(cards.map(card => <tr style={{cursor: "pointer"}}
                                                                     onClick={() => showCard(card.card_id)}>
                            <td>{card.client_name}</td>
                            <td>{card.card_title}</td>
                            <td>{card.rating || "-"}</td>
                            <td>{card.subscription_type}</td>
                            <td>{card.creation_time}</td>
                            <td>{card.assigned_time}</td>
                            <td>{card.done_time}</td>
                            <td>{card.archived_time}</td>
                            <td>
                                {!customer &&
                                    <Button flat icon={<Icon>unarchive</Icon>} small tooltip={admin ? "Un-Archive" : "Not Allowed"} disabled={!admin}
                                            onClick={() => unArchive(card.card_id)}/>
                                }
                            </td>
                        </tr>))
                    }

                    </tbody>
                </table>
                </div>
                
                <Row className="center-align">
                    {
                        cards.length > 0 ?
                            <Row className="center-align">
                                <Pagination
                                    activePage={index}
                                    items={limit}
                                    leftBtn={<Icon>chevron_left</Icon>}
                                    rightBtn={<Icon>chevron_right</Icon>}
                                    onSelect={i => setIndex(i)}
                                />
                            </Row> : null
                    }
                </Row>
            </Card>
        </Container>
    );
});

function mapStateToProps({auth, users}) {
    return {auth, users};
}
