import './styles/app.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {applyMiddleware, createStore} from "redux";
import reduxThunk from 'redux-thunk';

import App from './components/App';
import reducers from './reducers';
import {axiosClient, setAuthToken, setParentId, setUpInterceptors, setWebsiteType,setUserTimezoneInRequest} from "./api/httpClient";
import {FETCH_USER, FETCHING_USER, FETCH_SUB_DOMAIN_DATA, FETCHING_SUB_DOMAIN_DATA,FETCH_NOTIFICATION_COUNT,FETCHING_NOTIFICATION_COUNT,FETCHING_NOTIFICATION_LIST,FETCH_NOTIFICATION_LIST} from "./actions/types";

const store = createStore(reducers, {}, applyMiddleware(reduxThunk));

let authToken = localStorage.getItem("Authorization");
setAuthToken(authToken);
setWebsiteType(localStorage.getItem('website_type'));
setParentId(localStorage.getItem('parentId'));
setUserTimezoneInRequest();
setUpInterceptors(store);

if (window.location.host) {
    store.dispatch({type: FETCHING_SUB_DOMAIN_DATA});
    axiosClient.get('/api/white-label-customer-info/'+ window.location.host)
    .then(response => {
        store.dispatch({type: FETCH_SUB_DOMAIN_DATA, payload: response});
        localStorage.setItem('subDomainData', JSON.stringify(response?.data));
        document.getElementById('favIConTitle').innerHTML= response?.data?.name;
        const favicon192 = document.getElementById("favicon192");
        const favicon32 = document.getElementById("favicon32");
        const faviconImg = document.getElementById("faviconImg");
        if (response?.data.fav_icon && response?.data.fav_icon !== null ) {
            favicon192.href = response?.data.fav_icon;
            favicon32.href = response?.data.fav_icon;
            faviconImg.href = response?.data.fav_icon;
        }
        localStorage.setItem('parentId', (response?.data?.parent_id));
        }).catch(err => {
            console.error(err);
            store.dispatch({type: FETCH_SUB_DOMAIN_DATA, payload: err.response});
    });
}

if(authToken) {
    store.dispatch({type: FETCHING_NOTIFICATION_COUNT});
    axiosClient.get("/api/notifications/getCount")
        .then(response => {
            store.dispatch({type: FETCH_NOTIFICATION_COUNT, payload: response.data.notificationsCount});
        }).catch(err => {
            console.error(err);
            store.dispatch({type: FETCH_NOTIFICATION_COUNT, payload: err.response});
    });
    store.dispatch({type: FETCHING_NOTIFICATION_LIST});
    axiosClient.get("/api/notifications")
        .then(response => {
            store.dispatch({type: FETCH_NOTIFICATION_LIST, payload: response.data.notifications});
        }).catch(err => {
            console.error(err);
            store.dispatch({type: FETCH_NOTIFICATION_LIST, payload: err.response});
    });
    store.dispatch({type: FETCHING_USER});
    axiosClient.get('/api/me')
        .then(response => {
            store.dispatch({type: FETCH_USER, payload: response});
        }).catch(err => {
            console.error(err);
            store.dispatch({type: FETCH_USER, payload: err.response});
    });
}

ReactDOM.render(
    <Provider store={store}><App/></Provider>,
    document.querySelector('#root')
);
