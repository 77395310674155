import React, {useState, useEffect} from "react";
import {axiosClient} from '../api/httpClient';
import {
    Button,
    Card,
    CardPanel,
    CardTitle,
    Col,
    Container,
    Icon,
    Modal,
    ProgressBar,
    Row,
    Select,
    Table,
    TextInput
} from "react-materialize";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {connect} from "react-redux";
import * as actions from '../actions';
import * as _ from "lodash";
import {Link} from "react-router-dom";
import {errorToast, generateRandomPassword, getUserOptions, infoToast, isDateBetween, isTrue, successToast, toISODate, isSubAdmin, exportTableToCSV} from "../api/Util";
import ConfirmationDialog from "./ConfirmationDialog";
import {ADMIN, CUSTOM, DOUBLE, QA_USER, SINGLE, SUB_ADMIN, UNLIMITED, WEEKLY} from "../api/Constants";
import TopHeader from "./TopHeader";
import { DateRangePicker } from "react-date-range";
import { fetchCustomerDetails } from "../services/customerService";

const INITIAL_FILTER_STATE = {
    subscriptionType : "",
    referral : "",
    startDate : null,
    endDate : new Date(),
};

function populateCustomers(props, confirmationOpen, setConfirmationOpen, onConfirmDelete, setOnConfirmDelete, filterData) {
    if(!props?.users?.users && !props.users.loadingUsers ) {
        props.fetchAllUsers();
    }

    if (!props?.users?.customers && !props.users.loadingCustomers) {
        props.fetchAllCustomers();
        return (
            <ProgressBar/>
        );
    }
    if (_.isEmpty(props?.users?.customers)) {
        return (
            <CardPanel style={{padding: "12px", textAlign: "center"}}>
                <span style={{fontSize: "1.1em"}}>No customers found</span>
            </CardPanel>
        );
    }

    function onClickDelete(e, user) {
        setOnConfirmDelete((event) => () => deleteUser(event, user));
        setConfirmationOpen(true);
    }

    function onClickDeactivate(e, user) {
        axiosClient.post(`/api/user/${user.client_id}/toggleUserSubscription`, {
            client_id: user.client_id, is_active_subscription: user.is_active_subscription ? 0 : 1
        }).then(({data}) => {
            successToast(data.status || "Status updated");
            props.fetchAllCustomers();
        }).catch(err => {
           errorToast("Something went wrong");
           console.error(err);
        });
    }

    function renewSubscription(clientId) {
        if (window.confirm('Are you sure you want to renew subscription?')) {
            axiosClient.post(`/api/user/${clientId}/renewSubscription`)
                .then(({data}) => {
                    if(data.success) {
                        successToast(data.success);
                    } else if (data.error_message) {
                        errorToast(data.error_message);
                    }
                }).catch(err => {
                    console.error(err);
                    errorToast('Something went wrong when renewing subscription');
            });
        }
    }

    let showFields = (props.auth.loggedInUser.client_type === ADMIN || props.auth.loggedInUser.client_type === SUB_ADMIN) ? true : false;

    async function deleteUser(e, user) {
        try {
            let response = await axiosClient.delete('/api/user/' + user.client_id);
            successToast(response.data.message);
            props.removeCustomer(user);
        } catch (err) {
            errorToast(err.data.message || "Unable to delete customer");
        }
        setConfirmationOpen(false);
    }

    var customersList = props.users.customers;

    if (filterData && filterData.subscriptionType) {
        customersList = customersList.filter(customer => customer.subscription_type === filterData.subscriptionType);
    }
    if (filterData && filterData.referral) {
        customersList = customersList.filter(customer => customer.referral === filterData.referral);
    }
    if (filterData && filterData.startDate && filterData.endDate) {
        let startDate = toISODate(filterData.startDate), endDate = toISODate(filterData.endDate);
        customersList = customersList.filter(customer => isDateBetween(startDate,endDate,toISODate(customer.billing_date)));
    }

    return (
        <Container>
            <ConfirmationDialog
                onNegative={() => setConfirmationOpen(false)}
                onPositive={onConfirmDelete}
                confirmationHeader="Caution"
                confirmationDialogId="customerDeleteConfirmationDialog"
                confirmation={confirmationOpen}
                confirmationText="Are you sure you want to delete customer?"
            />
            {
                // Removed _.map props.users.customers so that all the customer can be mapped
                _.map(customersList, (user) => {
                    return (
                        <Col className="customer-col" s={12} m={4} l={4} key={`customer-${user.client_id}`}>
                            <Card
                                header={<CardTitle className="user-card-title" image="">
                                    <Link to={`/customer/${user.client_id}/profile`}>{user.fullname}</Link>
                                </CardTitle>}
                                actions={[
                                    <Button key={`delete-${user.client_id}`} onClick={e => onClickDelete(e, user)} disabled = {!showFields}
                                            className="btn-danger" style={{width: "50%"}}>
                                        Delete <Icon right>delete_forever</Icon>
                                    </Button>,
                                    <Button key={`inactive-${user.client_id}`} onClick={e => onClickDeactivate(e, user)} disabled = {!showFields}
                                            className={user.is_active_subscription ? "btn-danger" : "btn-primary"}>
                                        {user.is_active_subscription ? "Deactivate" : "Activate"}<Icon right>check</Icon>
                                    </Button>
                                ]}>
                                <Container style={{marginTop: "10px", minHeight: "280px"}}>
                                    <Row>
                                        <Col s={12}>
                                            <FontAwesomeIcon icon="user-tag"/>&nbsp;{(user.subscription_type || "")}
                                            {user.subscription_type === CUSTOM && user.custom_subscription ? `: ${user.custom_subscription}` : null}
                                            &nbsp;&nbsp;
                                            {
                                                user.subscription_type === SINGLE ?
                                                showFields && <Button tooltip="Renew subscription" icon={<Icon>refresh</Icon>}
                                                            onClick={() => renewSubscription(user.client_id)} />
                                                    : null
                                            }
                                        </Col>
                                    </Row>
                                    {
                                        isTrue(user.has_subtitles) &&
                                            <Row>
                                                <Col s={12}>
                                                    <FontAwesomeIcon icon="plus-square"/>&nbsp;Has Subtitles
                                                </Col>
                                            </Row>
                                    }
                                    {
                                        isTrue(user.has_youtube_helper) &&
                                            <Row>
                                                <Col s={12}>
                                                    <FontAwesomeIcon icon="plus-square"/>&nbsp;Has Youtube Helper
                                                </Col>
                                            </Row>
                                    }
                                    <Row>
                                        <Col s={12}>
                                            <FontAwesomeIcon icon="at"/>&nbsp;<a
                                            href={`mailto:${user.useremail}`}>{user.useremail}</a>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col s={12}>
                                            <span>
                                                <FontAwesomeIcon icon="at"/>&nbsp;Alternate:
                                                {
                                                    user.secondary_email &&
                                                    (<a target="_blank" rel="noopener noreferrer" href={`mailto:${user.secondary_email}`}>{user.secondary_email || 'NA'}</a>)
                                                }
                                            </span>
                                        </Col>
                                    </Row>
                                    {
                                        user?.assigned?.map(assignment => (<Row key={"assignment-" + assignment.client_id}>
                                            <Col s={12}>
                                                <FontAwesomeIcon icon="user-tie"/>&nbsp;Assigned: <Link
                                                to={`/user/${assignment.client_id}/profile`}>{assignment.name} ({assignment.client_type})</Link>
                                            </Col>
                                        </Row>))
                                    }
                                </Container>
                            </Card>
                        </Col>
                    );
                })
            }
        </Container>
    );
}

export default connect(mapStateToProps, actions)((props) => {
    let [firstName, setFirstName] = useState("");
    let [lastName, setLastName] = useState("");
    let [email, setEmail] = useState("");
    let [password, setPassword] = useState("");
    let [type, setType] = useState(SINGLE);
    let [customType, setCustomType] = useState('');
    let [qa, setQa] = useState("0");
    let [modalOpen, setModalOpen] = useState(false);
    let [addDateRangeModalOpen, setAddDateRangeModalOpen] = useState(false);
    let [addDisabled, setAddDisabled] = useState(false);
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [onConfirmDelete, setOnConfirmDelete] = useState(() => {});
    const [filterCsvData, setFilterCsvData] = useState([]);

    const [filterData, setfilterData] = useState(INITIAL_FILTER_STATE);

    async function addCustomer(e) {
        setAddDisabled(true);
        try {
            e.preventDefault();
            let response = await axiosClient.post('/api/user',
                {
                    firstname: firstName, lastname: lastName, email, password, subAdminCheck: (isSubAdmin(props.auth.loggedInUser) && props.subDomain.subDomainData.name === "vipleadmachine") ? 1 : 0, custom_subscription: isSubAdmin(props.auth.loggedInUser) ? "12" : customType,
                    subscription_type: isSubAdmin(props.auth.loggedInUser) ? CUSTOM : type, assign_qa: qa === "0" ? null : [Number(qa)]
                });
            successToast(response.data.message);
            setAddDisabled(false);
            if (response.data && !_.isEmpty(response.data.entity)) {
                props.addCustomer(response.data.entity);
                props.fetchAllCustomers();
            }
            setModalOpen(false);
        } catch (err) {
            if (err?.response?.data?.error_message && err?.response?.data?.error_message !== "") {
                errorToast(err.response.data.error_message);
            } else {
                errorToast('Something went wrong: ' + err.message);
            }
            setAddDisabled(false);
        }
    }

    // To Enable the add Button if fields are not empty
    useEffect(() => {
        if (
            firstName !== "" &&
            lastName !== "" &&
            email !== "" &&
            password !== "" &&
            type !== ""
        ) {
            setAddDisabled(false);
        } else {
            setAddDisabled(true);
        }
        }, [firstName, lastName, email, password, type]);

    function setRandomPassword() {
        setPassword(generateRandomPassword);
    }

    function resetFields() {
        setFirstName("");
        setLastName("");
        setEmail("");
        setPassword("");
        setType(SINGLE);
        setQa("0");
    }

    const exportCustomers = async () => {
        infoToast("Loading")
        const res = await fetchCustomerDetails(filterData);
        if (res.status) {
            setFilterCsvData(res.data)
            exportTableToCSV('customers-table', `Customers.csv`);
        } else {
            errorToast("Something went wrong");
        }
    }

    return (
        <Container>
            <div className="aside-right-top-bar all-customer-topbar">
                <div className="top-bar-left">
                    <div className="top_bar">
                        <h3>Customers</h3>
                    </div>
                </div>
                <div className="top-bar-right">
                    <TopHeader/>
                </div>
            </div>
            <div className="aside-top-filters filter-customer-top">
            <Row className="users-top-btn">
                <Col s={6} m={3}>
                    <CardPanel style={{padding: "12px", textAlign: "center", cursor: "pointer"}}
                               className="red darken-1 customer-reload-btn"
                               onClick={() => {
                                   props.fetchAllCustomers();
                                   infoToast("Reloading customers")
                               }}>
                        <FontAwesomeIcon icon="sync" inverse/>&nbsp;&nbsp;<span className="text-primary"
                                                                                style={{fontSize: "1.1em"}}>Customers</span>
                    </CardPanel>
                </Col>
                <Col s={6} m={3}>
                    <CardPanel style={{padding: "12px", textAlign: "center"}}>
                        <FontAwesomeIcon icon="users"/>&nbsp;&nbsp;
                        <span className="text-accent-1" style={{fontSize: "1.1em"}}>Active: {props?.users?.customers?.reduce(
                            (count, customer) => count + (customer.is_active_subscription), 0)}</span>
                    </CardPanel>
                </Col>
                <Col s={6} m={3}>
                    <CardPanel style={{padding: "12px", textAlign: "center"}}>
                        <FontAwesomeIcon icon="users"/>&nbsp;&nbsp;
                        <span className="text-darken-1" style={{fontSize: "1.1em"}}>Inactive: {props?.users?.customers?.reduce(
                            (count, customer) => count + (!customer.is_active_subscription), 0)}</span>
                    </CardPanel>
                </Col>
                <Col s={6} m={3}>
                    <Modal
                        actions={[
                            <Button flat modal="close" node="button" className="btn-danger" large>Close</Button>,
                            <Button modal="close" onClick={e => addCustomer(e)} disabled={addDisabled} node="button"
                                    waves="green" className="btn-primary" large>Add</Button>
                        ]}
                        trigger={(
                            <CardPanel style={{padding: "12px", textAlign: "center", cursor: "pointer"}}
                                       className="color-green-imp" >
                                <FontAwesomeIcon icon="plus" inverse/>&nbsp;&nbsp;
                                <span className="text-primary" style={{fontSize: "1.1em", color: '#fff'}}>Add Customer</span>
                            </CardPanel>
                        )}
                        header="Add New Customer"
                        id="addCustomerModal"
                        open={modalOpen}
                        style={{height: isSubAdmin(props.auth.loggedInUser) ? '37%' : '45%'}}
                        options={{
                            dismissible: true,
                            endingTop: '10%',
                            opacity: 0.5,
                            preventScrolling: true,
                            onOpenStart: resetFields,
                            onCloseEnd: () => setModalOpen(false)
                        }}
                    >
                        <Container>
                            <Row>
                                <TextInput s={12} m={6} id="txtFirstName" label="First Name" icon="person"
                                           value={firstName} onChange={e => setFirstName(e.target.value)}/>
                                <TextInput s={12} m={6} id="txtLastName" label="Last Name" icon="person"
                                           value={lastName} onChange={e => setLastName(e.target.value)}/>
                            </Row>
                            <Row>
                                <TextInput s={12} m={6} id="txtEmail" label="Email" email validate icon="email"
                                           value={email} onChange={e => setEmail(e.target.value)}/>
                                <TextInput s={12} m={6} icon={<Button flat onClick={setRandomPassword}><Icon>lock</Icon></Button>}
                                           id="txtPassword" label="Password"
                                           value={password} type="password" onChange={e => setPassword(e.target.value)}/>
                            </Row>
                            <Row>
                            {!isSubAdmin(props.auth.loggedInUser) ?
                                <Select s={12} m={6}
                                        icon={<Icon>subscriptions</Icon>}
                                        id="subscriptionType"
                                        label="Customer Type"
                                        value={type}
                                        onChange={e => setType(e.target.value)}>
                                    <option value={SINGLE}>
                                        {SINGLE}
                                    </option>
                                    <option value={WEEKLY}>
                                        {WEEKLY}
                                    </option>
                                    <option value={UNLIMITED}>
                                        {UNLIMITED}
                                    </option>
                                    <option value={DOUBLE}>
                                        {DOUBLE}
                                    </option>
                                    <option value={CUSTOM}>
                                        {CUSTOM}
                                    </option>
                                </Select>
                                : null}
                                {!isSubAdmin(props.auth.loggedInUser) ?
                                <Select s={12} m={6}
                                        icon={<Icon>supervisor_account</Icon>}
                                        id="assignQa"
                                        label="Assign QA"
                                        value={qa}
                                        onChange={e => setQa(e.target.value)}>
                                    <option value="0">
                                        None
                                    </option>
                                    {getUserOptions(props.users.users, QA_USER)}
                                </Select>
                                : null}
                            </Row>
                            {
                                type === CUSTOM &&
                                    <Row>
                                        <TextInput s={12} m={6} id="txtCustomSub" label="Custom Plan" icon="list"
                                                   value={customType} onChange={e => setCustomType(e.target.value)}/>
                                    </Row>
                            }
                        </Container>
                    </Modal>
                </Col>
            </Row>
            {
                props.auth.loggedInUser.client_type === ADMIN ? 
                <Row className="customers-filter-main-div">
                    <Col>
                        <Select
                            className="customer-select-sub"
                            label="Customer Type"
                            value={filterData.subscriptionType}
                            onChange={e => { let value = e.target.value; setfilterData(prev=>({
                                ...prev,
                                subscriptionType : value
                            }))}}>
                                <option value="">
                                    Select
                                </option>
                                <option value={SINGLE}>
                                    {SINGLE}
                                </option>
                                <option value={WEEKLY}>
                                    {WEEKLY}
                                </option>
                                <option value={UNLIMITED}>
                                    {UNLIMITED}
                                </option>
                                <option value={DOUBLE}>
                                    {DOUBLE}
                                </option>
                                <option value={CUSTOM}>
                                    {CUSTOM}
                                </option>
                        </Select>
                    </Col>
                    <Col>
                        <Select
                            label="Referral"
                            value={filterData.referral}
                            onChange={e => { let value = e.target.value; setfilterData(prev=>({ ...prev, referral : value })
                            )}} >
                            <option value="">Select</option>
                            <option value="Google">Google</option>
                            <option value="Youtube">Youtube</option>
                            <option value="Linkedin">Linkedin</option>
                            <option value="Google Search">Google Search</option>
                            <option value="Facebook">Facebook</option>
                            <option value="Affiliate">Affiliate</option>
                            <option value="Other">Other</option>
                        </Select>
                    </Col>
                    <Col className="cutomer-filter-dropdown">
                        <Modal
                            trigger={(
                                <Button node="button" className="btn-primary" icon={<Icon left>date_range</Icon>}>
                                    {toISODate(filterData.startDate ? filterData.startDate : new Date())} ~ {toISODate(filterData.endDate)}
                                </Button>
                            )}
                            header="Add Date Range"
                            id="addDateRangeModal"
                            open={addDateRangeModalOpen}
                            style={{height: '65%', width:'51%'}}
                            options={{
                                dismissible: true,
                                endingTop: '10%',
                                opacity: 0.5,
                                preventScrolling: true,
                                onCloseEnd: () => setAddDateRangeModalOpen(false)
                            }}
                        >
                            <Container>
                                <Row>
                                    <DateRangePicker
                                        ranges={[{
                                            startDate : filterData.startDate ? filterData.startDate : new Date(),
                                            endDate : filterData.endDate,
                                            key: 'selection',
                                        }]}
                                        direction="horizontal"
                                        fixedHeight={true}
                                        months={2}
                                        onChange={ (dates) => setfilterData(prev => ({
                                            ...prev, 
                                            startDate:dates.selection.startDate,
                                            endDate:dates.selection.endDate
                                        })) }
                                    />
                                </Row>
                            </Container>
                        </Modal>
                    </Col>
                    <Col className="cutomer-filter-clear-btn">
                        <Button onClick={()=>setfilterData(INITIAL_FILTER_STATE)}>Clear Filter</Button>
                    </Col>
                    <Col className="customer-filter-export">
                        <Button id="csv-link"
                            style={{ backgroundColor: props.subDomain.subDomainData.primary_color || "#ab322d"}}
                            onClick={() => exportCustomers()}>
                                Export Customers
                        </Button>
                    </Col>
                </Row> : null
            }
            </div>
            <Row className="customers-row-cards">
                {populateCustomers(props, confirmationOpen, setConfirmationOpen, onConfirmDelete, setOnConfirmDelete, filterData)}
            </Row>
            <Table id="customers-table">
                <thead>
                    <tr>
                        <th>FirstName</th>
                        <th>LastName</th>
                        <th>Email</th>
                        <th>Referral</th>
                        <th>OtherReferral</th>
                        <th>SocialMediaAccount</th>
                    </tr>
                </thead>
                <tbody>
                    {filterCsvData.map((item, i) => <tr key={i+1 + "-row"}>
                        <td>{item.firstname}</td>
                        <td>{item.lastname}</td>
                        <td>{item.useremail}</td>
                        <td>{item.referral}</td>
                        <td>{item.otherreferral}</td>
                        <td>{item.social_media_link}</td>
                    </tr>)
                    }
                </tbody>
            </Table>
        </Container>
    );
});

function mapStateToProps({auth, users, subDomain}) {
    return {auth, users, subDomain};
}
