import React from 'react';

const RenderHtml = ({ bannerHeading, bannerHeadingTag, headingStyle }) => {
    const htmlContent = `<${bannerHeadingTag}>${bannerHeading}</${bannerHeadingTag}>`;
    return (
        <div style={headingStyle} dangerouslySetInnerHTML={{ __html: htmlContent }} />
    )
}

export default RenderHtml;
