import React from 'react';
import { Container, Table, Icon, Button } from 'react-materialize';
import { deleteLeaveData } from '../../services/LeaveManagementService';
import styles from './leaveManagmentStyle.module.scss';
import { errorToast, successToast } from '../../api/Util';

const LeaveManagementTable = ({ leaveHistoryData, getLeaveData, loggedInUser, setLeaveDataLoaded }) => {

    const handleDelete = async (leaveId, clientId) => {
        setLeaveDataLoaded(false)
        try {
            const result = await deleteLeaveData(leaveId, clientId);
            if (result.status) {
                getLeaveData();
                successToast("Deleted successfully.")
            } else {
                errorToast(result.message)
            }
        } catch (error) {
            console.error('Error during deletion process:', error);
        }
    };

    return (
        <Container id="response-rate-table">
            <Table className={styles.leave_table}>
                <thead>
                    <tr>
                        <th>SR. Number</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Reason</th>
                        {loggedInUser === 'admin' && <th>Action</th>}
                    </tr>
                </thead>
                <tbody>
                    {
                        leaveHistoryData.length > 0 ? leaveHistoryData.map((item, i) => (
                            <tr key={'customer-table-row' + i}>
                                <td>{i + 1}</td>
                                <td>{item.start_date}</td>
                                <td>{item.end_date}</td>
                                <td>{item.reason}</td>
                                {loggedInUser === 'admin' && (
                                    <td>
                                        <Button className={styles.delete_icon} onClick={() => handleDelete(item.leave_id, item.client_id)}>
                                            <Icon>delete</Icon>
                                        </Button>
                                    </td>
                                )}
                            </tr>
                        )) : <tr>
                            <td colSpan="5" style={{ textAlign: 'center' }}>No Data Found</td>
                        </tr>
                    }
                </tbody>
            </Table>
        </Container>
    );
};

export default LeaveManagementTable;
