export const VIDEO_REQUEST = 'video_request'
export const ON_HOLD = 'on_hold'
export const EDITING = 'editing'
export const QA = 'qa'
export const REPURPOSE_QA = 'repurposeQA'
export const DONE = 'done'
export const CANCELED = 'canceled'
export const ARCHIVED = 'archived'

export const ADMIN = 'admin';
export const SUB_ADMIN = 'sub-admin';
export const TEAM_LEAD = 'team-lead';
export const YT_HELPER_LEAD = 'yt-helper-lead';
export const YT_HELPER = 'yt-helper';
export const QA_USER = 'qa';
export const CUSTOMER = 'customer';

export const SINGLE = 'Single';
export const WEEKLY = 'Weekly';
export const UNLIMITED = 'Unlimited';
export const DOUBLE = 'Double';
export const CUSTOM = 'Custom';

export const YT_HELPER_ASSINED = 'assignedCard';
export const YT_HELPER_IN_PROGRESS = 'inProgress';
export const YT_HELPER_IN_DONE = 'inDone';
export const YT_HELPER_CANCELLED = 'cancelled';

export const YT_HELPER_SECTION = 'ytHelperSection';

export const CARD_MOVEMENT_EMAIL = "card_movement";
export const CARD_COMMENT_EMAIL = "card_comment";
export const CARD_COMMENT_TAG_EMAIL = "card_comment_tag";


export const INITIAL_EMAIL_PREFERENCES_STATE = {
    [CARD_MOVEMENT_EMAIL] : 1,
    [CARD_COMMENT_EMAIL] : 1,
    [CARD_COMMENT_TAG_EMAIL] : 1,
}
