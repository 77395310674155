import {
    FETCHING_SUB_DOMAIN_DATA,
    FETCH_SUB_DOMAIN_DATA,
} from "../actions/types";

export default function (state = {}, {payload, type}) {
    switch (type) {
        case FETCHING_SUB_DOMAIN_DATA:
            return { ...state, loadingSubDomain: true };
        case FETCH_SUB_DOMAIN_DATA:
            if(payload.status < 300 && payload.status >= 200) {
                if (localStorage.getItem('parentId') === null) {
                    window.location.reload();
                }
                return { ...state, subDomainData: payload.data, loadingSubDomain: false };
            }
            return {...state, loadingSubDomain: false};
        default:
            break;
    }
    return state;
}