import React from 'react';
import ReactTooltip from 'react-tooltip';

const VideoPlayTab = ({ dataTip, videoUrl, cardName }) => {

    const openVideoInNewTab = (videoUrl) => {
        const newTab = window.open('', '_blank');
        newTab.document.write(`
          <html>
            <head>
              <title>Video: ${cardName}</title>
            </head>
            <body style="margin: 0; display: flex; align-items: center; justify-content: center; height: 100vh;">
              <video width="800" height="600" controls>
                <source src="${videoUrl}" type="video/mp4">
                Your browser does not support the video tag.
              </video>
            </body>
          </html>
        `);
    };

    return (
        <>
            <a
                data-tip={dataTip}
                data-place="bottom"
                icon="check"
                color="green"
                pull="right"
                className="playVideo"
                onClick={() => openVideoInNewTab(videoUrl)}>
                {videoUrl}
            </a>
            <ReactTooltip />
        </>
    );
}

export default VideoPlayTab;
