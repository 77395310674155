import { axiosClient } from '../api/httpClient';

export const updateUserLeave = async (id, data) => {
    try {
        const response = await axiosClient.post(`/api/user/${id}/update-leave`, data);
        return {
            status: response?.status
        };
    } catch (error) {
        return { 
            status: false, 
            message: error.response && error.response.data ? error.response.data.error : 'An unknown error occurred' 
        };
    }
};

export const getLeaveHistory = async (userId) => {
    try {
		const data = await axiosClient.get(`/api/user/${userId}/leave-history`);
		return {
			status: data?.status,
			data: data?.data
		}
	} catch (error) {
		return {
			error: "Some Error Occured"
		}
	}
};

export const deleteLeaveData = async (leaveId, clientId) => {
    try {
        const response = await axiosClient.delete(`/api/leave/${leaveId}/delete-leave?client_id=${clientId}`);
        return { status: true, message: response.data.message };
    } catch (error) {
        return { 
            status: false, 
            message: error.response && error.response.data ? error.response.data.error : 'An unknown error occurred' 
        };
    }
};
