import React from 'react';
import { Card, Table } from "react-materialize";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default ({ data }) => {
    return (
        <Card title="Customer Averages">
            <Table responsive={true} hoverable={true}>
                <thead>
                    <tr>
                        <th className="align-center">Total Customers</th>
                        <th className="align-center">Active Customers</th>
                        <th className="align-center">Request/Customer</th>
                        <th className="align-center">Completed/Customer</th>
                        <th className="align-center">Time/Customer</th>
                        <th className="align-center"><FontAwesomeIcon icon="star" color="#FFD700" size="xs" title="☆" />Rating/Customer</th>
                        <th className="align-center">Total Ratings</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="align-center">{data?.customer_data?.customer_averages?.total_customers}</td>
                        <td className="align-center">{data?.customer_data?.customer_averages?.active_customers}</td>
                        <td className="align-center">{data?.customer_data?.customer_averages?.average_requests?.toFixed(2)}</td>
                        <td className="align-center">{data?.customer_data?.customer_averages?.average_completed?.toFixed(2)}</td>
                        <td className="align-center">{data?.customer_data?.customer_averages?.average_time?.toFixed(2)} hours</td>
                        <td className="align-center">{data?.customer_data?.customer_averages?.average_rating?.toFixed(2)}</td>
                        <td className="align-center">{data?.customer_data?.customer_averages?.total_ratings}</td>
                    </tr>
                </tbody>
            </Table>
        </Card>
    );
}
