import React, { useEffect, useState } from "react";
import { Button, Card, Container, Icon, Row } from "react-materialize";
import { connect } from "react-redux";
import { YT_HELPER_SECTION } from "../api/Constants";
import { axiosClient } from "../api/httpClient";
import { isAdmin, isTeamLead, successToast } from "../api/Util";
import CardModal from "./CardModal";
import TopHeader from "./TopHeader";
import Pagination from "react-js-pagination";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

// function countUnread(notifications, myId) {
//     return notifications.reduce((unread, notification) => unread + isUnread(notification, myId), 0);
// }

// function isUnread(notification, myId) {
//     return !notification.seen && notification.recipient_client_id === myId;
// }

export default connect(mapStateToProps)((props) => {
    const me = props.auth.loggedInUser;
    const [notifications, setNotifications] = useState([]);
    const [index, setIndex] = useState(1);
    const [notificationsCount, setNotificationsCount] = useState(0);
    const [checkboxList, setCheckboxList] = useState([]);
    const limit = 50;

    const [viewCardId, setViewCardId] = useState({ cardId: 0, section: null });
    const [viewCardOpen, setViewCardOpen] = useState(false);


    const myId = me.client_id;

    const fetchNotifications = () => {
        axiosClient.get("/api/notifications", {
            params: {
                limit: limit,
                page: index,
                type:'notificationPage'
            }
        })
            .then(({ data }) => {
                setNotifications(data.notifications);
                if (data.notificationsCount) {
                    setNotificationsCount(parseInt(data.notificationsCount));
                }
            }).catch(err => {
                console.error(err);
            });
    };

    // function markAsRead() {
    //     if (countUnread(notifications, myId)) {
    //         axiosClient.post("/api/notifications/read")
    //             .then(() => { })
    //             .catch(err => {
    //                 console.error(err);
    //             });
    //     }
    // }

    // useEffect(() => {
    //     if (notifications && notifications.length > 0) {
    //         markAsRead();
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [notifications])

    useEffect(() => {
        if (me) {
            fetchNotifications();
        }

        if((isAdmin(me) || isTeamLead(me) ) && props.users.qas == null && !props.users.loadingUsers) {
            props.fetchAllUsers();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [index, me]);

    const showCard = (cardId, section) => {
        setViewCardId({ cardId: cardId, section });
        setViewCardOpen(true);
    }


    const multiSelectNotifications = (i, list, id) => {
        const indexOfObject = checkboxList.findIndex(object => {
            return object.index === i;
        });
        if (!(indexOfObject > -1)) {
            setCheckboxList([{index: i + (50 * (index - 1)), checked: list.target.checked, id}, ...checkboxList]);
        } else {
            checkboxList.splice(indexOfObject, 1);
            if (checkboxList.length === 0) {
                setCheckboxList([]);
            } else {
                setCheckboxList(checkboxList);
            }
        }

    };

    const selectAllNotifications = () => {
        setCheckboxList([]);
        let checkAll = document.querySelector("#checkAll");
        let ele = document.querySelectorAll('.check');  
        let temp = [];
        for (var i=0; i<ele.length; i++) {
            if (ele[i].type=='checkbox' && checkAll.checked) {
                temp.push({index: i, checked: ele[i].checked, id: ele[i].value});
                ele[i].checked=true;
            }
            else {
                ele[i].checked=false;
            }
            setCheckboxList(temp);
        }  
    }

    const markRead = () => {
        let resultIds = checkboxList.map(notificationsList => notificationsList.id);
        axiosClient.post("/api/notifications/read",{resultIds})
            .then((res) => {
                if (res.status === 200) {
                    successToast('Notifications marked read successfully');
                    document.querySelector("#checkAll").checked = false;
                    let ele = document.querySelectorAll('.check'); 
                    for (var i=0; i < ele.length; i++) {
                        if (ele[i].checked) {
                            ele[i].checked=false;
                        }
                        setCheckboxList([]);
                    }
                    let temp = notifications;
                    resultIds.map((item) => {
                        const indexOfObject = temp.findIndex(notification => {
                            return notification.id === item;
                        });
                        temp[indexOfObject].seen = 1;
                        setNotifications([...temp]);
                    })
                }})
            .catch(err => {
                console.error(err);
            });
    }

    return (
        <Container className="archived-main">
            <CardModal cardId={viewCardId.cardId} isOpen={viewCardOpen} onClose={setViewCardOpen.bind(this, false)} section={viewCardId.section}
                qas={props.users.qas} users={props.users.users} me={me} />
            <div className="aside-right-top-bar">
                <div className="top-bar-left">
                    <div className="top_bar">
                        <h3>Notifications</h3>
                    </div>
                </div>
                <div className="top-bar-right">
                    <TopHeader />
                </div>
            </div>

            <Card>
            <div style={{ float: 'right', marginRight: '20px', display: 'flex', gap: '10px' }}>
                    {checkboxList.length > 0 && <Button className='btn-primary' onClick={() => markRead()}>Mark as Read</Button>}
                    <Button key="refresh-btn" className='btn-primary' icon={<Icon left>refresh</Icon>} onClick={() => index === 1 ? fetchNotifications() : setIndex(1)}>Reload</Button>
                </div>

                <div className="archived-table-row">
                    <table>
                        <thead>
                            <tr>
                                <th width="5%" style={{ display: 'flex', gap: '10px', alignItems: 'center', width: 'fit-content', marginLeft: '4px' }}>
                                    <input type="checkbox" id="checkAll" style={{opacity: 1, pointerEvents: 'fill', position: 'unset'}} onChange={() => selectAllNotifications()}/>
                                    <p>Select All</p>
                                </th>
                                <th width="5%">Sr. No.</th>
                                <th width="11%">Created by</th>
                                <th width="50%">Message</th>
                                <th width="25%">Created On</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                notifications.length > 0 ? notifications.map((notification, i) => {
                                    let cardId = ((/^.*card-(\d+)/gm).exec(notification.action_link) || [])[1];

                                    return <tr style={{ cursor: "pointer", backgroundColor: notification.seen ? '#fff' : '#c2dbff' }} key={notification.id + "_key"}
                                        onClick={() => cardId ? showCard(cardId, notification?.action_link?.includes("/ythelper/dashboard") ? YT_HELPER_SECTION : null) : null}>
                                        <td onClick={e => e.stopPropagation()} style={{alignItems: 'center'}}>
                                            <input type="checkbox" value={notification.id}
                                                style={{opacity: 1, pointerEvents: 'fill'}}
                                                className="check" onChange={(e) => multiSelectNotifications(i, e, notification.id)} />
                                        </td>
                                        <td>{i + 1 + (50 * (index - 1))}</td>
                                        <td>{notification.firstname ? `${notification.firstname} ${notification.lastname}` : "" }</td>
                                        <td>{notification.data ? notification.data : ""}</td>
                                        <td>{notification.created_at ? notification.created_at : ""}</td>
                                    </tr>
                                }) : null
                            }
                        </tbody>
                    </table>
                </div>
                {
                    notifications.length > 0 ? <Row className="center-align">
                        <Pagination 
                            activePage={index}
                            itemsCountPerPage={50}
                            totalItemsCount={notificationsCount}
                            pageRangeDisplayed={10}
                            onChange={i => { setIndex(i); setCheckboxList([]); document.querySelector("#checkAll").checked = false; }}
                            prevPageText={<AiOutlineLeft/>}
                            nextPageText={<AiOutlineRight/>}
                            firstPageText={<AiOutlineDoubleLeft/>}
                            lastPageText={<AiOutlineDoubleRight/>}
                        />
                    </Row> : null
                }
            </Card>
        </Container>
    );
});

function mapStateToProps({ auth, users }) {
    return { auth, users };
}
