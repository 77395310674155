import React from 'react';
import Header from "./Header";
import Main from './Main';
import AllUsers from "./AllUsers";
import { Slide, ToastContainer } from "react-toastify";
import UserProfile from "./UserProfile";
import CustomerProfile from "./CustomerProfile";
import RequireLogin from "./RequireLogin";
import Settings from "./Settings/Settings";
import AllCustomers from "./AllCustomers";
import WhiteLabelCustomers from './WhiteLabelCustomers';
import Profile from "./Profile";
import Archived from "./Archived";
import Dashboard from "./Dashboard";
import YtHelperDashboard from './YtHelperDashboard';
import UploadVideo from './UploadVideo';
import Notifications from './Notifications';
import Rating from './Rating/rating';
import DueCards from './DueCards/DueCards';

let parentId = localStorage.getItem('parentId');

const parentIdEnabled = (id) => {
    if (id === '0') {
        return true;
    }
    return false;
};

const routes = [
    { path: "/", component: !(parentIdEnabled(parentId)) ? RequireLogin(Main) : RequireLogin(WhiteLabelCustomers) },
    { path: "/cards", component: !(parentIdEnabled(parentId)) ? RequireLogin(Main) : null },
    { path: "/customer/index", component: !(parentIdEnabled(parentId)) ? RequireLogin(Main) : null },
    { path: "/users", component: !(parentIdEnabled(parentId)) ? RequireLogin(AllUsers) : null },
    { path: "/customers", component: !(parentIdEnabled(parentId)) ? RequireLogin(AllCustomers) : null },
    { path: "/whitelabelcustomers", component: parentIdEnabled(parentId) ? RequireLogin(WhiteLabelCustomers) : null },
    { path: "/user/:userId/profile", component: !(parentIdEnabled(parentId)) ? RequireLogin(UserProfile) : null },
    { path: "/customer/:userId/profile", component: !(parentIdEnabled(parentId)) ? RequireLogin(CustomerProfile) : null },
    { path: "/settings", component: parentIdEnabled(parentId) ? RequireLogin(Settings) : null },
    { path: "/profile", component: RequireLogin(Profile) },
    { path: "/cards/archive", component: !(parentIdEnabled(parentId)) ? RequireLogin(Archived) : null },
    { path: "/dashboard", component: !(parentIdEnabled(parentId)) ? RequireLogin(Dashboard) : null },
    { path: "/ythelper/dashboard", component: !(parentIdEnabled(parentId)) ? RequireLogin(YtHelperDashboard) : null },
    { path: "/upload", component: !(parentIdEnabled(parentId)) ? RequireLogin(UploadVideo) : null },
    { path: "/notifications", component: RequireLogin(Notifications) },
    { path: "/rating", component: RequireLogin(Rating) },
    { path: '/dueCards', component: RequireLogin(DueCards) },
];

export default routes;
