import React, {useEffect, useState} from "react";
import Dragula from 'react-dragula';
import {
    caMoveRepurposeCard,
    canMoveYtHelperCard,
    canAcceptYtHelperCard,
    canAcceptCard,
    canAcceptRepurposeCard,
    canMoveCard,
    isAdmin,
    isCustomer,
    resize,
    repurposingVideosName,
    S3_BUCKET,
    myBucket,
    // validURL
} from "../api/Util";
import {CANCELED, DONE, EDITING, ON_HOLD, QA, REPURPOSE_QA, TEAM_LEAD, VIDEO_REQUEST, YT_HELPER_ASSINED, YT_HELPER_CANCELLED, YT_HELPER_IN_DONE, YT_HELPER_IN_PROGRESS} from "../api/Constants";
import {
    addCardTimeline,
    addRevision,
    decideTimeline,
    markAsDone,
    moveCard,
    moveCardSubStatus,
    updateSorting,
    moveYtHelperCardInDone,
    moveYtHelperCardInProgress,
    moveToQa,
    moveYtCard
} from "../api/CardActions";
import ConfirmationDialog from "./ConfirmationDialog";
import {Button, Checkbox, Col,Icon, Modal, TextInput} from "react-materialize";
import {connect} from 'react-redux';
import * as actions from "../actions";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';

let drake = null;

// https://bcmdr.netlify.app/2020/01/02/adding-drag-and-drop-to-a-react-application/
export default connect(mapStateToProps, actions)(({loggedInUser, refresh, setKey, subDomain}) => {
    const repurposingVideosCount = subDomain.subDomainData.repurposing_videos_count;
    const [selectedFile, setSelectedFile] = useState(null);
    const [reviewCard, setReviewCard] = useState({ open: false });
    const [addTimeline, setAddTimeline] = useState({ open: false });
    const [confirmation, setConfirmation] = useState({ open: false });
    const [confirmationCardToDone, setConfirmationCardToDone] = useState({ open: false });
    const [confirmationRepurpose, setConfirmationRepurpose] = useState({ open: false });
    const [doneLink, setDoneLink] = useState({ open: false });
    const [doneLinkUrl, setDoneLinkUrl] = useState("");
    const [doneLinkUrlArray, setDoneLinkUrlArray] = useState([]);
    const [repurposeDoneLink, setRepurposeDoneLink] = useState({ open: false });
    const [unlistedLink, setUnlistedLink] = useState("");

    // for yt helper dashboard card movement 
    const [moveCardInProgress, setMoveCardInProgress] = useState({open:false});
    const [ytHelperAssetsModal, setYtHelperAssetsModal] = useState({open:false});

    const [thumbnailsLink, setThumbnailsLink] = useState("");
    const [snippetLink, setSnippetLink] = useState("");
    const [videoDescription, setVideoDescriptions] = useState("");
    const [editorState, setEditorState] = useState();
    const [disableButton, setDisableButton] = useState(true);

    const [confirmationYtCard, setConfirmationYtCard] = useState({open:false});

    const [contentID, setContentID] = useState("");
    const [requiredContentId, setRequiredContentId] = useState(true);

    const [confirmToQA, setConfirmToQA] = useState({open:false});
    const [confirmToQAFromDone, setConfirmToQAFromDone] = useState({open:false});

    useEffect(() => {
        const admin = isAdmin(loggedInUser);
        const customer = isCustomer(loggedInUser);
        drake = Dragula({
            isContainer: (el) => {
                return el.classList.contains('drag-container');
            },
            moves: (el) => {
                if(el.attributes['data-card-repurpose-status']?.value) {
                    return caMoveRepurposeCard(loggedInUser, el.attributes['data-card-repurpose-status']?.value);
                } 
                else if(el.attributes['data-ythelper-card-status']?.value){
                    return canMoveYtHelperCard(loggedInUser, el.attributes['data-ythelper-card-status']?.value);
                }
                else {
                    return canMoveCard(loggedInUser, el.attributes['data-card-status']?.value,
                        Number(el.attributes['data-paused'].value), Number(el.attributes['data-complete'].value));
                }
            },
            accepts: (el, target) => {
                if(el.attributes['data-ythelper-card-status']?.value || target.attributes['data-ythelper-card-status']?.value)
                {
                    return canAcceptYtHelperCard(loggedInUser, el.attributes['data-ythelper-card-status']?.value, target.attributes['data-ythelper-card-status']?.value) ;
                }
                return canAcceptCard(loggedInUser, el.attributes['data-card-status']?.value, target.attributes['data-card-status']?.value)
                    || canAcceptRepurposeCard(loggedInUser, el.attributes['data-card-repurpose-status']?.value ||
                        (!isCustomer(loggedInUser) ? el.attributes['data-card-status']?.value : null),
                        target.attributes['data-card-repurpose-status']?.value);
            }
        });

        drake.on('drag', (el) => {
            // add 'is-moving' class to element being dragged
            el.classList.add('is-moving');
        });
        drake.on('dragend', (el) => {
            // remove 'is-moving' class from element after dragging has stopped
            el.classList.remove('is-moving');

            // add the 'is-moved' class for 600ms then remove it
            setTimeout(() => {
                el.classList.add('is-moved');
                setTimeout(() => el.classList.remove('is-moved'), 600);
            }, 100);
        });

        drake.on('drop', (el, target, source) => {
            let cardId = el.attributes['data-card-id'].value;
            let contentId = el.attributes['data-content-id'].value;
            let is_complete = el.attributes['data-complete'].value;
            let revisions = el.attributes['data-revisions'].value;
            let doneVideoLimit = el.attributes['data-done-video-limit'] ? el.attributes['data-done-video-limit'].value : "1";
            let targetStatus = target.attributes['data-card-status']?.value;
            let sourceStatus = source.attributes['data-card-status']?.value;

            let targetSubStatus = target.attributes['data-card-repurpose-status']?.value;
            let sourceSubStatus = source.attributes['data-card-repurpose-status']?.value;

            let ytHelperSourceStatus = source.attributes['data-ythelper-card-status']?.value;
            let ytHelperTargetStatus = target.attributes['data-ythelper-card-status']?.value;

            let flag = false;
            if(targetSubStatus || sourceSubStatus) {
                if (admin && (!(targetSubStatus && sourceSubStatus) && !(targetStatus && sourceStatus) && !(targetSubStatus && sourceStatus))) {
                    flag = true;
                    switch (targetStatus) {
                        case VIDEO_REQUEST:
                            targetSubStatus = "video_request";
                            break;
                        case ON_HOLD:
                            targetSubStatus = "on_hold";
                        case EDITING:
                            targetSubStatus = "normalEditing";
                            break;
                        case QA:
                            targetSubStatus = "normalQA";
                            break;
                        case DONE:
                            targetSubStatus = "normalDone"
                            break;
                        default:
                            break;
                    }

                    switch (targetSubStatus) {
                        case EDITING:
                            targetSubStatus = "repurposing";
                            break;
                        case DONE:
                            targetSubStatus = "repurposeDone";
                        case REPURPOSE_QA:
                            targetSubStatus = REPURPOSE_QA;
                            break;
                        default:
                            break;
                    }

                    if (sourceSubStatus === "editing") {
                        sourceSubStatus = "repurposing";
                    } else if (sourceSubStatus === "done") {
                        sourceSubStatus = "repurposeDone";
                    } else if (sourceSubStatus === REPURPOSE_QA) {
                        sourceSubStatus = REPURPOSE_QA;
                    }

                    if (sourceStatus === "editing") {
                        sourceStatus = "normalEditing";
                    } else if (sourceStatus === "done") {
                        sourceStatus = "normalDone";
                    } else if (sourceStatus === "qa") {
                        sourceStatus = "normalQA";
                    }

                    let state = {open: true, cardId, newStatus: targetSubStatus, cardStatus: sourceSubStatus || sourceStatus};
                    setConfirmation(state);
                } else {
                    let state = {open: true, cardId, newStatus: targetSubStatus, cardStatus: sourceSubStatus || sourceStatus};
                    if((targetSubStatus === DONE) || (targetSubStatus === REPURPOSE_QA)) {
                        setRepurposeDoneLink(state)
                    } else {
                        setConfirmationRepurpose(state);
                    }
                    return;
                }
            }

            if(ytHelperSourceStatus||ytHelperTargetStatus)
            {
                // setting the new status and open Confirmation Dialog 
                let state = {open: true, cardId, newStatus: ytHelperTargetStatus, cardStatus: ytHelperSourceStatus};
                if(ytHelperTargetStatus === YT_HELPER_IN_PROGRESS)
                {
                    setMoveCardInProgress(state);
                }
                else if(ytHelperTargetStatus === YT_HELPER_IN_DONE)
                {
                    setYtHelperAssetsModal(state);
                }
                else if(ytHelperTargetStatus === YT_HELPER_CANCELLED)
                {
                    setConfirmationYtCard(state);
                }
                else if (ytHelperTargetStatus === YT_HELPER_ASSINED && ytHelperSourceStatus === YT_HELPER_CANCELLED)
                {
                    setConfirmationYtCard(state);
                }
                return;
            }

            if (admin || customer) {
                if (sourceStatus === targetStatus && targetStatus === VIDEO_REQUEST) {
                    let videoRequests = [];
                    let sort = 1;
                    for (let i = 0; i < target.children.length; i++) {
                        let child = target.children[i];
                        let childCardId = child.attributes['data-card-id'].value;
                        if (childCardId) {
                            videoRequests.push(
                                {"card_id": Number(childCardId), "sort": sort});
                            sort++;
                            if (childCardId === cardId && i === Number(child.attributes['data-index'].value)) {
                                return;
                            }
                        }
                    }

                    updateSorting(videoRequests).then(() => refresh());
                } else if (customer && targetStatus === CANCELED) {
                    setConfirmation({open: true, cardId, newStatus: targetStatus, cardStatus: sourceStatus});
                } else if (admin && targetStatus === DONE && sourceStatus === ON_HOLD) {
                    setConfirmationCardToDone({open: true, cardId, newStatus: targetStatus, cardStatus: sourceStatus});
                }
            }

            if (!customer && !flag) {
                switch (targetStatus) {
                    case VIDEO_REQUEST:
                        if (sourceStatus !== targetStatus && (is_complete !== '1' || admin)) {
                            setReviewCard({open: true, cardId, cardStatus: sourceStatus});
                        }
                        break;
                    case EDITING:
                        if (is_complete !== '1' || admin) {
                            setAddTimeline({
                                open: true,
                                cardId,
                                cardStatus: sourceStatus,
                                timeline: decideTimeline(revisions)
                            });
                        }
                        break;
                    case ON_HOLD:
                    case CANCELED:
                        if (sourceStatus !== targetStatus && (is_complete !== '1' || admin)) {
                            setConfirmation({
                                open: true,
                                cardId,
                                newStatus: targetStatus,
                                cardStatus: sourceStatus
                            });
                        }
                        break;
                    case DONE:
                        if ((is_complete !== '1' || admin) && sourceStatus !== ON_HOLD) {
                            setDoneLink({open: true, cardId, contentId, newStatus: DONE, cardStatus: sourceStatus, revisions,
                                doneVideoLimit : parseInt(doneVideoLimit)});
                        }
                        break;
                    
                    case QA:
                        if(is_complete !=='1' || admin)
                        {
                            if (sourceStatus === DONE) {
                                setConfirmToQAFromDone({open: true,cardId, newStatus:QA,cardStatus:sourceStatus,
                                    doneVideoLimit : parseInt(doneVideoLimit)});
                            } else {
                                setConfirmToQA({open: true,cardId, contentId, newStatus:QA,cardStatus:sourceStatus,
                                    doneVideoLimit : parseInt(doneVideoLimit)});
                            }
                        }
                        break;
                    
                    default:
                        break;
                }
            }
        });

        return () => {
            if(drake) {
                drake.destroy();
                drake = null;
            }
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loggedInUser]);

    function handleEditorStateChange(e){
        if(e)
        {
            // Converting editor state to html 
            let value = draftToHtml(convertToRaw(e.getCurrentContent()));
            // Storing the editor state to display in the model
            setEditorState(e);
            // Storing the editor html tag in state so that it can be pass to backend
            setVideoDescriptions(value);
        }
    }

    function isValidURL(string) {
         // eslint-disable-next-line no-useless-escape
        var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
        return (res !== null)
    };

    /**
     * To save done url
     * @param {int} value 
     * @param {int} index 
     */
     function handleChangeDoneURL(value, index, name, fieldName) {
        if (fieldName === 'repurposingFields') {
            setDoneLinkUrlArray((prev) => ({
                ...prev,
                [index]: { type: name, url: value },
              }));
        } else {
            if(index != null && index >= 0) {
                let list = doneLinkUrlArray;
                list = resize(list, index, "");
                list[index] = value;
                setDoneLinkUrlArray(list);
            }
        }
    }
    const doneLinkUrlAndTypeArray = Object.entries(doneLinkUrlArray).map((e) => (e[1]));
    /**
     * To map the fields in modal
     * @param {int} limit 
     * @returns array of fields
     */
    function mapDoneVideoFields(limit, fieldName) {
        let tempFields = [];
        for(let i = 0; i<limit; i++) {
            tempFields.push(
                fieldName === "repurposingFields" ? (
                  <TextInput
                    s={12}
                    name={repurposingVideosName[i]}
                    label={repurposingVideosName[i]}
                    icon="link"
                    validate
                    key={"qa-done-link" + i}
                    value={doneLinkUrlArray[i]?.url || ""}
                    onChange={(e) =>
                      handleChangeDoneURL(e.target.value, i, e.target.name, 'repurposingFields')
                    }
                  />
                ) : (
                  <TextInput
                    s={12}
                    label={"Video Link"}
                    icon="link"
                    validate
                    key={"qa-done-link" + i}
                    value={doneLinkUrlArray[i] || ""}
                    onChange={(e) => handleChangeDoneURL(e.target.value, i)}
                  />
                )
              );
        }
        return tempFields;
    }

    useEffect(() => {
        let data = doneLinkUrlAndTypeArray.find(item => item?.url)
            if(doneLinkUrlAndTypeArray.length > 0 && data?.url !== undefined) {
                setDisableButton(false);
            } else {
                setDisableButton(true);
            }
    }, [doneLinkUrlAndTypeArray])

    const onChangeFile = (e) => {
        let value = e.target.files[0];

        const params = {
            ACL: 'public-read',
            Body: value,
            Bucket: S3_BUCKET,
            Key: 'prod/media/srt/'+value.name
        };
        myBucket.upload(params,
            (err, data) => {
            if (err) {
                return err;
            } else {
                let temp = data?.Key ? "https://videoportal-files.s3.amazonaws.com/"+ data.Key : null;
                setSelectedFile(temp);
            }
        })
    }

    function onChangeContentIdCheck () {
        setRequiredContentId(!requiredContentId);
        setContentID("");
    }

    return (
        <div>
            <ConfirmationDialog
                onNegative ={()=> {setMoveCardInProgress({open:false}); setKey(k=>k+1);}}
                onPositive ={()=> {moveYtHelperCardInProgress(moveCardInProgress.cardId, moveCardInProgress.newStatus,moveCardInProgress.cardStatus,refresh)}}
                confirmationHeader = "Confirm"
                confirmationDialogId = {"confirm-ythelper-card-move"}
                confirmation = {moveCardInProgress.open}
                confirmationText = {"Are you sure you want to move the card for editing section"}
            />
            <ConfirmationDialog
                onNegative={() => {setReviewCard({ open: false }); setKey(k => k+1);}}
                onPositive={() => addRevision(reviewCard.cardId, reviewCard.cardStatus, refresh)}
                confirmationHeader="Revision"
                confirmationDialogId="confirm-revision"
                confirmation={reviewCard.open}
                confirmationText={"Are you sure you want to request revision?"}
            />
            <ConfirmationDialog
                onNegative={() => {setAddTimeline({ open: false }); setKey(k => k+1);}}
                onPositive={() => addCardTimeline(addTimeline.cardId, addTimeline.cardStatus, addTimeline.timeline, refresh)}
                confirmationHeader="Confirm"
                confirmationDialogId={"confirm-timeline"}
                confirmation={addTimeline.open}
                confirmationText={"Confirm move card to editing?"}
            />
            <ConfirmationDialog
                onNegative={() => {setConfirmationCardToDone({open: false}); setKey(k => k+1);}}
                onPositive={() => markAsDone(confirmationCardToDone.cardId, confirmationCardToDone.cardStatus, "", refresh, "", [])}
                confirmationHeader="Confirm"
                confirmationDialogId={"confirm-move"}
                confirmation={confirmationCardToDone.open}
                confirmationText={"Are you sure you want to move the card to done?"}
            />
            <ConfirmationDialog
                onNegative={() => {setConfirmation({open: false}); setKey(k => k+1);}}
                onPositive={() => moveCard(confirmation.cardId, confirmation.newStatus, confirmation.cardStatus, refresh)}
                confirmationHeader="Confirm"
                confirmationDialogId={"confirm-move"}
                confirmation={confirmation.open}
                confirmationText={"Are you sure you want to move the card?"}
            />
            <ConfirmationDialog
                onNegative={() => {setConfirmationYtCard({open: false}); setKey(k => k+1);}}
                onPositive={() => moveYtCard(confirmationYtCard.cardId, confirmationYtCard.newStatus, confirmationYtCard.cardStatus, refresh)}
                confirmationHeader="Confirm"
                confirmationDialogId={"confirm-move"}
                confirmation={confirmationYtCard.open}
                confirmationText={"Are you sure you want to move the card?"}
            />
            <ConfirmationDialog
                onNegative={() => {setConfirmToQAFromDone({open: false}); setKey(k => k+1);}}
                onPositive={() => moveCard(confirmToQAFromDone.cardId, confirmToQAFromDone.newStatus, confirmToQAFromDone.cardStatus, refresh)}
                confirmationHeader="Confirm"
                confirmationDialogId={"confirm-move"}
                confirmation={confirmToQAFromDone.open}
                confirmationText={"Are you sure you want to move the card?"}
            />
            <ConfirmationDialog
                onNegative={() => {setConfirmationRepurpose({open: false}); setKey(k => k+1);}}
                onPositive={() => moveCardSubStatus(confirmationRepurpose.cardId, confirmationRepurpose.newStatus,
                    confirmationRepurpose.cardStatus, refresh)}
                confirmationHeader="Confirm"
                confirmationDialogId={"confirm-move-sub"}
                confirmation={confirmationRepurpose.open}
                confirmationText={"Are you sure you want to move the card to repurposing?"}
            />
            <Modal
                actions={[
                    <Button flat modal="close" node="button" waves="red" large>Close</Button>,
                    <Button modal="close" onClick={() => markAsDone(doneLink.cardId, doneLink.cardStatus, doneLinkUrl, refresh, contentID,doneLinkUrlArray)}
                            disabled={doneLinkUrl && ((requiredContentId && contentID !== "") || !requiredContentId) ? false : true } node="button" waves="green" large>Submit</Button>
                ]}
                header="Set video link and sync id for music"
                id="setVideoLinkModal"
                open={doneLink.open}
                // style={{height: '35rem'}}
                options={{
                    dismissible: true,
                    endingTop: '10%',
                    opacity: 0.5,
                    preventScrolling: true,
                    onOpenStart: () => {setDoneLinkUrl(""); setContentID("");setDoneLinkUrlArray([]); setRequiredContentId(true)},
                    onCloseEnd: () => {setDoneLink({open: false}); setKey(k => k+1);}
                }}
            >
                <TextInput s={12} label="Video Link" icon="link" validate id={"txt-done-link"}
                           value={doneLinkUrl} onChange={e => setDoneLinkUrl(e.target.value)}/>
                {
                     mapDoneVideoFields(4)
                }
                
                <TextInput s={12} label={ doneLink.revisions > 0 ? "Any Additional Sync ID (Optional)" : "Sync ID for Music Licensing" } icon="short_text" id={"txt-content-id"}
                           value={contentID || doneLink.contentId} onChange={e => setContentID(e.target.value)} disabled={!requiredContentId} />
                <Checkbox
                    id="contentIdRequired"
                    label="Please remove the checkmark If order has no Sync ID"
                    onChange={onChangeContentIdCheck}
                    checked={requiredContentId}
                    />
            </Modal>
            <Modal
                actions={[
                    <Button flat modal="close" node="button" waves="red" large>Close</Button>,
                    <Button modal="close" onClick={() => moveCardSubStatus(repurposeDoneLink.cardId,
                        repurposeDoneLink.newStatus, repurposeDoneLink.cardStatus, refresh, { done_link: doneLinkUrlArray[0], doneLinkUrlArray: doneLinkUrlAndTypeArray.slice(1) }, selectedFile)}
                            disabled={repurposeDoneLink.newStatus === DONE ? false : disableButton}
                            node="button" waves="green" large>Submit</Button>
                ]}
                header="Set repurposed video link"
                id="setReVideoLinkModal"
                open={repurposeDoneLink.open}
                // style={{height: '20rem'}}
                options={{
                    dismissible: true,
                    endingTop: '10%',
                    opacity: 0.5,
                    preventScrolling: true,
                    onOpenStart: () => { setDoneLinkUrl(""); setDoneLinkUrlArray({}); document.getElementById('srt-input').value = null;},
                    onCloseEnd: () => {setRepurposeDoneLink({open: false}); setKey(k => k+1); document.getElementById('srt-input').value = null;}
                }}
            >
                {
                    mapDoneVideoFields(repurposingVideosCount, 'repurposingFields')
                }
               <div style={{ display: "flex" }}>
                <Icon style={{ marginRight: "18px", marginTop: "4px" }}>
                    attach_file
                </Icon>
                <input
                    style={{ width: "100%" }}
                    className="custom-file-input"
                    s={12}
                    id="srt-input"
                    type="file"
                    name="srtFile"
                    onChange={(e) => onChangeFile(e)}
                    label="Select SRT File"
                />
            </div>
            </Modal>
            <Modal
                actions = {[
                    <Button flat modal='close' node= 'button' waves="red" large>Close</Button>,
                    <Button modal="close" onClick={()=> moveYtHelperCardInDone(ytHelperAssetsModal.cardId,
                        ytHelperAssetsModal.newStatus,ytHelperAssetsModal.cardStatus,thumbnailsLink,videoDescription,snippetLink,unlistedLink,refresh
                        )} disabled={! (isValidURL(thumbnailsLink) && (videoDescription !== "<p></p>")) } node="button" waves = "green" large>Submit</Button>
                ]}
                header = "Set YouTube Helper Assets"
                id="setYtHelperAssetsModel"
                open = {ytHelperAssetsModal.open}
                style={{height: '50rem'}}
                options={{
                    dismissible: true,
                    endingTop: '10%',
                    opacity: 0.5,
                    preventScrolling: true,
                    onOpenStart: () => {setThumbnailsLink(""); setSnippetLink(""); setUnlistedLink(""); setVideoDescriptions("");setEditorState();},
                    onCloseEnd: () => {setYtHelperAssetsModal({open: false}); setKey(k => k+1);}
                }}
            >
                <TextInput s={12} label="Video Thumbnail Link" icon="link" validate id={"thumbnails-link"}
                           value={thumbnailsLink} onChange={e => setThumbnailsLink(e.target.value)}/>

                {(isAdmin(loggedInUser) || loggedInUser.client_type === TEAM_LEAD || loggedInUser.client_type === QA) ? <TextInput s={12} label="Snippet Link" icon="link" validate id={"snippet-link"}
                           value={snippetLink} onChange={e => setSnippetLink(e.target.value)}/> : null}

                <TextInput s={12} label="Unlisted Video" icon="link" validate id={"unlisted-link"}
                           value={unlistedLink} onChange={e => setUnlistedLink(e.target.value)}/>
                
                <Col s={12} className="label-column">
                <label>Video Description</label>
                </Col>
                <Col s={12} className="label-column labele_title">
                    <Icon>short_text</Icon>
                    <Editor
                        toolbar = {{options: ['inline', 'blockType', 'fontSize', 'list', 'colorPicker', 'emoji']}}
                        editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={(e)=>{handleEditorStateChange(e)}}
                    />
                    <div className="clearfix"></div>
                </Col>
                
            </Modal>
            <Modal
                actions={[
                    <Button flat modal="close" node="button" waves="red" large>Close</Button>,
                    <Button modal="close" onClick={() => moveToQa(confirmToQA.cardId, confirmToQA.cardStatus, doneLinkUrl, refresh, contentID, doneLinkUrlArray)}
                            disabled={isValidURL(doneLinkUrl) && ((requiredContentId && contentID !== "") || !requiredContentId) ? false : true } node="button" waves="green" large>Submit</Button>
                ]}
                header="Set video link and sync id for music"
                id="setQAVideoLinkModal"
                open={confirmToQA.open}
                // style={{height: '30rem'}}
                options={{
                    dismissible: true,
                    endingTop: '10%',
                    opacity: 0.5,
                    preventScrolling: true,
                    onOpenStart: () => {setDoneLinkUrl(""); setContentID(""); setDoneLinkUrlArray([]); setRequiredContentId(true)},
                    onCloseEnd: () => {setConfirmToQA({open: false}); setKey(k => k+1);}
                }}
            >
                <TextInput s={12} label="Video Link" icon="link" validate id={"qa-done-link"}
                           value={doneLinkUrl} onChange={e => setDoneLinkUrl(e.target.value)}/>
                {
                    confirmToQA?.doneVideoLimit > 1 ? mapDoneVideoFields(confirmToQA?.doneVideoLimit) : null
                }
                <TextInput s={12} label={ doneLink.revisions > 0 ? "Any Additional Sync ID (Optional)" : "Sync ID for Music Licensing" } icon="short_text" id={"txt-content-id"}
                        value={contentID || confirmToQA.contentId} onChange={e => setContentID(e.target.value)} disabled={!requiredContentId} />
                <Checkbox
                    id="contentIdRequired"
                    label="Please remove the checkmark If order has no Sync ID"
                    onChange={onChangeContentIdCheck}
                    checked={requiredContentId}
                    />
            </Modal>
        </div>
    );
});

function mapStateToProps({subDomain}) {
    return {subDomain};
}
